/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type CreateGameSessionFromTemplateInput = {
  gameTemplateId: string,
};

export type CreateGameTemplateInput = {
  id?: string | null,
  title: string,
  owner: string,
  version: number,
  description: string,
  domain?: string | null,
  cluster?: string | null,
  grade?: string | null,
  standard?: string | null,
  phaseOneTime?: number | null,
  phaseTwoTime?: number | null,
  imageUrl?: string | null,
  questionTemplatesCount: number,
  questionTemplatesOrder?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  type?: string | null,
};

export type ModelGameTemplateConditionInput = {
  title?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  version?: ModelIntInput | null,
  description?: ModelStringInput | null,
  domain?: ModelStringInput | null,
  cluster?: ModelStringInput | null,
  grade?: ModelStringInput | null,
  standard?: ModelStringInput | null,
  phaseOneTime?: ModelIntInput | null,
  phaseTwoTime?: ModelIntInput | null,
  imageUrl?: ModelStringInput | null,
  questionTemplatesCount?: ModelIntInput | null,
  questionTemplatesOrder?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  type?: ModelStringInput | null,
  and?: Array< ModelGameTemplateConditionInput | null > | null,
  or?: Array< ModelGameTemplateConditionInput | null > | null,
  not?: ModelGameTemplateConditionInput | null,
};

export type ModelStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null",
}


export type ModelSizeInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type GameTemplate = {
  __typename: "GameTemplate",
  id: string,
  title: string,
  owner: string,
  version: number,
  description: string,
  domain?: string | null,
  cluster?: string | null,
  grade?: string | null,
  standard?: string | null,
  phaseOneTime?: number | null,
  phaseTwoTime?: number | null,
  imageUrl?: string | null,
  questionTemplates?: ModelGameQuestionsConnection | null,
  questionTemplatesCount: number,
  questionTemplatesOrder?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  type?: string | null,
};

export type ModelGameQuestionsConnection = {
  __typename: "ModelGameQuestionsConnection",
  items:  Array<GameQuestions | null >,
  nextToken?: string | null,
};

export type GameQuestions = {
  __typename: "GameQuestions",
  id: string,
  gameTemplateID: string,
  questionTemplateID: string,
  gameTemplate: GameTemplate,
  questionTemplate: QuestionTemplate,
  createdAt: string,
  updatedAt: string,
};

export type QuestionTemplate = {
  __typename: "QuestionTemplate",
  id: string,
  title: string,
  owner: string,
  version: number,
  choices?: string | null,
  instructions?: string | null,
  answerSettings?: string | null,
  domain?: string | null,
  cluster?: string | null,
  grade?: string | null,
  standard?: string | null,
  imageUrl?: string | null,
  gameTemplates?: ModelGameQuestionsConnection | null,
  gameTemplatesCount: number,
  createdAt?: string | null,
  updatedAt?: string | null,
  type?: string | null,
};

export type UpdateGameTemplateInput = {
  id: string,
  title?: string | null,
  owner?: string | null,
  version?: number | null,
  description?: string | null,
  domain?: string | null,
  cluster?: string | null,
  grade?: string | null,
  standard?: string | null,
  phaseOneTime?: number | null,
  phaseTwoTime?: number | null,
  imageUrl?: string | null,
  questionTemplatesCount?: number | null,
  questionTemplatesOrder?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  type?: string | null,
};

export type DeleteGameTemplateInput = {
  id: string,
};

export type CreateQuestionTemplateInput = {
  id?: string | null,
  title: string,
  owner: string,
  version: number,
  choices?: string | null,
  instructions?: string | null,
  answerSettings?: string | null,
  domain?: string | null,
  cluster?: string | null,
  grade?: string | null,
  standard?: string | null,
  imageUrl?: string | null,
  gameTemplatesCount: number,
  createdAt?: string | null,
  updatedAt?: string | null,
  type?: string | null,
};

export type ModelQuestionTemplateConditionInput = {
  title?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  version?: ModelIntInput | null,
  choices?: ModelStringInput | null,
  instructions?: ModelStringInput | null,
  answerSettings?: ModelStringInput | null,
  domain?: ModelStringInput | null,
  cluster?: ModelStringInput | null,
  grade?: ModelStringInput | null,
  standard?: ModelStringInput | null,
  imageUrl?: ModelStringInput | null,
  gameTemplatesCount?: ModelIntInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  type?: ModelStringInput | null,
  and?: Array< ModelQuestionTemplateConditionInput | null > | null,
  or?: Array< ModelQuestionTemplateConditionInput | null > | null,
  not?: ModelQuestionTemplateConditionInput | null,
};

export type UpdateQuestionTemplateInput = {
  id: string,
  title?: string | null,
  owner?: string | null,
  version?: number | null,
  choices?: string | null,
  instructions?: string | null,
  answerSettings?: string | null,
  domain?: string | null,
  cluster?: string | null,
  grade?: string | null,
  standard?: string | null,
  imageUrl?: string | null,
  gameTemplatesCount?: number | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  type?: string | null,
};

export type DeleteQuestionTemplateInput = {
  id: string,
};

export type CreateGameSessionInput = {
  id?: string | null,
  gameId: string,
  startTime?: string | null,
  phaseOneTime: number,
  phaseTwoTime: number,
  currentQuestionIndex?: number | null,
  currentState: GameSessionState,
  gameCode: number,
  isAdvancedMode: boolean,
  imageUrl?: string | null,
  description?: string | null,
  title?: string | null,
  currentTimer?: number | null,
};

export enum GameSessionState {
  NOT_STARTED = "NOT_STARTED",
  TEAMS_JOINING = "TEAMS_JOINING",
  CHOOSE_CORRECT_ANSWER = "CHOOSE_CORRECT_ANSWER",
  PHASE_1_DISCUSS = "PHASE_1_DISCUSS",
  PHASE_2_START = "PHASE_2_START",
  CHOOSE_TRICKIEST_ANSWER = "CHOOSE_TRICKIEST_ANSWER",
  PHASE_2_DISCUSS = "PHASE_2_DISCUSS",
  FINAL_RESULTS = "FINAL_RESULTS",
  FINISHED = "FINISHED",
}


export type ModelGameSessionConditionInput = {
  gameId?: ModelIDInput | null,
  startTime?: ModelStringInput | null,
  phaseOneTime?: ModelIntInput | null,
  phaseTwoTime?: ModelIntInput | null,
  currentQuestionIndex?: ModelIntInput | null,
  currentState?: ModelGameSessionStateInput | null,
  gameCode?: ModelIntInput | null,
  isAdvancedMode?: ModelBooleanInput | null,
  imageUrl?: ModelStringInput | null,
  description?: ModelStringInput | null,
  title?: ModelStringInput | null,
  currentTimer?: ModelIntInput | null,
  and?: Array< ModelGameSessionConditionInput | null > | null,
  or?: Array< ModelGameSessionConditionInput | null > | null,
  not?: ModelGameSessionConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type ModelIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export type ModelGameSessionStateInput = {
  eq?: GameSessionState | null,
  ne?: GameSessionState | null,
};

export type ModelBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type GameSession = {
  __typename: "GameSession",
  id: string,
  gameId: string,
  startTime?: string | null,
  phaseOneTime: number,
  phaseTwoTime: number,
  teams?: ModelTeamConnection | null,
  currentQuestionIndex?: number | null,
  currentState: GameSessionState,
  gameCode: number,
  isAdvancedMode: boolean,
  imageUrl?: string | null,
  description?: string | null,
  title?: string | null,
  currentTimer?: number | null,
  questions?: ModelQuestionConnection | null,
  createdAt: string,
  updatedAt: string,
};

export type ModelTeamConnection = {
  __typename: "ModelTeamConnection",
  items:  Array<Team | null >,
  nextToken?: string | null,
};

export type Team = {
  __typename: "Team",
  id: string,
  name: string,
  question?: Question | null,
  teamMembers?: ModelTeamMemberConnection | null,
  score: number,
  selectedAvatarIndex: number,
  createdAt: string,
  updatedAt: string,
  gameSessionTeamsId?: string | null,
  teamQuestionId?: string | null,
  teamQuestionOrder?: number | null,
  teamQuestionGameSessionId?: string | null,
};

export type Question = {
  __typename: "Question",
  id: string,
  text: string,
  choices?: string | null,
  answerSettings?: string | null,
  responses?: string | null,
  hints?: string | null,
  imageUrl?: string | null,
  instructions?: string | null,
  standard?: string | null,
  cluster?: string | null,
  domain?: string | null,
  grade?: string | null,
  order: number,
  isConfidenceEnabled: boolean,
  isShortAnswerEnabled: boolean,
  isHintEnabled: boolean,
  gameSessionId: string,
};

export type ModelTeamMemberConnection = {
  __typename: "ModelTeamMemberConnection",
  items:  Array<TeamMember | null >,
  nextToken?: string | null,
};

export type TeamMember = {
  __typename: "TeamMember",
  id: string,
  isFacilitator?: boolean | null,
  answers?: ModelTeamAnswerConnection | null,
  deviceId: string,
  createdAt: string,
  updatedAt: string,
  teamTeamMembersId?: string | null,
};

export type ModelTeamAnswerConnection = {
  __typename: "ModelTeamAnswerConnection",
  items:  Array<TeamAnswer | null >,
  nextToken?: string | null,
};

export type TeamAnswer = {
  __typename: "TeamAnswer",
  id: string,
  isCorrect?: boolean | null,
  isSubmitted: boolean,
  isShortAnswerEnabled: boolean,
  currentState: GameSessionState,
  currentQuestionIndex: number,
  questionId: string,
  teamMemberAnswersId: string,
  teamAnswersId?: string | null,
  teamName?: string | null,
  text: string,
  answer: string,
  confidenceLevel?: ConfidenceLevel | null,
  hint?: string | null,
  createdAt: string,
  updatedAt: string,
};

export enum ConfidenceLevel {
  NOT_RATED = "NOT_RATED",
  NOT_AT_ALL = "NOT_AT_ALL",
  KINDA = "KINDA",
  QUITE = "QUITE",
  VERY = "VERY",
  TOTALLY = "TOTALLY",
}


export type ModelQuestionConnection = {
  __typename: "ModelQuestionConnection",
  items:  Array<Question | null >,
  nextToken?: string | null,
};

export type UpdateGameSessionInput = {
  id: string,
  gameId?: string | null,
  startTime?: string | null,
  phaseOneTime?: number | null,
  phaseTwoTime?: number | null,
  currentQuestionIndex?: number | null,
  currentState?: GameSessionState | null,
  gameCode?: number | null,
  isAdvancedMode?: boolean | null,
  imageUrl?: string | null,
  description?: string | null,
  title?: string | null,
  currentTimer?: number | null,
};

export type DeleteGameSessionInput = {
  id: string,
};

export type CreateQuestionInput = {
  id?: string | null,
  text: string,
  choices?: string | null,
  answerSettings?: string | null,
  responses?: string | null,
  hints?: string | null,
  imageUrl?: string | null,
  instructions?: string | null,
  standard?: string | null,
  cluster?: string | null,
  domain?: string | null,
  grade?: string | null,
  order: number,
  isConfidenceEnabled: boolean,
  isShortAnswerEnabled: boolean,
  isHintEnabled: boolean,
  gameSessionId: string,
};

export type ModelQuestionConditionInput = {
  text?: ModelStringInput | null,
  choices?: ModelStringInput | null,
  answerSettings?: ModelStringInput | null,
  responses?: ModelStringInput | null,
  hints?: ModelStringInput | null,
  imageUrl?: ModelStringInput | null,
  instructions?: ModelStringInput | null,
  standard?: ModelStringInput | null,
  cluster?: ModelStringInput | null,
  domain?: ModelStringInput | null,
  grade?: ModelStringInput | null,
  isConfidenceEnabled?: ModelBooleanInput | null,
  isShortAnswerEnabled?: ModelBooleanInput | null,
  isHintEnabled?: ModelBooleanInput | null,
  and?: Array< ModelQuestionConditionInput | null > | null,
  or?: Array< ModelQuestionConditionInput | null > | null,
  not?: ModelQuestionConditionInput | null,
};

export type UpdateQuestionInput = {
  id: string,
  text?: string | null,
  choices?: string | null,
  answerSettings?: string | null,
  responses?: string | null,
  hints?: string | null,
  imageUrl?: string | null,
  instructions?: string | null,
  standard?: string | null,
  cluster?: string | null,
  domain?: string | null,
  grade?: string | null,
  order: number,
  isConfidenceEnabled?: boolean | null,
  isShortAnswerEnabled?: boolean | null,
  isHintEnabled?: boolean | null,
  gameSessionId: string,
};

export type DeleteQuestionInput = {
  id: string,
  order: number,
  gameSessionId: string,
};

export type CreateTeamInput = {
  id?: string | null,
  name: string,
  score: number,
  selectedAvatarIndex: number,
  gameSessionTeamsId?: string | null,
  teamQuestionId?: string | null,
  teamQuestionOrder?: number | null,
  teamQuestionGameSessionId?: string | null,
};

export type ModelTeamConditionInput = {
  name?: ModelStringInput | null,
  score?: ModelIntInput | null,
  selectedAvatarIndex?: ModelIntInput | null,
  and?: Array< ModelTeamConditionInput | null > | null,
  or?: Array< ModelTeamConditionInput | null > | null,
  not?: ModelTeamConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  gameSessionTeamsId?: ModelIDInput | null,
  teamQuestionId?: ModelIDInput | null,
  teamQuestionOrder?: ModelIntInput | null,
  teamQuestionGameSessionId?: ModelIDInput | null,
};

export type UpdateTeamInput = {
  id: string,
  name?: string | null,
  score?: number | null,
  selectedAvatarIndex?: number | null,
  gameSessionTeamsId?: string | null,
  teamQuestionId?: string | null,
  teamQuestionOrder?: number | null,
  teamQuestionGameSessionId?: string | null,
};

export type DeleteTeamInput = {
  id: string,
};

export type CreateTeamMemberInput = {
  id?: string | null,
  isFacilitator?: boolean | null,
  deviceId: string,
  teamTeamMembersId?: string | null,
};

export type ModelTeamMemberConditionInput = {
  isFacilitator?: ModelBooleanInput | null,
  deviceId?: ModelIDInput | null,
  and?: Array< ModelTeamMemberConditionInput | null > | null,
  or?: Array< ModelTeamMemberConditionInput | null > | null,
  not?: ModelTeamMemberConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  teamTeamMembersId?: ModelIDInput | null,
};

export type UpdateTeamMemberInput = {
  id: string,
  isFacilitator?: boolean | null,
  deviceId?: string | null,
  teamTeamMembersId?: string | null,
};

export type DeleteTeamMemberInput = {
  id: string,
};

export type CreateTeamAnswerInput = {
  id?: string | null,
  isCorrect?: boolean | null,
  isSubmitted: boolean,
  isShortAnswerEnabled: boolean,
  currentState: GameSessionState,
  currentQuestionIndex: number,
  questionId: string,
  teamMemberAnswersId: string,
  teamAnswersId?: string | null,
  teamName?: string | null,
  text: string,
  answer: string,
  confidenceLevel?: ConfidenceLevel | null,
  hint?: string | null,
};

export type ModelTeamAnswerConditionInput = {
  isCorrect?: ModelBooleanInput | null,
  isSubmitted?: ModelBooleanInput | null,
  isShortAnswerEnabled?: ModelBooleanInput | null,
  currentState?: ModelGameSessionStateInput | null,
  currentQuestionIndex?: ModelIntInput | null,
  questionId?: ModelIDInput | null,
  teamMemberAnswersId?: ModelIDInput | null,
  teamAnswersId?: ModelIDInput | null,
  teamName?: ModelStringInput | null,
  text?: ModelStringInput | null,
  answer?: ModelStringInput | null,
  confidenceLevel?: ModelConfidenceLevelInput | null,
  hint?: ModelStringInput | null,
  and?: Array< ModelTeamAnswerConditionInput | null > | null,
  or?: Array< ModelTeamAnswerConditionInput | null > | null,
  not?: ModelTeamAnswerConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type ModelConfidenceLevelInput = {
  eq?: ConfidenceLevel | null,
  ne?: ConfidenceLevel | null,
};

export type UpdateTeamAnswerInput = {
  id: string,
  isCorrect?: boolean | null,
  isSubmitted?: boolean | null,
  isShortAnswerEnabled?: boolean | null,
  currentState?: GameSessionState | null,
  currentQuestionIndex?: number | null,
  questionId?: string | null,
  teamMemberAnswersId?: string | null,
  teamAnswersId?: string | null,
  teamName?: string | null,
  text?: string | null,
  answer?: string | null,
  confidenceLevel?: ConfidenceLevel | null,
  hint?: string | null,
};

export type DeleteTeamAnswerInput = {
  id: string,
};

export type CreateGameQuestionsInput = {
  id?: string | null,
  gameTemplateID: string,
  questionTemplateID: string,
};

export type ModelGameQuestionsConditionInput = {
  gameTemplateID?: ModelIDInput | null,
  questionTemplateID?: ModelIDInput | null,
  and?: Array< ModelGameQuestionsConditionInput | null > | null,
  or?: Array< ModelGameQuestionsConditionInput | null > | null,
  not?: ModelGameQuestionsConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type UpdateGameQuestionsInput = {
  id: string,
  gameTemplateID?: string | null,
  questionTemplateID?: string | null,
};

export type DeleteGameQuestionsInput = {
  id: string,
};

export type ModelGameTemplateFilterInput = {
  id?: ModelIDInput | null,
  title?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  version?: ModelIntInput | null,
  description?: ModelStringInput | null,
  domain?: ModelStringInput | null,
  cluster?: ModelStringInput | null,
  grade?: ModelStringInput | null,
  standard?: ModelStringInput | null,
  phaseOneTime?: ModelIntInput | null,
  phaseTwoTime?: ModelIntInput | null,
  imageUrl?: ModelStringInput | null,
  questionTemplatesCount?: ModelIntInput | null,
  questionTemplatesOrder?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  type?: ModelStringInput | null,
  and?: Array< ModelGameTemplateFilterInput | null > | null,
  or?: Array< ModelGameTemplateFilterInput | null > | null,
  not?: ModelGameTemplateFilterInput | null,
};

export type ModelGameTemplateConnection = {
  __typename: "ModelGameTemplateConnection",
  items:  Array<GameTemplate | null >,
  nextToken?: string | null,
};

export type ModelQuestionTemplateFilterInput = {
  id?: ModelIDInput | null,
  title?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  version?: ModelIntInput | null,
  choices?: ModelStringInput | null,
  instructions?: ModelStringInput | null,
  answerSettings?: ModelStringInput | null,
  domain?: ModelStringInput | null,
  cluster?: ModelStringInput | null,
  grade?: ModelStringInput | null,
  standard?: ModelStringInput | null,
  imageUrl?: ModelStringInput | null,
  gameTemplatesCount?: ModelIntInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  type?: ModelStringInput | null,
  and?: Array< ModelQuestionTemplateFilterInput | null > | null,
  or?: Array< ModelQuestionTemplateFilterInput | null > | null,
  not?: ModelQuestionTemplateFilterInput | null,
};

export type ModelQuestionTemplateConnection = {
  __typename: "ModelQuestionTemplateConnection",
  items:  Array<QuestionTemplate | null >,
  nextToken?: string | null,
};

export type ModelGameSessionFilterInput = {
  id?: ModelIDInput | null,
  gameId?: ModelIDInput | null,
  startTime?: ModelStringInput | null,
  phaseOneTime?: ModelIntInput | null,
  phaseTwoTime?: ModelIntInput | null,
  currentQuestionIndex?: ModelIntInput | null,
  currentState?: ModelGameSessionStateInput | null,
  gameCode?: ModelIntInput | null,
  isAdvancedMode?: ModelBooleanInput | null,
  imageUrl?: ModelStringInput | null,
  description?: ModelStringInput | null,
  title?: ModelStringInput | null,
  currentTimer?: ModelIntInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelGameSessionFilterInput | null > | null,
  or?: Array< ModelGameSessionFilterInput | null > | null,
  not?: ModelGameSessionFilterInput | null,
};

export type ModelGameSessionConnection = {
  __typename: "ModelGameSessionConnection",
  items:  Array<GameSession | null >,
  nextToken?: string | null,
};

export type ModelQuestionPrimaryCompositeKeyConditionInput = {
  eq?: ModelQuestionPrimaryCompositeKeyInput | null,
  le?: ModelQuestionPrimaryCompositeKeyInput | null,
  lt?: ModelQuestionPrimaryCompositeKeyInput | null,
  ge?: ModelQuestionPrimaryCompositeKeyInput | null,
  gt?: ModelQuestionPrimaryCompositeKeyInput | null,
  between?: Array< ModelQuestionPrimaryCompositeKeyInput | null > | null,
  beginsWith?: ModelQuestionPrimaryCompositeKeyInput | null,
};

export type ModelQuestionPrimaryCompositeKeyInput = {
  order?: number | null,
  gameSessionId?: string | null,
};

export type ModelQuestionFilterInput = {
  id?: ModelIDInput | null,
  text?: ModelStringInput | null,
  choices?: ModelStringInput | null,
  answerSettings?: ModelStringInput | null,
  responses?: ModelStringInput | null,
  hints?: ModelStringInput | null,
  imageUrl?: ModelStringInput | null,
  instructions?: ModelStringInput | null,
  standard?: ModelStringInput | null,
  cluster?: ModelStringInput | null,
  domain?: ModelStringInput | null,
  grade?: ModelStringInput | null,
  order?: ModelIntInput | null,
  isConfidenceEnabled?: ModelBooleanInput | null,
  isShortAnswerEnabled?: ModelBooleanInput | null,
  isHintEnabled?: ModelBooleanInput | null,
  gameSessionId?: ModelIDInput | null,
  and?: Array< ModelQuestionFilterInput | null > | null,
  or?: Array< ModelQuestionFilterInput | null > | null,
  not?: ModelQuestionFilterInput | null,
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC",
}


export type ModelTeamFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  score?: ModelIntInput | null,
  selectedAvatarIndex?: ModelIntInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelTeamFilterInput | null > | null,
  or?: Array< ModelTeamFilterInput | null > | null,
  not?: ModelTeamFilterInput | null,
  gameSessionTeamsId?: ModelIDInput | null,
  teamQuestionId?: ModelIDInput | null,
  teamQuestionOrder?: ModelIntInput | null,
  teamQuestionGameSessionId?: ModelIDInput | null,
};

export type ModelTeamMemberFilterInput = {
  id?: ModelIDInput | null,
  isFacilitator?: ModelBooleanInput | null,
  deviceId?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelTeamMemberFilterInput | null > | null,
  or?: Array< ModelTeamMemberFilterInput | null > | null,
  not?: ModelTeamMemberFilterInput | null,
  teamTeamMembersId?: ModelIDInput | null,
};

export type ModelTeamAnswerFilterInput = {
  id?: ModelIDInput | null,
  isCorrect?: ModelBooleanInput | null,
  isSubmitted?: ModelBooleanInput | null,
  isShortAnswerEnabled?: ModelBooleanInput | null,
  currentState?: ModelGameSessionStateInput | null,
  currentQuestionIndex?: ModelIntInput | null,
  questionId?: ModelIDInput | null,
  teamMemberAnswersId?: ModelIDInput | null,
  teamAnswersId?: ModelIDInput | null,
  teamName?: ModelStringInput | null,
  text?: ModelStringInput | null,
  answer?: ModelStringInput | null,
  confidenceLevel?: ModelConfidenceLevelInput | null,
  hint?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelTeamAnswerFilterInput | null > | null,
  or?: Array< ModelTeamAnswerFilterInput | null > | null,
  not?: ModelTeamAnswerFilterInput | null,
};

export type ModelGameQuestionsFilterInput = {
  id?: ModelIDInput | null,
  gameTemplateID?: ModelIDInput | null,
  questionTemplateID?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelGameQuestionsFilterInput | null > | null,
  or?: Array< ModelGameQuestionsFilterInput | null > | null,
  not?: ModelGameQuestionsFilterInput | null,
};

export type ModelStringKeyConditionInput = {
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export type ModelIntKeyConditionInput = {
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelSubscriptionGameTemplateFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  title?: ModelSubscriptionStringInput | null,
  owner?: ModelSubscriptionStringInput | null,
  version?: ModelSubscriptionIntInput | null,
  description?: ModelSubscriptionStringInput | null,
  domain?: ModelSubscriptionStringInput | null,
  cluster?: ModelSubscriptionStringInput | null,
  grade?: ModelSubscriptionStringInput | null,
  standard?: ModelSubscriptionStringInput | null,
  phaseOneTime?: ModelSubscriptionIntInput | null,
  phaseTwoTime?: ModelSubscriptionIntInput | null,
  imageUrl?: ModelSubscriptionStringInput | null,
  questionTemplatesCount?: ModelSubscriptionIntInput | null,
  questionTemplatesOrder?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  type?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionGameTemplateFilterInput | null > | null,
  or?: Array< ModelSubscriptionGameTemplateFilterInput | null > | null,
};

export type ModelSubscriptionIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  in?: Array< string | null > | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  in?: Array< string | null > | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  in?: Array< number | null > | null,
  notIn?: Array< number | null > | null,
};

export type ModelSubscriptionQuestionTemplateFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  title?: ModelSubscriptionStringInput | null,
  owner?: ModelSubscriptionStringInput | null,
  version?: ModelSubscriptionIntInput | null,
  choices?: ModelSubscriptionStringInput | null,
  instructions?: ModelSubscriptionStringInput | null,
  answerSettings?: ModelSubscriptionStringInput | null,
  domain?: ModelSubscriptionStringInput | null,
  cluster?: ModelSubscriptionStringInput | null,
  grade?: ModelSubscriptionStringInput | null,
  standard?: ModelSubscriptionStringInput | null,
  imageUrl?: ModelSubscriptionStringInput | null,
  gameTemplatesCount?: ModelSubscriptionIntInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  type?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionQuestionTemplateFilterInput | null > | null,
  or?: Array< ModelSubscriptionQuestionTemplateFilterInput | null > | null,
};

export type ModelSubscriptionGameSessionFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  gameId?: ModelSubscriptionIDInput | null,
  startTime?: ModelSubscriptionStringInput | null,
  phaseOneTime?: ModelSubscriptionIntInput | null,
  phaseTwoTime?: ModelSubscriptionIntInput | null,
  currentQuestionIndex?: ModelSubscriptionIntInput | null,
  currentState?: ModelSubscriptionStringInput | null,
  gameCode?: ModelSubscriptionIntInput | null,
  isAdvancedMode?: ModelSubscriptionBooleanInput | null,
  imageUrl?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  title?: ModelSubscriptionStringInput | null,
  currentTimer?: ModelSubscriptionIntInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionGameSessionFilterInput | null > | null,
  or?: Array< ModelSubscriptionGameSessionFilterInput | null > | null,
  gameSessionTeamsId?: ModelSubscriptionIDInput | null,
};

export type ModelSubscriptionBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
};

export type ModelSubscriptionTeamFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  score?: ModelSubscriptionIntInput | null,
  selectedAvatarIndex?: ModelSubscriptionIntInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionTeamFilterInput | null > | null,
  or?: Array< ModelSubscriptionTeamFilterInput | null > | null,
  teamTeamMembersId?: ModelSubscriptionIDInput | null,
  teamQuestionId?: ModelSubscriptionIDInput | null,
  teamQuestionOrder?: ModelSubscriptionIntInput | null,
  teamQuestionGameSessionId?: ModelSubscriptionIDInput | null,
};

export type ModelSubscriptionTeamMemberFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  isFacilitator?: ModelSubscriptionBooleanInput | null,
  deviceId?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionTeamMemberFilterInput | null > | null,
  or?: Array< ModelSubscriptionTeamMemberFilterInput | null > | null,
  teamMemberAnswersId?: ModelSubscriptionIDInput | null,
};

export type ModelSubscriptionTeamAnswerFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  isCorrect?: ModelSubscriptionBooleanInput | null,
  isSubmitted?: ModelSubscriptionBooleanInput | null,
  isShortAnswerEnabled?: ModelSubscriptionBooleanInput | null,
  currentState?: ModelSubscriptionStringInput | null,
  currentQuestionIndex?: ModelSubscriptionIntInput | null,
  questionId?: ModelSubscriptionIDInput | null,
  teamMemberAnswersId?: ModelSubscriptionIDInput | null,
  teamAnswersId?: ModelSubscriptionIDInput | null,
  teamName?: ModelSubscriptionStringInput | null,
  text?: ModelSubscriptionStringInput | null,
  answer?: ModelSubscriptionStringInput | null,
  confidenceLevel?: ModelSubscriptionStringInput | null,
  hint?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionTeamAnswerFilterInput | null > | null,
  or?: Array< ModelSubscriptionTeamAnswerFilterInput | null > | null,
};

export type ModelSubscriptionGameQuestionsFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  gameTemplateID?: ModelSubscriptionIDInput | null,
  questionTemplateID?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionGameQuestionsFilterInput | null > | null,
  or?: Array< ModelSubscriptionGameQuestionsFilterInput | null > | null,
};

export type CreateGameSessionFromTemplateMutationVariables = {
  input: CreateGameSessionFromTemplateInput,
};

export type CreateGameSessionFromTemplateMutation = {
  createGameSessionFromTemplate?: string | null,
};

export type CreateGameTemplateMutationVariables = {
  input: CreateGameTemplateInput,
  condition?: ModelGameTemplateConditionInput | null,
};

export type CreateGameTemplateMutation = {
  createGameTemplate?:  {
    __typename: "GameTemplate",
    id: string,
    title: string,
    owner: string,
    version: number,
    description: string,
    domain?: string | null,
    cluster?: string | null,
    grade?: string | null,
    standard?: string | null,
    phaseOneTime?: number | null,
    phaseTwoTime?: number | null,
    imageUrl?: string | null,
    questionTemplates?:  {
      __typename: "ModelGameQuestionsConnection",
      items:  Array< {
        __typename: "GameQuestions",
        id: string,
        gameTemplateID: string,
        questionTemplateID: string,
        gameTemplate:  {
          __typename: "GameTemplate",
          id: string,
          title: string,
          owner: string,
          version: number,
          description: string,
          domain?: string | null,
          cluster?: string | null,
          grade?: string | null,
          standard?: string | null,
          phaseOneTime?: number | null,
          phaseTwoTime?: number | null,
          imageUrl?: string | null,
          questionTemplates?:  {
            __typename: "ModelGameQuestionsConnection",
            items:  Array< {
              __typename: "GameQuestions",
              id: string,
              gameTemplateID: string,
              questionTemplateID: string,
              gameTemplate:  {
                __typename: "GameTemplate",
                id: string,
                title: string,
                owner: string,
                version: number,
                description: string,
                domain?: string | null,
                cluster?: string | null,
                grade?: string | null,
                standard?: string | null,
                phaseOneTime?: number | null,
                phaseTwoTime?: number | null,
                imageUrl?: string | null,
                questionTemplates?:  {
                  __typename: "ModelGameQuestionsConnection",
                  nextToken?: string | null,
                } | null,
                questionTemplatesCount: number,
                questionTemplatesOrder?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
                type?: string | null,
              },
              questionTemplate:  {
                __typename: "QuestionTemplate",
                id: string,
                title: string,
                owner: string,
                version: number,
                choices?: string | null,
                instructions?: string | null,
                answerSettings?: string | null,
                domain?: string | null,
                cluster?: string | null,
                grade?: string | null,
                standard?: string | null,
                imageUrl?: string | null,
                gameTemplates?:  {
                  __typename: "ModelGameQuestionsConnection",
                  nextToken?: string | null,
                } | null,
                gameTemplatesCount: number,
                createdAt?: string | null,
                updatedAt?: string | null,
                type?: string | null,
              },
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          questionTemplatesCount: number,
          questionTemplatesOrder?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          type?: string | null,
        },
        questionTemplate:  {
          __typename: "QuestionTemplate",
          id: string,
          title: string,
          owner: string,
          version: number,
          choices?: string | null,
          instructions?: string | null,
          answerSettings?: string | null,
          domain?: string | null,
          cluster?: string | null,
          grade?: string | null,
          standard?: string | null,
          imageUrl?: string | null,
          gameTemplates?:  {
            __typename: "ModelGameQuestionsConnection",
            items:  Array< {
              __typename: "GameQuestions",
              id: string,
              gameTemplateID: string,
              questionTemplateID: string,
              gameTemplate:  {
                __typename: "GameTemplate",
                id: string,
                title: string,
                owner: string,
                version: number,
                description: string,
                domain?: string | null,
                cluster?: string | null,
                grade?: string | null,
                standard?: string | null,
                phaseOneTime?: number | null,
                phaseTwoTime?: number | null,
                imageUrl?: string | null,
                questionTemplates?:  {
                  __typename: "ModelGameQuestionsConnection",
                  nextToken?: string | null,
                } | null,
                questionTemplatesCount: number,
                questionTemplatesOrder?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
                type?: string | null,
              },
              questionTemplate:  {
                __typename: "QuestionTemplate",
                id: string,
                title: string,
                owner: string,
                version: number,
                choices?: string | null,
                instructions?: string | null,
                answerSettings?: string | null,
                domain?: string | null,
                cluster?: string | null,
                grade?: string | null,
                standard?: string | null,
                imageUrl?: string | null,
                gameTemplates?:  {
                  __typename: "ModelGameQuestionsConnection",
                  nextToken?: string | null,
                } | null,
                gameTemplatesCount: number,
                createdAt?: string | null,
                updatedAt?: string | null,
                type?: string | null,
              },
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          gameTemplatesCount: number,
          createdAt?: string | null,
          updatedAt?: string | null,
          type?: string | null,
        },
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    questionTemplatesCount: number,
    questionTemplatesOrder?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    type?: string | null,
  } | null,
};

export type UpdateGameTemplateMutationVariables = {
  input: UpdateGameTemplateInput,
  condition?: ModelGameTemplateConditionInput | null,
};

export type UpdateGameTemplateMutation = {
  updateGameTemplate?:  {
    __typename: "GameTemplate",
    id: string,
    title: string,
    owner: string,
    version: number,
    description: string,
    domain?: string | null,
    cluster?: string | null,
    grade?: string | null,
    standard?: string | null,
    phaseOneTime?: number | null,
    phaseTwoTime?: number | null,
    imageUrl?: string | null,
    questionTemplates?:  {
      __typename: "ModelGameQuestionsConnection",
      items:  Array< {
        __typename: "GameQuestions",
        id: string,
        gameTemplateID: string,
        questionTemplateID: string,
        gameTemplate:  {
          __typename: "GameTemplate",
          id: string,
          title: string,
          owner: string,
          version: number,
          description: string,
          domain?: string | null,
          cluster?: string | null,
          grade?: string | null,
          standard?: string | null,
          phaseOneTime?: number | null,
          phaseTwoTime?: number | null,
          imageUrl?: string | null,
          questionTemplates?:  {
            __typename: "ModelGameQuestionsConnection",
            items:  Array< {
              __typename: "GameQuestions",
              id: string,
              gameTemplateID: string,
              questionTemplateID: string,
              gameTemplate:  {
                __typename: "GameTemplate",
                id: string,
                title: string,
                owner: string,
                version: number,
                description: string,
                domain?: string | null,
                cluster?: string | null,
                grade?: string | null,
                standard?: string | null,
                phaseOneTime?: number | null,
                phaseTwoTime?: number | null,
                imageUrl?: string | null,
                questionTemplates?:  {
                  __typename: "ModelGameQuestionsConnection",
                  nextToken?: string | null,
                } | null,
                questionTemplatesCount: number,
                questionTemplatesOrder?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
                type?: string | null,
              },
              questionTemplate:  {
                __typename: "QuestionTemplate",
                id: string,
                title: string,
                owner: string,
                version: number,
                choices?: string | null,
                instructions?: string | null,
                answerSettings?: string | null,
                domain?: string | null,
                cluster?: string | null,
                grade?: string | null,
                standard?: string | null,
                imageUrl?: string | null,
                gameTemplates?:  {
                  __typename: "ModelGameQuestionsConnection",
                  nextToken?: string | null,
                } | null,
                gameTemplatesCount: number,
                createdAt?: string | null,
                updatedAt?: string | null,
                type?: string | null,
              },
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          questionTemplatesCount: number,
          questionTemplatesOrder?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          type?: string | null,
        },
        questionTemplate:  {
          __typename: "QuestionTemplate",
          id: string,
          title: string,
          owner: string,
          version: number,
          choices?: string | null,
          instructions?: string | null,
          answerSettings?: string | null,
          domain?: string | null,
          cluster?: string | null,
          grade?: string | null,
          standard?: string | null,
          imageUrl?: string | null,
          gameTemplates?:  {
            __typename: "ModelGameQuestionsConnection",
            items:  Array< {
              __typename: "GameQuestions",
              id: string,
              gameTemplateID: string,
              questionTemplateID: string,
              gameTemplate:  {
                __typename: "GameTemplate",
                id: string,
                title: string,
                owner: string,
                version: number,
                description: string,
                domain?: string | null,
                cluster?: string | null,
                grade?: string | null,
                standard?: string | null,
                phaseOneTime?: number | null,
                phaseTwoTime?: number | null,
                imageUrl?: string | null,
                questionTemplates?:  {
                  __typename: "ModelGameQuestionsConnection",
                  nextToken?: string | null,
                } | null,
                questionTemplatesCount: number,
                questionTemplatesOrder?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
                type?: string | null,
              },
              questionTemplate:  {
                __typename: "QuestionTemplate",
                id: string,
                title: string,
                owner: string,
                version: number,
                choices?: string | null,
                instructions?: string | null,
                answerSettings?: string | null,
                domain?: string | null,
                cluster?: string | null,
                grade?: string | null,
                standard?: string | null,
                imageUrl?: string | null,
                gameTemplates?:  {
                  __typename: "ModelGameQuestionsConnection",
                  nextToken?: string | null,
                } | null,
                gameTemplatesCount: number,
                createdAt?: string | null,
                updatedAt?: string | null,
                type?: string | null,
              },
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          gameTemplatesCount: number,
          createdAt?: string | null,
          updatedAt?: string | null,
          type?: string | null,
        },
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    questionTemplatesCount: number,
    questionTemplatesOrder?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    type?: string | null,
  } | null,
};

export type DeleteGameTemplateMutationVariables = {
  input: DeleteGameTemplateInput,
  condition?: ModelGameTemplateConditionInput | null,
};

export type DeleteGameTemplateMutation = {
  deleteGameTemplate?:  {
    __typename: "GameTemplate",
    id: string,
    title: string,
    owner: string,
    version: number,
    description: string,
    domain?: string | null,
    cluster?: string | null,
    grade?: string | null,
    standard?: string | null,
    phaseOneTime?: number | null,
    phaseTwoTime?: number | null,
    imageUrl?: string | null,
    questionTemplates?:  {
      __typename: "ModelGameQuestionsConnection",
      items:  Array< {
        __typename: "GameQuestions",
        id: string,
        gameTemplateID: string,
        questionTemplateID: string,
        gameTemplate:  {
          __typename: "GameTemplate",
          id: string,
          title: string,
          owner: string,
          version: number,
          description: string,
          domain?: string | null,
          cluster?: string | null,
          grade?: string | null,
          standard?: string | null,
          phaseOneTime?: number | null,
          phaseTwoTime?: number | null,
          imageUrl?: string | null,
          questionTemplates?:  {
            __typename: "ModelGameQuestionsConnection",
            items:  Array< {
              __typename: "GameQuestions",
              id: string,
              gameTemplateID: string,
              questionTemplateID: string,
              gameTemplate:  {
                __typename: "GameTemplate",
                id: string,
                title: string,
                owner: string,
                version: number,
                description: string,
                domain?: string | null,
                cluster?: string | null,
                grade?: string | null,
                standard?: string | null,
                phaseOneTime?: number | null,
                phaseTwoTime?: number | null,
                imageUrl?: string | null,
                questionTemplates?:  {
                  __typename: "ModelGameQuestionsConnection",
                  nextToken?: string | null,
                } | null,
                questionTemplatesCount: number,
                questionTemplatesOrder?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
                type?: string | null,
              },
              questionTemplate:  {
                __typename: "QuestionTemplate",
                id: string,
                title: string,
                owner: string,
                version: number,
                choices?: string | null,
                instructions?: string | null,
                answerSettings?: string | null,
                domain?: string | null,
                cluster?: string | null,
                grade?: string | null,
                standard?: string | null,
                imageUrl?: string | null,
                gameTemplates?:  {
                  __typename: "ModelGameQuestionsConnection",
                  nextToken?: string | null,
                } | null,
                gameTemplatesCount: number,
                createdAt?: string | null,
                updatedAt?: string | null,
                type?: string | null,
              },
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          questionTemplatesCount: number,
          questionTemplatesOrder?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          type?: string | null,
        },
        questionTemplate:  {
          __typename: "QuestionTemplate",
          id: string,
          title: string,
          owner: string,
          version: number,
          choices?: string | null,
          instructions?: string | null,
          answerSettings?: string | null,
          domain?: string | null,
          cluster?: string | null,
          grade?: string | null,
          standard?: string | null,
          imageUrl?: string | null,
          gameTemplates?:  {
            __typename: "ModelGameQuestionsConnection",
            items:  Array< {
              __typename: "GameQuestions",
              id: string,
              gameTemplateID: string,
              questionTemplateID: string,
              gameTemplate:  {
                __typename: "GameTemplate",
                id: string,
                title: string,
                owner: string,
                version: number,
                description: string,
                domain?: string | null,
                cluster?: string | null,
                grade?: string | null,
                standard?: string | null,
                phaseOneTime?: number | null,
                phaseTwoTime?: number | null,
                imageUrl?: string | null,
                questionTemplates?:  {
                  __typename: "ModelGameQuestionsConnection",
                  nextToken?: string | null,
                } | null,
                questionTemplatesCount: number,
                questionTemplatesOrder?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
                type?: string | null,
              },
              questionTemplate:  {
                __typename: "QuestionTemplate",
                id: string,
                title: string,
                owner: string,
                version: number,
                choices?: string | null,
                instructions?: string | null,
                answerSettings?: string | null,
                domain?: string | null,
                cluster?: string | null,
                grade?: string | null,
                standard?: string | null,
                imageUrl?: string | null,
                gameTemplates?:  {
                  __typename: "ModelGameQuestionsConnection",
                  nextToken?: string | null,
                } | null,
                gameTemplatesCount: number,
                createdAt?: string | null,
                updatedAt?: string | null,
                type?: string | null,
              },
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          gameTemplatesCount: number,
          createdAt?: string | null,
          updatedAt?: string | null,
          type?: string | null,
        },
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    questionTemplatesCount: number,
    questionTemplatesOrder?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    type?: string | null,
  } | null,
};

export type CreateQuestionTemplateMutationVariables = {
  input: CreateQuestionTemplateInput,
  condition?: ModelQuestionTemplateConditionInput | null,
};

export type CreateQuestionTemplateMutation = {
  createQuestionTemplate?:  {
    __typename: "QuestionTemplate",
    id: string,
    title: string,
    owner: string,
    version: number,
    choices?: string | null,
    instructions?: string | null,
    answerSettings?: string | null,
    domain?: string | null,
    cluster?: string | null,
    grade?: string | null,
    standard?: string | null,
    imageUrl?: string | null,
    gameTemplates?:  {
      __typename: "ModelGameQuestionsConnection",
      items:  Array< {
        __typename: "GameQuestions",
        id: string,
        gameTemplateID: string,
        questionTemplateID: string,
        gameTemplate:  {
          __typename: "GameTemplate",
          id: string,
          title: string,
          owner: string,
          version: number,
          description: string,
          domain?: string | null,
          cluster?: string | null,
          grade?: string | null,
          standard?: string | null,
          phaseOneTime?: number | null,
          phaseTwoTime?: number | null,
          imageUrl?: string | null,
          questionTemplates?:  {
            __typename: "ModelGameQuestionsConnection",
            items:  Array< {
              __typename: "GameQuestions",
              id: string,
              gameTemplateID: string,
              questionTemplateID: string,
              gameTemplate:  {
                __typename: "GameTemplate",
                id: string,
                title: string,
                owner: string,
                version: number,
                description: string,
                domain?: string | null,
                cluster?: string | null,
                grade?: string | null,
                standard?: string | null,
                phaseOneTime?: number | null,
                phaseTwoTime?: number | null,
                imageUrl?: string | null,
                questionTemplates?:  {
                  __typename: "ModelGameQuestionsConnection",
                  nextToken?: string | null,
                } | null,
                questionTemplatesCount: number,
                questionTemplatesOrder?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
                type?: string | null,
              },
              questionTemplate:  {
                __typename: "QuestionTemplate",
                id: string,
                title: string,
                owner: string,
                version: number,
                choices?: string | null,
                instructions?: string | null,
                answerSettings?: string | null,
                domain?: string | null,
                cluster?: string | null,
                grade?: string | null,
                standard?: string | null,
                imageUrl?: string | null,
                gameTemplates?:  {
                  __typename: "ModelGameQuestionsConnection",
                  nextToken?: string | null,
                } | null,
                gameTemplatesCount: number,
                createdAt?: string | null,
                updatedAt?: string | null,
                type?: string | null,
              },
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          questionTemplatesCount: number,
          questionTemplatesOrder?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          type?: string | null,
        },
        questionTemplate:  {
          __typename: "QuestionTemplate",
          id: string,
          title: string,
          owner: string,
          version: number,
          choices?: string | null,
          instructions?: string | null,
          answerSettings?: string | null,
          domain?: string | null,
          cluster?: string | null,
          grade?: string | null,
          standard?: string | null,
          imageUrl?: string | null,
          gameTemplates?:  {
            __typename: "ModelGameQuestionsConnection",
            items:  Array< {
              __typename: "GameQuestions",
              id: string,
              gameTemplateID: string,
              questionTemplateID: string,
              gameTemplate:  {
                __typename: "GameTemplate",
                id: string,
                title: string,
                owner: string,
                version: number,
                description: string,
                domain?: string | null,
                cluster?: string | null,
                grade?: string | null,
                standard?: string | null,
                phaseOneTime?: number | null,
                phaseTwoTime?: number | null,
                imageUrl?: string | null,
                questionTemplates?:  {
                  __typename: "ModelGameQuestionsConnection",
                  nextToken?: string | null,
                } | null,
                questionTemplatesCount: number,
                questionTemplatesOrder?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
                type?: string | null,
              },
              questionTemplate:  {
                __typename: "QuestionTemplate",
                id: string,
                title: string,
                owner: string,
                version: number,
                choices?: string | null,
                instructions?: string | null,
                answerSettings?: string | null,
                domain?: string | null,
                cluster?: string | null,
                grade?: string | null,
                standard?: string | null,
                imageUrl?: string | null,
                gameTemplates?:  {
                  __typename: "ModelGameQuestionsConnection",
                  nextToken?: string | null,
                } | null,
                gameTemplatesCount: number,
                createdAt?: string | null,
                updatedAt?: string | null,
                type?: string | null,
              },
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          gameTemplatesCount: number,
          createdAt?: string | null,
          updatedAt?: string | null,
          type?: string | null,
        },
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    gameTemplatesCount: number,
    createdAt?: string | null,
    updatedAt?: string | null,
    type?: string | null,
  } | null,
};

export type UpdateQuestionTemplateMutationVariables = {
  input: UpdateQuestionTemplateInput,
  condition?: ModelQuestionTemplateConditionInput | null,
};

export type UpdateQuestionTemplateMutation = {
  updateQuestionTemplate?:  {
    __typename: "QuestionTemplate",
    id: string,
    title: string,
    owner: string,
    version: number,
    choices?: string | null,
    instructions?: string | null,
    answerSettings?: string | null,
    domain?: string | null,
    cluster?: string | null,
    grade?: string | null,
    standard?: string | null,
    imageUrl?: string | null,
    gameTemplates?:  {
      __typename: "ModelGameQuestionsConnection",
      items:  Array< {
        __typename: "GameQuestions",
        id: string,
        gameTemplateID: string,
        questionTemplateID: string,
        gameTemplate:  {
          __typename: "GameTemplate",
          id: string,
          title: string,
          owner: string,
          version: number,
          description: string,
          domain?: string | null,
          cluster?: string | null,
          grade?: string | null,
          standard?: string | null,
          phaseOneTime?: number | null,
          phaseTwoTime?: number | null,
          imageUrl?: string | null,
          questionTemplates?:  {
            __typename: "ModelGameQuestionsConnection",
            items:  Array< {
              __typename: "GameQuestions",
              id: string,
              gameTemplateID: string,
              questionTemplateID: string,
              gameTemplate:  {
                __typename: "GameTemplate",
                id: string,
                title: string,
                owner: string,
                version: number,
                description: string,
                domain?: string | null,
                cluster?: string | null,
                grade?: string | null,
                standard?: string | null,
                phaseOneTime?: number | null,
                phaseTwoTime?: number | null,
                imageUrl?: string | null,
                questionTemplates?:  {
                  __typename: "ModelGameQuestionsConnection",
                  nextToken?: string | null,
                } | null,
                questionTemplatesCount: number,
                questionTemplatesOrder?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
                type?: string | null,
              },
              questionTemplate:  {
                __typename: "QuestionTemplate",
                id: string,
                title: string,
                owner: string,
                version: number,
                choices?: string | null,
                instructions?: string | null,
                answerSettings?: string | null,
                domain?: string | null,
                cluster?: string | null,
                grade?: string | null,
                standard?: string | null,
                imageUrl?: string | null,
                gameTemplates?:  {
                  __typename: "ModelGameQuestionsConnection",
                  nextToken?: string | null,
                } | null,
                gameTemplatesCount: number,
                createdAt?: string | null,
                updatedAt?: string | null,
                type?: string | null,
              },
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          questionTemplatesCount: number,
          questionTemplatesOrder?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          type?: string | null,
        },
        questionTemplate:  {
          __typename: "QuestionTemplate",
          id: string,
          title: string,
          owner: string,
          version: number,
          choices?: string | null,
          instructions?: string | null,
          answerSettings?: string | null,
          domain?: string | null,
          cluster?: string | null,
          grade?: string | null,
          standard?: string | null,
          imageUrl?: string | null,
          gameTemplates?:  {
            __typename: "ModelGameQuestionsConnection",
            items:  Array< {
              __typename: "GameQuestions",
              id: string,
              gameTemplateID: string,
              questionTemplateID: string,
              gameTemplate:  {
                __typename: "GameTemplate",
                id: string,
                title: string,
                owner: string,
                version: number,
                description: string,
                domain?: string | null,
                cluster?: string | null,
                grade?: string | null,
                standard?: string | null,
                phaseOneTime?: number | null,
                phaseTwoTime?: number | null,
                imageUrl?: string | null,
                questionTemplates?:  {
                  __typename: "ModelGameQuestionsConnection",
                  nextToken?: string | null,
                } | null,
                questionTemplatesCount: number,
                questionTemplatesOrder?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
                type?: string | null,
              },
              questionTemplate:  {
                __typename: "QuestionTemplate",
                id: string,
                title: string,
                owner: string,
                version: number,
                choices?: string | null,
                instructions?: string | null,
                answerSettings?: string | null,
                domain?: string | null,
                cluster?: string | null,
                grade?: string | null,
                standard?: string | null,
                imageUrl?: string | null,
                gameTemplates?:  {
                  __typename: "ModelGameQuestionsConnection",
                  nextToken?: string | null,
                } | null,
                gameTemplatesCount: number,
                createdAt?: string | null,
                updatedAt?: string | null,
                type?: string | null,
              },
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          gameTemplatesCount: number,
          createdAt?: string | null,
          updatedAt?: string | null,
          type?: string | null,
        },
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    gameTemplatesCount: number,
    createdAt?: string | null,
    updatedAt?: string | null,
    type?: string | null,
  } | null,
};

export type DeleteQuestionTemplateMutationVariables = {
  input: DeleteQuestionTemplateInput,
  condition?: ModelQuestionTemplateConditionInput | null,
};

export type DeleteQuestionTemplateMutation = {
  deleteQuestionTemplate?:  {
    __typename: "QuestionTemplate",
    id: string,
    title: string,
    owner: string,
    version: number,
    choices?: string | null,
    instructions?: string | null,
    answerSettings?: string | null,
    domain?: string | null,
    cluster?: string | null,
    grade?: string | null,
    standard?: string | null,
    imageUrl?: string | null,
    gameTemplates?:  {
      __typename: "ModelGameQuestionsConnection",
      items:  Array< {
        __typename: "GameQuestions",
        id: string,
        gameTemplateID: string,
        questionTemplateID: string,
        gameTemplate:  {
          __typename: "GameTemplate",
          id: string,
          title: string,
          owner: string,
          version: number,
          description: string,
          domain?: string | null,
          cluster?: string | null,
          grade?: string | null,
          standard?: string | null,
          phaseOneTime?: number | null,
          phaseTwoTime?: number | null,
          imageUrl?: string | null,
          questionTemplates?:  {
            __typename: "ModelGameQuestionsConnection",
            items:  Array< {
              __typename: "GameQuestions",
              id: string,
              gameTemplateID: string,
              questionTemplateID: string,
              gameTemplate:  {
                __typename: "GameTemplate",
                id: string,
                title: string,
                owner: string,
                version: number,
                description: string,
                domain?: string | null,
                cluster?: string | null,
                grade?: string | null,
                standard?: string | null,
                phaseOneTime?: number | null,
                phaseTwoTime?: number | null,
                imageUrl?: string | null,
                questionTemplates?:  {
                  __typename: "ModelGameQuestionsConnection",
                  nextToken?: string | null,
                } | null,
                questionTemplatesCount: number,
                questionTemplatesOrder?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
                type?: string | null,
              },
              questionTemplate:  {
                __typename: "QuestionTemplate",
                id: string,
                title: string,
                owner: string,
                version: number,
                choices?: string | null,
                instructions?: string | null,
                answerSettings?: string | null,
                domain?: string | null,
                cluster?: string | null,
                grade?: string | null,
                standard?: string | null,
                imageUrl?: string | null,
                gameTemplates?:  {
                  __typename: "ModelGameQuestionsConnection",
                  nextToken?: string | null,
                } | null,
                gameTemplatesCount: number,
                createdAt?: string | null,
                updatedAt?: string | null,
                type?: string | null,
              },
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          questionTemplatesCount: number,
          questionTemplatesOrder?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          type?: string | null,
        },
        questionTemplate:  {
          __typename: "QuestionTemplate",
          id: string,
          title: string,
          owner: string,
          version: number,
          choices?: string | null,
          instructions?: string | null,
          answerSettings?: string | null,
          domain?: string | null,
          cluster?: string | null,
          grade?: string | null,
          standard?: string | null,
          imageUrl?: string | null,
          gameTemplates?:  {
            __typename: "ModelGameQuestionsConnection",
            items:  Array< {
              __typename: "GameQuestions",
              id: string,
              gameTemplateID: string,
              questionTemplateID: string,
              gameTemplate:  {
                __typename: "GameTemplate",
                id: string,
                title: string,
                owner: string,
                version: number,
                description: string,
                domain?: string | null,
                cluster?: string | null,
                grade?: string | null,
                standard?: string | null,
                phaseOneTime?: number | null,
                phaseTwoTime?: number | null,
                imageUrl?: string | null,
                questionTemplates?:  {
                  __typename: "ModelGameQuestionsConnection",
                  nextToken?: string | null,
                } | null,
                questionTemplatesCount: number,
                questionTemplatesOrder?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
                type?: string | null,
              },
              questionTemplate:  {
                __typename: "QuestionTemplate",
                id: string,
                title: string,
                owner: string,
                version: number,
                choices?: string | null,
                instructions?: string | null,
                answerSettings?: string | null,
                domain?: string | null,
                cluster?: string | null,
                grade?: string | null,
                standard?: string | null,
                imageUrl?: string | null,
                gameTemplates?:  {
                  __typename: "ModelGameQuestionsConnection",
                  nextToken?: string | null,
                } | null,
                gameTemplatesCount: number,
                createdAt?: string | null,
                updatedAt?: string | null,
                type?: string | null,
              },
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          gameTemplatesCount: number,
          createdAt?: string | null,
          updatedAt?: string | null,
          type?: string | null,
        },
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    gameTemplatesCount: number,
    createdAt?: string | null,
    updatedAt?: string | null,
    type?: string | null,
  } | null,
};

export type CreateGameSessionMutationVariables = {
  input: CreateGameSessionInput,
  condition?: ModelGameSessionConditionInput | null,
};

export type CreateGameSessionMutation = {
  createGameSession?:  {
    __typename: "GameSession",
    id: string,
    gameId: string,
    startTime?: string | null,
    phaseOneTime: number,
    phaseTwoTime: number,
    teams?:  {
      __typename: "ModelTeamConnection",
      items:  Array< {
        __typename: "Team",
        id: string,
        name: string,
        question?:  {
          __typename: "Question",
          id: string,
          text: string,
          choices?: string | null,
          answerSettings?: string | null,
          responses?: string | null,
          hints?: string | null,
          imageUrl?: string | null,
          instructions?: string | null,
          standard?: string | null,
          cluster?: string | null,
          domain?: string | null,
          grade?: string | null,
          order: number,
          isConfidenceEnabled: boolean,
          isShortAnswerEnabled: boolean,
          isHintEnabled: boolean,
          gameSessionId: string,
        } | null,
        teamMembers?:  {
          __typename: "ModelTeamMemberConnection",
          items:  Array< {
            __typename: "TeamMember",
            id: string,
            isFacilitator?: boolean | null,
            answers?:  {
              __typename: "ModelTeamAnswerConnection",
              items:  Array< {
                __typename: "TeamAnswer",
                id: string,
                isCorrect?: boolean | null,
                isSubmitted: boolean,
                isShortAnswerEnabled: boolean,
                currentState: GameSessionState,
                currentQuestionIndex: number,
                questionId: string,
                teamMemberAnswersId: string,
                teamAnswersId?: string | null,
                teamName?: string | null,
                text: string,
                answer: string,
                confidenceLevel?: ConfidenceLevel | null,
                hint?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            deviceId: string,
            createdAt: string,
            updatedAt: string,
            teamTeamMembersId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        score: number,
        selectedAvatarIndex: number,
        createdAt: string,
        updatedAt: string,
        gameSessionTeamsId?: string | null,
        teamQuestionId?: string | null,
        teamQuestionOrder?: number | null,
        teamQuestionGameSessionId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    currentQuestionIndex?: number | null,
    currentState: GameSessionState,
    gameCode: number,
    isAdvancedMode: boolean,
    imageUrl?: string | null,
    description?: string | null,
    title?: string | null,
    currentTimer?: number | null,
    questions?:  {
      __typename: "ModelQuestionConnection",
      items:  Array< {
        __typename: "Question",
        id: string,
        text: string,
        choices?: string | null,
        answerSettings?: string | null,
        responses?: string | null,
        hints?: string | null,
        imageUrl?: string | null,
        instructions?: string | null,
        standard?: string | null,
        cluster?: string | null,
        domain?: string | null,
        grade?: string | null,
        order: number,
        isConfidenceEnabled: boolean,
        isShortAnswerEnabled: boolean,
        isHintEnabled: boolean,
        gameSessionId: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateGameSessionMutationVariables = {
  input: UpdateGameSessionInput,
  condition?: ModelGameSessionConditionInput | null,
};

export type UpdateGameSessionMutation = {
  updateGameSession?:  {
    __typename: "GameSession",
    id: string,
    gameId: string,
    startTime?: string | null,
    phaseOneTime: number,
    phaseTwoTime: number,
    teams?:  {
      __typename: "ModelTeamConnection",
      items:  Array< {
        __typename: "Team",
        id: string,
        name: string,
        question?:  {
          __typename: "Question",
          id: string,
          text: string,
          choices?: string | null,
          answerSettings?: string | null,
          responses?: string | null,
          hints?: string | null,
          imageUrl?: string | null,
          instructions?: string | null,
          standard?: string | null,
          cluster?: string | null,
          domain?: string | null,
          grade?: string | null,
          order: number,
          isConfidenceEnabled: boolean,
          isShortAnswerEnabled: boolean,
          isHintEnabled: boolean,
          gameSessionId: string,
        } | null,
        teamMembers?:  {
          __typename: "ModelTeamMemberConnection",
          items:  Array< {
            __typename: "TeamMember",
            id: string,
            isFacilitator?: boolean | null,
            answers?:  {
              __typename: "ModelTeamAnswerConnection",
              items:  Array< {
                __typename: "TeamAnswer",
                id: string,
                isCorrect?: boolean | null,
                isSubmitted: boolean,
                isShortAnswerEnabled: boolean,
                currentState: GameSessionState,
                currentQuestionIndex: number,
                questionId: string,
                teamMemberAnswersId: string,
                teamAnswersId?: string | null,
                teamName?: string | null,
                text: string,
                answer: string,
                confidenceLevel?: ConfidenceLevel | null,
                hint?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            deviceId: string,
            createdAt: string,
            updatedAt: string,
            teamTeamMembersId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        score: number,
        selectedAvatarIndex: number,
        createdAt: string,
        updatedAt: string,
        gameSessionTeamsId?: string | null,
        teamQuestionId?: string | null,
        teamQuestionOrder?: number | null,
        teamQuestionGameSessionId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    currentQuestionIndex?: number | null,
    currentState: GameSessionState,
    gameCode: number,
    isAdvancedMode: boolean,
    imageUrl?: string | null,
    description?: string | null,
    title?: string | null,
    currentTimer?: number | null,
    questions?:  {
      __typename: "ModelQuestionConnection",
      items:  Array< {
        __typename: "Question",
        id: string,
        text: string,
        choices?: string | null,
        answerSettings?: string | null,
        responses?: string | null,
        hints?: string | null,
        imageUrl?: string | null,
        instructions?: string | null,
        standard?: string | null,
        cluster?: string | null,
        domain?: string | null,
        grade?: string | null,
        order: number,
        isConfidenceEnabled: boolean,
        isShortAnswerEnabled: boolean,
        isHintEnabled: boolean,
        gameSessionId: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteGameSessionMutationVariables = {
  input: DeleteGameSessionInput,
  condition?: ModelGameSessionConditionInput | null,
};

export type DeleteGameSessionMutation = {
  deleteGameSession?:  {
    __typename: "GameSession",
    id: string,
    gameId: string,
    startTime?: string | null,
    phaseOneTime: number,
    phaseTwoTime: number,
    teams?:  {
      __typename: "ModelTeamConnection",
      items:  Array< {
        __typename: "Team",
        id: string,
        name: string,
        question?:  {
          __typename: "Question",
          id: string,
          text: string,
          choices?: string | null,
          answerSettings?: string | null,
          responses?: string | null,
          hints?: string | null,
          imageUrl?: string | null,
          instructions?: string | null,
          standard?: string | null,
          cluster?: string | null,
          domain?: string | null,
          grade?: string | null,
          order: number,
          isConfidenceEnabled: boolean,
          isShortAnswerEnabled: boolean,
          isHintEnabled: boolean,
          gameSessionId: string,
        } | null,
        teamMembers?:  {
          __typename: "ModelTeamMemberConnection",
          items:  Array< {
            __typename: "TeamMember",
            id: string,
            isFacilitator?: boolean | null,
            answers?:  {
              __typename: "ModelTeamAnswerConnection",
              items:  Array< {
                __typename: "TeamAnswer",
                id: string,
                isCorrect?: boolean | null,
                isSubmitted: boolean,
                isShortAnswerEnabled: boolean,
                currentState: GameSessionState,
                currentQuestionIndex: number,
                questionId: string,
                teamMemberAnswersId: string,
                teamAnswersId?: string | null,
                teamName?: string | null,
                text: string,
                answer: string,
                confidenceLevel?: ConfidenceLevel | null,
                hint?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            deviceId: string,
            createdAt: string,
            updatedAt: string,
            teamTeamMembersId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        score: number,
        selectedAvatarIndex: number,
        createdAt: string,
        updatedAt: string,
        gameSessionTeamsId?: string | null,
        teamQuestionId?: string | null,
        teamQuestionOrder?: number | null,
        teamQuestionGameSessionId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    currentQuestionIndex?: number | null,
    currentState: GameSessionState,
    gameCode: number,
    isAdvancedMode: boolean,
    imageUrl?: string | null,
    description?: string | null,
    title?: string | null,
    currentTimer?: number | null,
    questions?:  {
      __typename: "ModelQuestionConnection",
      items:  Array< {
        __typename: "Question",
        id: string,
        text: string,
        choices?: string | null,
        answerSettings?: string | null,
        responses?: string | null,
        hints?: string | null,
        imageUrl?: string | null,
        instructions?: string | null,
        standard?: string | null,
        cluster?: string | null,
        domain?: string | null,
        grade?: string | null,
        order: number,
        isConfidenceEnabled: boolean,
        isShortAnswerEnabled: boolean,
        isHintEnabled: boolean,
        gameSessionId: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateQuestionMutationVariables = {
  input: CreateQuestionInput,
  condition?: ModelQuestionConditionInput | null,
};

export type CreateQuestionMutation = {
  createQuestion?:  {
    __typename: "Question",
    id: string,
    text: string,
    choices?: string | null,
    answerSettings?: string | null,
    responses?: string | null,
    hints?: string | null,
    imageUrl?: string | null,
    instructions?: string | null,
    standard?: string | null,
    cluster?: string | null,
    domain?: string | null,
    grade?: string | null,
    order: number,
    isConfidenceEnabled: boolean,
    isShortAnswerEnabled: boolean,
    isHintEnabled: boolean,
    gameSessionId: string,
  } | null,
};

export type UpdateQuestionMutationVariables = {
  input: UpdateQuestionInput,
  condition?: ModelQuestionConditionInput | null,
};

export type UpdateQuestionMutation = {
  updateQuestion?:  {
    __typename: "Question",
    id: string,
    text: string,
    choices?: string | null,
    answerSettings?: string | null,
    responses?: string | null,
    hints?: string | null,
    imageUrl?: string | null,
    instructions?: string | null,
    standard?: string | null,
    cluster?: string | null,
    domain?: string | null,
    grade?: string | null,
    order: number,
    isConfidenceEnabled: boolean,
    isShortAnswerEnabled: boolean,
    isHintEnabled: boolean,
    gameSessionId: string,
  } | null,
};

export type DeleteQuestionMutationVariables = {
  input: DeleteQuestionInput,
  condition?: ModelQuestionConditionInput | null,
};

export type DeleteQuestionMutation = {
  deleteQuestion?:  {
    __typename: "Question",
    id: string,
    text: string,
    choices?: string | null,
    answerSettings?: string | null,
    responses?: string | null,
    hints?: string | null,
    imageUrl?: string | null,
    instructions?: string | null,
    standard?: string | null,
    cluster?: string | null,
    domain?: string | null,
    grade?: string | null,
    order: number,
    isConfidenceEnabled: boolean,
    isShortAnswerEnabled: boolean,
    isHintEnabled: boolean,
    gameSessionId: string,
  } | null,
};

export type CreateTeamMutationVariables = {
  input: CreateTeamInput,
  condition?: ModelTeamConditionInput | null,
};

export type CreateTeamMutation = {
  createTeam?:  {
    __typename: "Team",
    id: string,
    name: string,
    question?:  {
      __typename: "Question",
      id: string,
      text: string,
      choices?: string | null,
      answerSettings?: string | null,
      responses?: string | null,
      hints?: string | null,
      imageUrl?: string | null,
      instructions?: string | null,
      standard?: string | null,
      cluster?: string | null,
      domain?: string | null,
      grade?: string | null,
      order: number,
      isConfidenceEnabled: boolean,
      isShortAnswerEnabled: boolean,
      isHintEnabled: boolean,
      gameSessionId: string,
    } | null,
    teamMembers?:  {
      __typename: "ModelTeamMemberConnection",
      items:  Array< {
        __typename: "TeamMember",
        id: string,
        isFacilitator?: boolean | null,
        answers?:  {
          __typename: "ModelTeamAnswerConnection",
          items:  Array< {
            __typename: "TeamAnswer",
            id: string,
            isCorrect?: boolean | null,
            isSubmitted: boolean,
            isShortAnswerEnabled: boolean,
            currentState: GameSessionState,
            currentQuestionIndex: number,
            questionId: string,
            teamMemberAnswersId: string,
            teamAnswersId?: string | null,
            teamName?: string | null,
            text: string,
            answer: string,
            confidenceLevel?: ConfidenceLevel | null,
            hint?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        deviceId: string,
        createdAt: string,
        updatedAt: string,
        teamTeamMembersId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    score: number,
    selectedAvatarIndex: number,
    createdAt: string,
    updatedAt: string,
    gameSessionTeamsId?: string | null,
    teamQuestionId?: string | null,
    teamQuestionOrder?: number | null,
    teamQuestionGameSessionId?: string | null,
  } | null,
};

export type UpdateTeamMutationVariables = {
  input: UpdateTeamInput,
  condition?: ModelTeamConditionInput | null,
};

export type UpdateTeamMutation = {
  updateTeam?:  {
    __typename: "Team",
    id: string,
    name: string,
    question?:  {
      __typename: "Question",
      id: string,
      text: string,
      choices?: string | null,
      answerSettings?: string | null,
      responses?: string | null,
      hints?: string | null,
      imageUrl?: string | null,
      instructions?: string | null,
      standard?: string | null,
      cluster?: string | null,
      domain?: string | null,
      grade?: string | null,
      order: number,
      isConfidenceEnabled: boolean,
      isShortAnswerEnabled: boolean,
      isHintEnabled: boolean,
      gameSessionId: string,
    } | null,
    teamMembers?:  {
      __typename: "ModelTeamMemberConnection",
      items:  Array< {
        __typename: "TeamMember",
        id: string,
        isFacilitator?: boolean | null,
        answers?:  {
          __typename: "ModelTeamAnswerConnection",
          items:  Array< {
            __typename: "TeamAnswer",
            id: string,
            isCorrect?: boolean | null,
            isSubmitted: boolean,
            isShortAnswerEnabled: boolean,
            currentState: GameSessionState,
            currentQuestionIndex: number,
            questionId: string,
            teamMemberAnswersId: string,
            teamAnswersId?: string | null,
            teamName?: string | null,
            text: string,
            answer: string,
            confidenceLevel?: ConfidenceLevel | null,
            hint?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        deviceId: string,
        createdAt: string,
        updatedAt: string,
        teamTeamMembersId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    score: number,
    selectedAvatarIndex: number,
    createdAt: string,
    updatedAt: string,
    gameSessionTeamsId?: string | null,
    teamQuestionId?: string | null,
    teamQuestionOrder?: number | null,
    teamQuestionGameSessionId?: string | null,
  } | null,
};

export type DeleteTeamMutationVariables = {
  input: DeleteTeamInput,
  condition?: ModelTeamConditionInput | null,
};

export type DeleteTeamMutation = {
  deleteTeam?:  {
    __typename: "Team",
    id: string,
    name: string,
    question?:  {
      __typename: "Question",
      id: string,
      text: string,
      choices?: string | null,
      answerSettings?: string | null,
      responses?: string | null,
      hints?: string | null,
      imageUrl?: string | null,
      instructions?: string | null,
      standard?: string | null,
      cluster?: string | null,
      domain?: string | null,
      grade?: string | null,
      order: number,
      isConfidenceEnabled: boolean,
      isShortAnswerEnabled: boolean,
      isHintEnabled: boolean,
      gameSessionId: string,
    } | null,
    teamMembers?:  {
      __typename: "ModelTeamMemberConnection",
      items:  Array< {
        __typename: "TeamMember",
        id: string,
        isFacilitator?: boolean | null,
        answers?:  {
          __typename: "ModelTeamAnswerConnection",
          items:  Array< {
            __typename: "TeamAnswer",
            id: string,
            isCorrect?: boolean | null,
            isSubmitted: boolean,
            isShortAnswerEnabled: boolean,
            currentState: GameSessionState,
            currentQuestionIndex: number,
            questionId: string,
            teamMemberAnswersId: string,
            teamAnswersId?: string | null,
            teamName?: string | null,
            text: string,
            answer: string,
            confidenceLevel?: ConfidenceLevel | null,
            hint?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        deviceId: string,
        createdAt: string,
        updatedAt: string,
        teamTeamMembersId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    score: number,
    selectedAvatarIndex: number,
    createdAt: string,
    updatedAt: string,
    gameSessionTeamsId?: string | null,
    teamQuestionId?: string | null,
    teamQuestionOrder?: number | null,
    teamQuestionGameSessionId?: string | null,
  } | null,
};

export type CreateTeamMemberMutationVariables = {
  input: CreateTeamMemberInput,
  condition?: ModelTeamMemberConditionInput | null,
};

export type CreateTeamMemberMutation = {
  createTeamMember?:  {
    __typename: "TeamMember",
    id: string,
    isFacilitator?: boolean | null,
    answers?:  {
      __typename: "ModelTeamAnswerConnection",
      items:  Array< {
        __typename: "TeamAnswer",
        id: string,
        isCorrect?: boolean | null,
        isSubmitted: boolean,
        isShortAnswerEnabled: boolean,
        currentState: GameSessionState,
        currentQuestionIndex: number,
        questionId: string,
        teamMemberAnswersId: string,
        teamAnswersId?: string | null,
        teamName?: string | null,
        text: string,
        answer: string,
        confidenceLevel?: ConfidenceLevel | null,
        hint?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    deviceId: string,
    createdAt: string,
    updatedAt: string,
    teamTeamMembersId?: string | null,
  } | null,
};

export type UpdateTeamMemberMutationVariables = {
  input: UpdateTeamMemberInput,
  condition?: ModelTeamMemberConditionInput | null,
};

export type UpdateTeamMemberMutation = {
  updateTeamMember?:  {
    __typename: "TeamMember",
    id: string,
    isFacilitator?: boolean | null,
    answers?:  {
      __typename: "ModelTeamAnswerConnection",
      items:  Array< {
        __typename: "TeamAnswer",
        id: string,
        isCorrect?: boolean | null,
        isSubmitted: boolean,
        isShortAnswerEnabled: boolean,
        currentState: GameSessionState,
        currentQuestionIndex: number,
        questionId: string,
        teamMemberAnswersId: string,
        teamAnswersId?: string | null,
        teamName?: string | null,
        text: string,
        answer: string,
        confidenceLevel?: ConfidenceLevel | null,
        hint?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    deviceId: string,
    createdAt: string,
    updatedAt: string,
    teamTeamMembersId?: string | null,
  } | null,
};

export type DeleteTeamMemberMutationVariables = {
  input: DeleteTeamMemberInput,
  condition?: ModelTeamMemberConditionInput | null,
};

export type DeleteTeamMemberMutation = {
  deleteTeamMember?:  {
    __typename: "TeamMember",
    id: string,
    isFacilitator?: boolean | null,
    answers?:  {
      __typename: "ModelTeamAnswerConnection",
      items:  Array< {
        __typename: "TeamAnswer",
        id: string,
        isCorrect?: boolean | null,
        isSubmitted: boolean,
        isShortAnswerEnabled: boolean,
        currentState: GameSessionState,
        currentQuestionIndex: number,
        questionId: string,
        teamMemberAnswersId: string,
        teamAnswersId?: string | null,
        teamName?: string | null,
        text: string,
        answer: string,
        confidenceLevel?: ConfidenceLevel | null,
        hint?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    deviceId: string,
    createdAt: string,
    updatedAt: string,
    teamTeamMembersId?: string | null,
  } | null,
};

export type CreateTeamAnswerMutationVariables = {
  input: CreateTeamAnswerInput,
  condition?: ModelTeamAnswerConditionInput | null,
};

export type CreateTeamAnswerMutation = {
  createTeamAnswer?:  {
    __typename: "TeamAnswer",
    id: string,
    isCorrect?: boolean | null,
    isSubmitted: boolean,
    isShortAnswerEnabled: boolean,
    currentState: GameSessionState,
    currentQuestionIndex: number,
    questionId: string,
    teamMemberAnswersId: string,
    teamAnswersId?: string | null,
    teamName?: string | null,
    text: string,
    answer: string,
    confidenceLevel?: ConfidenceLevel | null,
    hint?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateTeamAnswerMutationVariables = {
  input: UpdateTeamAnswerInput,
  condition?: ModelTeamAnswerConditionInput | null,
};

export type UpdateTeamAnswerMutation = {
  updateTeamAnswer?:  {
    __typename: "TeamAnswer",
    id: string,
    isCorrect?: boolean | null,
    isSubmitted: boolean,
    isShortAnswerEnabled: boolean,
    currentState: GameSessionState,
    currentQuestionIndex: number,
    questionId: string,
    teamMemberAnswersId: string,
    teamAnswersId?: string | null,
    teamName?: string | null,
    text: string,
    answer: string,
    confidenceLevel?: ConfidenceLevel | null,
    hint?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteTeamAnswerMutationVariables = {
  input: DeleteTeamAnswerInput,
  condition?: ModelTeamAnswerConditionInput | null,
};

export type DeleteTeamAnswerMutation = {
  deleteTeamAnswer?:  {
    __typename: "TeamAnswer",
    id: string,
    isCorrect?: boolean | null,
    isSubmitted: boolean,
    isShortAnswerEnabled: boolean,
    currentState: GameSessionState,
    currentQuestionIndex: number,
    questionId: string,
    teamMemberAnswersId: string,
    teamAnswersId?: string | null,
    teamName?: string | null,
    text: string,
    answer: string,
    confidenceLevel?: ConfidenceLevel | null,
    hint?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateGameQuestionsMutationVariables = {
  input: CreateGameQuestionsInput,
  condition?: ModelGameQuestionsConditionInput | null,
};

export type CreateGameQuestionsMutation = {
  createGameQuestions?:  {
    __typename: "GameQuestions",
    id: string,
    gameTemplateID: string,
    questionTemplateID: string,
    gameTemplate:  {
      __typename: "GameTemplate",
      id: string,
      title: string,
      owner: string,
      version: number,
      description: string,
      domain?: string | null,
      cluster?: string | null,
      grade?: string | null,
      standard?: string | null,
      phaseOneTime?: number | null,
      phaseTwoTime?: number | null,
      imageUrl?: string | null,
      questionTemplates?:  {
        __typename: "ModelGameQuestionsConnection",
        items:  Array< {
          __typename: "GameQuestions",
          id: string,
          gameTemplateID: string,
          questionTemplateID: string,
          gameTemplate:  {
            __typename: "GameTemplate",
            id: string,
            title: string,
            owner: string,
            version: number,
            description: string,
            domain?: string | null,
            cluster?: string | null,
            grade?: string | null,
            standard?: string | null,
            phaseOneTime?: number | null,
            phaseTwoTime?: number | null,
            imageUrl?: string | null,
            questionTemplates?:  {
              __typename: "ModelGameQuestionsConnection",
              items:  Array< {
                __typename: "GameQuestions",
                id: string,
                gameTemplateID: string,
                questionTemplateID: string,
                gameTemplate:  {
                  __typename: "GameTemplate",
                  id: string,
                  title: string,
                  owner: string,
                  version: number,
                  description: string,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  standard?: string | null,
                  phaseOneTime?: number | null,
                  phaseTwoTime?: number | null,
                  imageUrl?: string | null,
                  questionTemplatesCount: number,
                  questionTemplatesOrder?: string | null,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                questionTemplate:  {
                  __typename: "QuestionTemplate",
                  id: string,
                  title: string,
                  owner: string,
                  version: number,
                  choices?: string | null,
                  instructions?: string | null,
                  answerSettings?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  standard?: string | null,
                  imageUrl?: string | null,
                  gameTemplatesCount: number,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            questionTemplatesCount: number,
            questionTemplatesOrder?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            type?: string | null,
          },
          questionTemplate:  {
            __typename: "QuestionTemplate",
            id: string,
            title: string,
            owner: string,
            version: number,
            choices?: string | null,
            instructions?: string | null,
            answerSettings?: string | null,
            domain?: string | null,
            cluster?: string | null,
            grade?: string | null,
            standard?: string | null,
            imageUrl?: string | null,
            gameTemplates?:  {
              __typename: "ModelGameQuestionsConnection",
              items:  Array< {
                __typename: "GameQuestions",
                id: string,
                gameTemplateID: string,
                questionTemplateID: string,
                gameTemplate:  {
                  __typename: "GameTemplate",
                  id: string,
                  title: string,
                  owner: string,
                  version: number,
                  description: string,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  standard?: string | null,
                  phaseOneTime?: number | null,
                  phaseTwoTime?: number | null,
                  imageUrl?: string | null,
                  questionTemplatesCount: number,
                  questionTemplatesOrder?: string | null,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                questionTemplate:  {
                  __typename: "QuestionTemplate",
                  id: string,
                  title: string,
                  owner: string,
                  version: number,
                  choices?: string | null,
                  instructions?: string | null,
                  answerSettings?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  standard?: string | null,
                  imageUrl?: string | null,
                  gameTemplatesCount: number,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            gameTemplatesCount: number,
            createdAt?: string | null,
            updatedAt?: string | null,
            type?: string | null,
          },
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      questionTemplatesCount: number,
      questionTemplatesOrder?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      type?: string | null,
    },
    questionTemplate:  {
      __typename: "QuestionTemplate",
      id: string,
      title: string,
      owner: string,
      version: number,
      choices?: string | null,
      instructions?: string | null,
      answerSettings?: string | null,
      domain?: string | null,
      cluster?: string | null,
      grade?: string | null,
      standard?: string | null,
      imageUrl?: string | null,
      gameTemplates?:  {
        __typename: "ModelGameQuestionsConnection",
        items:  Array< {
          __typename: "GameQuestions",
          id: string,
          gameTemplateID: string,
          questionTemplateID: string,
          gameTemplate:  {
            __typename: "GameTemplate",
            id: string,
            title: string,
            owner: string,
            version: number,
            description: string,
            domain?: string | null,
            cluster?: string | null,
            grade?: string | null,
            standard?: string | null,
            phaseOneTime?: number | null,
            phaseTwoTime?: number | null,
            imageUrl?: string | null,
            questionTemplates?:  {
              __typename: "ModelGameQuestionsConnection",
              items:  Array< {
                __typename: "GameQuestions",
                id: string,
                gameTemplateID: string,
                questionTemplateID: string,
                gameTemplate:  {
                  __typename: "GameTemplate",
                  id: string,
                  title: string,
                  owner: string,
                  version: number,
                  description: string,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  standard?: string | null,
                  phaseOneTime?: number | null,
                  phaseTwoTime?: number | null,
                  imageUrl?: string | null,
                  questionTemplatesCount: number,
                  questionTemplatesOrder?: string | null,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                questionTemplate:  {
                  __typename: "QuestionTemplate",
                  id: string,
                  title: string,
                  owner: string,
                  version: number,
                  choices?: string | null,
                  instructions?: string | null,
                  answerSettings?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  standard?: string | null,
                  imageUrl?: string | null,
                  gameTemplatesCount: number,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            questionTemplatesCount: number,
            questionTemplatesOrder?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            type?: string | null,
          },
          questionTemplate:  {
            __typename: "QuestionTemplate",
            id: string,
            title: string,
            owner: string,
            version: number,
            choices?: string | null,
            instructions?: string | null,
            answerSettings?: string | null,
            domain?: string | null,
            cluster?: string | null,
            grade?: string | null,
            standard?: string | null,
            imageUrl?: string | null,
            gameTemplates?:  {
              __typename: "ModelGameQuestionsConnection",
              items:  Array< {
                __typename: "GameQuestions",
                id: string,
                gameTemplateID: string,
                questionTemplateID: string,
                gameTemplate:  {
                  __typename: "GameTemplate",
                  id: string,
                  title: string,
                  owner: string,
                  version: number,
                  description: string,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  standard?: string | null,
                  phaseOneTime?: number | null,
                  phaseTwoTime?: number | null,
                  imageUrl?: string | null,
                  questionTemplatesCount: number,
                  questionTemplatesOrder?: string | null,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                questionTemplate:  {
                  __typename: "QuestionTemplate",
                  id: string,
                  title: string,
                  owner: string,
                  version: number,
                  choices?: string | null,
                  instructions?: string | null,
                  answerSettings?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  standard?: string | null,
                  imageUrl?: string | null,
                  gameTemplatesCount: number,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            gameTemplatesCount: number,
            createdAt?: string | null,
            updatedAt?: string | null,
            type?: string | null,
          },
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      gameTemplatesCount: number,
      createdAt?: string | null,
      updatedAt?: string | null,
      type?: string | null,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateGameQuestionsMutationVariables = {
  input: UpdateGameQuestionsInput,
  condition?: ModelGameQuestionsConditionInput | null,
};

export type UpdateGameQuestionsMutation = {
  updateGameQuestions?:  {
    __typename: "GameQuestions",
    id: string,
    gameTemplateID: string,
    questionTemplateID: string,
    gameTemplate:  {
      __typename: "GameTemplate",
      id: string,
      title: string,
      owner: string,
      version: number,
      description: string,
      domain?: string | null,
      cluster?: string | null,
      grade?: string | null,
      standard?: string | null,
      phaseOneTime?: number | null,
      phaseTwoTime?: number | null,
      imageUrl?: string | null,
      questionTemplates?:  {
        __typename: "ModelGameQuestionsConnection",
        items:  Array< {
          __typename: "GameQuestions",
          id: string,
          gameTemplateID: string,
          questionTemplateID: string,
          gameTemplate:  {
            __typename: "GameTemplate",
            id: string,
            title: string,
            owner: string,
            version: number,
            description: string,
            domain?: string | null,
            cluster?: string | null,
            grade?: string | null,
            standard?: string | null,
            phaseOneTime?: number | null,
            phaseTwoTime?: number | null,
            imageUrl?: string | null,
            questionTemplates?:  {
              __typename: "ModelGameQuestionsConnection",
              items:  Array< {
                __typename: "GameQuestions",
                id: string,
                gameTemplateID: string,
                questionTemplateID: string,
                gameTemplate:  {
                  __typename: "GameTemplate",
                  id: string,
                  title: string,
                  owner: string,
                  version: number,
                  description: string,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  standard?: string | null,
                  phaseOneTime?: number | null,
                  phaseTwoTime?: number | null,
                  imageUrl?: string | null,
                  questionTemplatesCount: number,
                  questionTemplatesOrder?: string | null,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                questionTemplate:  {
                  __typename: "QuestionTemplate",
                  id: string,
                  title: string,
                  owner: string,
                  version: number,
                  choices?: string | null,
                  instructions?: string | null,
                  answerSettings?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  standard?: string | null,
                  imageUrl?: string | null,
                  gameTemplatesCount: number,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            questionTemplatesCount: number,
            questionTemplatesOrder?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            type?: string | null,
          },
          questionTemplate:  {
            __typename: "QuestionTemplate",
            id: string,
            title: string,
            owner: string,
            version: number,
            choices?: string | null,
            instructions?: string | null,
            answerSettings?: string | null,
            domain?: string | null,
            cluster?: string | null,
            grade?: string | null,
            standard?: string | null,
            imageUrl?: string | null,
            gameTemplates?:  {
              __typename: "ModelGameQuestionsConnection",
              items:  Array< {
                __typename: "GameQuestions",
                id: string,
                gameTemplateID: string,
                questionTemplateID: string,
                gameTemplate:  {
                  __typename: "GameTemplate",
                  id: string,
                  title: string,
                  owner: string,
                  version: number,
                  description: string,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  standard?: string | null,
                  phaseOneTime?: number | null,
                  phaseTwoTime?: number | null,
                  imageUrl?: string | null,
                  questionTemplatesCount: number,
                  questionTemplatesOrder?: string | null,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                questionTemplate:  {
                  __typename: "QuestionTemplate",
                  id: string,
                  title: string,
                  owner: string,
                  version: number,
                  choices?: string | null,
                  instructions?: string | null,
                  answerSettings?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  standard?: string | null,
                  imageUrl?: string | null,
                  gameTemplatesCount: number,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            gameTemplatesCount: number,
            createdAt?: string | null,
            updatedAt?: string | null,
            type?: string | null,
          },
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      questionTemplatesCount: number,
      questionTemplatesOrder?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      type?: string | null,
    },
    questionTemplate:  {
      __typename: "QuestionTemplate",
      id: string,
      title: string,
      owner: string,
      version: number,
      choices?: string | null,
      instructions?: string | null,
      answerSettings?: string | null,
      domain?: string | null,
      cluster?: string | null,
      grade?: string | null,
      standard?: string | null,
      imageUrl?: string | null,
      gameTemplates?:  {
        __typename: "ModelGameQuestionsConnection",
        items:  Array< {
          __typename: "GameQuestions",
          id: string,
          gameTemplateID: string,
          questionTemplateID: string,
          gameTemplate:  {
            __typename: "GameTemplate",
            id: string,
            title: string,
            owner: string,
            version: number,
            description: string,
            domain?: string | null,
            cluster?: string | null,
            grade?: string | null,
            standard?: string | null,
            phaseOneTime?: number | null,
            phaseTwoTime?: number | null,
            imageUrl?: string | null,
            questionTemplates?:  {
              __typename: "ModelGameQuestionsConnection",
              items:  Array< {
                __typename: "GameQuestions",
                id: string,
                gameTemplateID: string,
                questionTemplateID: string,
                gameTemplate:  {
                  __typename: "GameTemplate",
                  id: string,
                  title: string,
                  owner: string,
                  version: number,
                  description: string,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  standard?: string | null,
                  phaseOneTime?: number | null,
                  phaseTwoTime?: number | null,
                  imageUrl?: string | null,
                  questionTemplatesCount: number,
                  questionTemplatesOrder?: string | null,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                questionTemplate:  {
                  __typename: "QuestionTemplate",
                  id: string,
                  title: string,
                  owner: string,
                  version: number,
                  choices?: string | null,
                  instructions?: string | null,
                  answerSettings?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  standard?: string | null,
                  imageUrl?: string | null,
                  gameTemplatesCount: number,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            questionTemplatesCount: number,
            questionTemplatesOrder?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            type?: string | null,
          },
          questionTemplate:  {
            __typename: "QuestionTemplate",
            id: string,
            title: string,
            owner: string,
            version: number,
            choices?: string | null,
            instructions?: string | null,
            answerSettings?: string | null,
            domain?: string | null,
            cluster?: string | null,
            grade?: string | null,
            standard?: string | null,
            imageUrl?: string | null,
            gameTemplates?:  {
              __typename: "ModelGameQuestionsConnection",
              items:  Array< {
                __typename: "GameQuestions",
                id: string,
                gameTemplateID: string,
                questionTemplateID: string,
                gameTemplate:  {
                  __typename: "GameTemplate",
                  id: string,
                  title: string,
                  owner: string,
                  version: number,
                  description: string,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  standard?: string | null,
                  phaseOneTime?: number | null,
                  phaseTwoTime?: number | null,
                  imageUrl?: string | null,
                  questionTemplatesCount: number,
                  questionTemplatesOrder?: string | null,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                questionTemplate:  {
                  __typename: "QuestionTemplate",
                  id: string,
                  title: string,
                  owner: string,
                  version: number,
                  choices?: string | null,
                  instructions?: string | null,
                  answerSettings?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  standard?: string | null,
                  imageUrl?: string | null,
                  gameTemplatesCount: number,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            gameTemplatesCount: number,
            createdAt?: string | null,
            updatedAt?: string | null,
            type?: string | null,
          },
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      gameTemplatesCount: number,
      createdAt?: string | null,
      updatedAt?: string | null,
      type?: string | null,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteGameQuestionsMutationVariables = {
  input: DeleteGameQuestionsInput,
  condition?: ModelGameQuestionsConditionInput | null,
};

export type DeleteGameQuestionsMutation = {
  deleteGameQuestions?:  {
    __typename: "GameQuestions",
    id: string,
    gameTemplateID: string,
    questionTemplateID: string,
    gameTemplate:  {
      __typename: "GameTemplate",
      id: string,
      title: string,
      owner: string,
      version: number,
      description: string,
      domain?: string | null,
      cluster?: string | null,
      grade?: string | null,
      standard?: string | null,
      phaseOneTime?: number | null,
      phaseTwoTime?: number | null,
      imageUrl?: string | null,
      questionTemplates?:  {
        __typename: "ModelGameQuestionsConnection",
        items:  Array< {
          __typename: "GameQuestions",
          id: string,
          gameTemplateID: string,
          questionTemplateID: string,
          gameTemplate:  {
            __typename: "GameTemplate",
            id: string,
            title: string,
            owner: string,
            version: number,
            description: string,
            domain?: string | null,
            cluster?: string | null,
            grade?: string | null,
            standard?: string | null,
            phaseOneTime?: number | null,
            phaseTwoTime?: number | null,
            imageUrl?: string | null,
            questionTemplates?:  {
              __typename: "ModelGameQuestionsConnection",
              items:  Array< {
                __typename: "GameQuestions",
                id: string,
                gameTemplateID: string,
                questionTemplateID: string,
                gameTemplate:  {
                  __typename: "GameTemplate",
                  id: string,
                  title: string,
                  owner: string,
                  version: number,
                  description: string,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  standard?: string | null,
                  phaseOneTime?: number | null,
                  phaseTwoTime?: number | null,
                  imageUrl?: string | null,
                  questionTemplatesCount: number,
                  questionTemplatesOrder?: string | null,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                questionTemplate:  {
                  __typename: "QuestionTemplate",
                  id: string,
                  title: string,
                  owner: string,
                  version: number,
                  choices?: string | null,
                  instructions?: string | null,
                  answerSettings?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  standard?: string | null,
                  imageUrl?: string | null,
                  gameTemplatesCount: number,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            questionTemplatesCount: number,
            questionTemplatesOrder?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            type?: string | null,
          },
          questionTemplate:  {
            __typename: "QuestionTemplate",
            id: string,
            title: string,
            owner: string,
            version: number,
            choices?: string | null,
            instructions?: string | null,
            answerSettings?: string | null,
            domain?: string | null,
            cluster?: string | null,
            grade?: string | null,
            standard?: string | null,
            imageUrl?: string | null,
            gameTemplates?:  {
              __typename: "ModelGameQuestionsConnection",
              items:  Array< {
                __typename: "GameQuestions",
                id: string,
                gameTemplateID: string,
                questionTemplateID: string,
                gameTemplate:  {
                  __typename: "GameTemplate",
                  id: string,
                  title: string,
                  owner: string,
                  version: number,
                  description: string,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  standard?: string | null,
                  phaseOneTime?: number | null,
                  phaseTwoTime?: number | null,
                  imageUrl?: string | null,
                  questionTemplatesCount: number,
                  questionTemplatesOrder?: string | null,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                questionTemplate:  {
                  __typename: "QuestionTemplate",
                  id: string,
                  title: string,
                  owner: string,
                  version: number,
                  choices?: string | null,
                  instructions?: string | null,
                  answerSettings?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  standard?: string | null,
                  imageUrl?: string | null,
                  gameTemplatesCount: number,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            gameTemplatesCount: number,
            createdAt?: string | null,
            updatedAt?: string | null,
            type?: string | null,
          },
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      questionTemplatesCount: number,
      questionTemplatesOrder?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      type?: string | null,
    },
    questionTemplate:  {
      __typename: "QuestionTemplate",
      id: string,
      title: string,
      owner: string,
      version: number,
      choices?: string | null,
      instructions?: string | null,
      answerSettings?: string | null,
      domain?: string | null,
      cluster?: string | null,
      grade?: string | null,
      standard?: string | null,
      imageUrl?: string | null,
      gameTemplates?:  {
        __typename: "ModelGameQuestionsConnection",
        items:  Array< {
          __typename: "GameQuestions",
          id: string,
          gameTemplateID: string,
          questionTemplateID: string,
          gameTemplate:  {
            __typename: "GameTemplate",
            id: string,
            title: string,
            owner: string,
            version: number,
            description: string,
            domain?: string | null,
            cluster?: string | null,
            grade?: string | null,
            standard?: string | null,
            phaseOneTime?: number | null,
            phaseTwoTime?: number | null,
            imageUrl?: string | null,
            questionTemplates?:  {
              __typename: "ModelGameQuestionsConnection",
              items:  Array< {
                __typename: "GameQuestions",
                id: string,
                gameTemplateID: string,
                questionTemplateID: string,
                gameTemplate:  {
                  __typename: "GameTemplate",
                  id: string,
                  title: string,
                  owner: string,
                  version: number,
                  description: string,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  standard?: string | null,
                  phaseOneTime?: number | null,
                  phaseTwoTime?: number | null,
                  imageUrl?: string | null,
                  questionTemplatesCount: number,
                  questionTemplatesOrder?: string | null,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                questionTemplate:  {
                  __typename: "QuestionTemplate",
                  id: string,
                  title: string,
                  owner: string,
                  version: number,
                  choices?: string | null,
                  instructions?: string | null,
                  answerSettings?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  standard?: string | null,
                  imageUrl?: string | null,
                  gameTemplatesCount: number,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            questionTemplatesCount: number,
            questionTemplatesOrder?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            type?: string | null,
          },
          questionTemplate:  {
            __typename: "QuestionTemplate",
            id: string,
            title: string,
            owner: string,
            version: number,
            choices?: string | null,
            instructions?: string | null,
            answerSettings?: string | null,
            domain?: string | null,
            cluster?: string | null,
            grade?: string | null,
            standard?: string | null,
            imageUrl?: string | null,
            gameTemplates?:  {
              __typename: "ModelGameQuestionsConnection",
              items:  Array< {
                __typename: "GameQuestions",
                id: string,
                gameTemplateID: string,
                questionTemplateID: string,
                gameTemplate:  {
                  __typename: "GameTemplate",
                  id: string,
                  title: string,
                  owner: string,
                  version: number,
                  description: string,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  standard?: string | null,
                  phaseOneTime?: number | null,
                  phaseTwoTime?: number | null,
                  imageUrl?: string | null,
                  questionTemplatesCount: number,
                  questionTemplatesOrder?: string | null,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                questionTemplate:  {
                  __typename: "QuestionTemplate",
                  id: string,
                  title: string,
                  owner: string,
                  version: number,
                  choices?: string | null,
                  instructions?: string | null,
                  answerSettings?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  standard?: string | null,
                  imageUrl?: string | null,
                  gameTemplatesCount: number,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            gameTemplatesCount: number,
            createdAt?: string | null,
            updatedAt?: string | null,
            type?: string | null,
          },
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      gameTemplatesCount: number,
      createdAt?: string | null,
      updatedAt?: string | null,
      type?: string | null,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type GetGameTemplateQueryVariables = {
  id: string,
};

export type GetGameTemplateQuery = {
  getGameTemplate?:  {
    __typename: "GameTemplate",
    id: string,
    title: string,
    owner: string,
    version: number,
    description: string,
    domain?: string | null,
    cluster?: string | null,
    grade?: string | null,
    standard?: string | null,
    phaseOneTime?: number | null,
    phaseTwoTime?: number | null,
    imageUrl?: string | null,
    questionTemplates?:  {
      __typename: "ModelGameQuestionsConnection",
      items:  Array< {
        __typename: "GameQuestions",
        id: string,
        gameTemplateID: string,
        questionTemplateID: string,
        gameTemplate:  {
          __typename: "GameTemplate",
          id: string,
          title: string,
          owner: string,
          version: number,
          description: string,
          domain?: string | null,
          cluster?: string | null,
          grade?: string | null,
          standard?: string | null,
          phaseOneTime?: number | null,
          phaseTwoTime?: number | null,
          imageUrl?: string | null,
          questionTemplates?:  {
            __typename: "ModelGameQuestionsConnection",
            items:  Array< {
              __typename: "GameQuestions",
              id: string,
              gameTemplateID: string,
              questionTemplateID: string,
              gameTemplate:  {
                __typename: "GameTemplate",
                id: string,
                title: string,
                owner: string,
                version: number,
                description: string,
                domain?: string | null,
                cluster?: string | null,
                grade?: string | null,
                standard?: string | null,
                phaseOneTime?: number | null,
                phaseTwoTime?: number | null,
                imageUrl?: string | null,
                questionTemplates?:  {
                  __typename: "ModelGameQuestionsConnection",
                  nextToken?: string | null,
                } | null,
                questionTemplatesCount: number,
                questionTemplatesOrder?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
                type?: string | null,
              },
              questionTemplate:  {
                __typename: "QuestionTemplate",
                id: string,
                title: string,
                owner: string,
                version: number,
                choices?: string | null,
                instructions?: string | null,
                answerSettings?: string | null,
                domain?: string | null,
                cluster?: string | null,
                grade?: string | null,
                standard?: string | null,
                imageUrl?: string | null,
                gameTemplates?:  {
                  __typename: "ModelGameQuestionsConnection",
                  nextToken?: string | null,
                } | null,
                gameTemplatesCount: number,
                createdAt?: string | null,
                updatedAt?: string | null,
                type?: string | null,
              },
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          questionTemplatesCount: number,
          questionTemplatesOrder?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          type?: string | null,
        },
        questionTemplate:  {
          __typename: "QuestionTemplate",
          id: string,
          title: string,
          owner: string,
          version: number,
          choices?: string | null,
          instructions?: string | null,
          answerSettings?: string | null,
          domain?: string | null,
          cluster?: string | null,
          grade?: string | null,
          standard?: string | null,
          imageUrl?: string | null,
          gameTemplates?:  {
            __typename: "ModelGameQuestionsConnection",
            items:  Array< {
              __typename: "GameQuestions",
              id: string,
              gameTemplateID: string,
              questionTemplateID: string,
              gameTemplate:  {
                __typename: "GameTemplate",
                id: string,
                title: string,
                owner: string,
                version: number,
                description: string,
                domain?: string | null,
                cluster?: string | null,
                grade?: string | null,
                standard?: string | null,
                phaseOneTime?: number | null,
                phaseTwoTime?: number | null,
                imageUrl?: string | null,
                questionTemplates?:  {
                  __typename: "ModelGameQuestionsConnection",
                  nextToken?: string | null,
                } | null,
                questionTemplatesCount: number,
                questionTemplatesOrder?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
                type?: string | null,
              },
              questionTemplate:  {
                __typename: "QuestionTemplate",
                id: string,
                title: string,
                owner: string,
                version: number,
                choices?: string | null,
                instructions?: string | null,
                answerSettings?: string | null,
                domain?: string | null,
                cluster?: string | null,
                grade?: string | null,
                standard?: string | null,
                imageUrl?: string | null,
                gameTemplates?:  {
                  __typename: "ModelGameQuestionsConnection",
                  nextToken?: string | null,
                } | null,
                gameTemplatesCount: number,
                createdAt?: string | null,
                updatedAt?: string | null,
                type?: string | null,
              },
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          gameTemplatesCount: number,
          createdAt?: string | null,
          updatedAt?: string | null,
          type?: string | null,
        },
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    questionTemplatesCount: number,
    questionTemplatesOrder?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    type?: string | null,
  } | null,
};

export type ListGameTemplatesQueryVariables = {
  filter?: ModelGameTemplateFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListGameTemplatesQuery = {
  listGameTemplates?:  {
    __typename: "ModelGameTemplateConnection",
    items:  Array< {
      __typename: "GameTemplate",
      id: string,
      title: string,
      owner: string,
      version: number,
      description: string,
      domain?: string | null,
      cluster?: string | null,
      grade?: string | null,
      standard?: string | null,
      phaseOneTime?: number | null,
      phaseTwoTime?: number | null,
      imageUrl?: string | null,
      questionTemplates?:  {
        __typename: "ModelGameQuestionsConnection",
        items:  Array< {
          __typename: "GameQuestions",
          id: string,
          gameTemplateID: string,
          questionTemplateID: string,
          gameTemplate:  {
            __typename: "GameTemplate",
            id: string,
            title: string,
            owner: string,
            version: number,
            description: string,
            domain?: string | null,
            cluster?: string | null,
            grade?: string | null,
            standard?: string | null,
            phaseOneTime?: number | null,
            phaseTwoTime?: number | null,
            imageUrl?: string | null,
            questionTemplates?:  {
              __typename: "ModelGameQuestionsConnection",
              items:  Array< {
                __typename: "GameQuestions",
                id: string,
                gameTemplateID: string,
                questionTemplateID: string,
                gameTemplate:  {
                  __typename: "GameTemplate",
                  id: string,
                  title: string,
                  owner: string,
                  version: number,
                  description: string,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  standard?: string | null,
                  phaseOneTime?: number | null,
                  phaseTwoTime?: number | null,
                  imageUrl?: string | null,
                  questionTemplatesCount: number,
                  questionTemplatesOrder?: string | null,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                questionTemplate:  {
                  __typename: "QuestionTemplate",
                  id: string,
                  title: string,
                  owner: string,
                  version: number,
                  choices?: string | null,
                  instructions?: string | null,
                  answerSettings?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  standard?: string | null,
                  imageUrl?: string | null,
                  gameTemplatesCount: number,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            questionTemplatesCount: number,
            questionTemplatesOrder?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            type?: string | null,
          },
          questionTemplate:  {
            __typename: "QuestionTemplate",
            id: string,
            title: string,
            owner: string,
            version: number,
            choices?: string | null,
            instructions?: string | null,
            answerSettings?: string | null,
            domain?: string | null,
            cluster?: string | null,
            grade?: string | null,
            standard?: string | null,
            imageUrl?: string | null,
            gameTemplates?:  {
              __typename: "ModelGameQuestionsConnection",
              items:  Array< {
                __typename: "GameQuestions",
                id: string,
                gameTemplateID: string,
                questionTemplateID: string,
                gameTemplate:  {
                  __typename: "GameTemplate",
                  id: string,
                  title: string,
                  owner: string,
                  version: number,
                  description: string,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  standard?: string | null,
                  phaseOneTime?: number | null,
                  phaseTwoTime?: number | null,
                  imageUrl?: string | null,
                  questionTemplatesCount: number,
                  questionTemplatesOrder?: string | null,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                questionTemplate:  {
                  __typename: "QuestionTemplate",
                  id: string,
                  title: string,
                  owner: string,
                  version: number,
                  choices?: string | null,
                  instructions?: string | null,
                  answerSettings?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  standard?: string | null,
                  imageUrl?: string | null,
                  gameTemplatesCount: number,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            gameTemplatesCount: number,
            createdAt?: string | null,
            updatedAt?: string | null,
            type?: string | null,
          },
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      questionTemplatesCount: number,
      questionTemplatesOrder?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      type?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetQuestionTemplateQueryVariables = {
  id: string,
};

export type GetQuestionTemplateQuery = {
  getQuestionTemplate?:  {
    __typename: "QuestionTemplate",
    id: string,
    title: string,
    owner: string,
    version: number,
    choices?: string | null,
    instructions?: string | null,
    answerSettings?: string | null,
    domain?: string | null,
    cluster?: string | null,
    grade?: string | null,
    standard?: string | null,
    imageUrl?: string | null,
    gameTemplates?:  {
      __typename: "ModelGameQuestionsConnection",
      items:  Array< {
        __typename: "GameQuestions",
        id: string,
        gameTemplateID: string,
        questionTemplateID: string,
        gameTemplate:  {
          __typename: "GameTemplate",
          id: string,
          title: string,
          owner: string,
          version: number,
          description: string,
          domain?: string | null,
          cluster?: string | null,
          grade?: string | null,
          standard?: string | null,
          phaseOneTime?: number | null,
          phaseTwoTime?: number | null,
          imageUrl?: string | null,
          questionTemplates?:  {
            __typename: "ModelGameQuestionsConnection",
            items:  Array< {
              __typename: "GameQuestions",
              id: string,
              gameTemplateID: string,
              questionTemplateID: string,
              gameTemplate:  {
                __typename: "GameTemplate",
                id: string,
                title: string,
                owner: string,
                version: number,
                description: string,
                domain?: string | null,
                cluster?: string | null,
                grade?: string | null,
                standard?: string | null,
                phaseOneTime?: number | null,
                phaseTwoTime?: number | null,
                imageUrl?: string | null,
                questionTemplates?:  {
                  __typename: "ModelGameQuestionsConnection",
                  nextToken?: string | null,
                } | null,
                questionTemplatesCount: number,
                questionTemplatesOrder?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
                type?: string | null,
              },
              questionTemplate:  {
                __typename: "QuestionTemplate",
                id: string,
                title: string,
                owner: string,
                version: number,
                choices?: string | null,
                instructions?: string | null,
                answerSettings?: string | null,
                domain?: string | null,
                cluster?: string | null,
                grade?: string | null,
                standard?: string | null,
                imageUrl?: string | null,
                gameTemplates?:  {
                  __typename: "ModelGameQuestionsConnection",
                  nextToken?: string | null,
                } | null,
                gameTemplatesCount: number,
                createdAt?: string | null,
                updatedAt?: string | null,
                type?: string | null,
              },
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          questionTemplatesCount: number,
          questionTemplatesOrder?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          type?: string | null,
        },
        questionTemplate:  {
          __typename: "QuestionTemplate",
          id: string,
          title: string,
          owner: string,
          version: number,
          choices?: string | null,
          instructions?: string | null,
          answerSettings?: string | null,
          domain?: string | null,
          cluster?: string | null,
          grade?: string | null,
          standard?: string | null,
          imageUrl?: string | null,
          gameTemplates?:  {
            __typename: "ModelGameQuestionsConnection",
            items:  Array< {
              __typename: "GameQuestions",
              id: string,
              gameTemplateID: string,
              questionTemplateID: string,
              gameTemplate:  {
                __typename: "GameTemplate",
                id: string,
                title: string,
                owner: string,
                version: number,
                description: string,
                domain?: string | null,
                cluster?: string | null,
                grade?: string | null,
                standard?: string | null,
                phaseOneTime?: number | null,
                phaseTwoTime?: number | null,
                imageUrl?: string | null,
                questionTemplates?:  {
                  __typename: "ModelGameQuestionsConnection",
                  nextToken?: string | null,
                } | null,
                questionTemplatesCount: number,
                questionTemplatesOrder?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
                type?: string | null,
              },
              questionTemplate:  {
                __typename: "QuestionTemplate",
                id: string,
                title: string,
                owner: string,
                version: number,
                choices?: string | null,
                instructions?: string | null,
                answerSettings?: string | null,
                domain?: string | null,
                cluster?: string | null,
                grade?: string | null,
                standard?: string | null,
                imageUrl?: string | null,
                gameTemplates?:  {
                  __typename: "ModelGameQuestionsConnection",
                  nextToken?: string | null,
                } | null,
                gameTemplatesCount: number,
                createdAt?: string | null,
                updatedAt?: string | null,
                type?: string | null,
              },
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          gameTemplatesCount: number,
          createdAt?: string | null,
          updatedAt?: string | null,
          type?: string | null,
        },
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    gameTemplatesCount: number,
    createdAt?: string | null,
    updatedAt?: string | null,
    type?: string | null,
  } | null,
};

export type ListQuestionTemplatesQueryVariables = {
  filter?: ModelQuestionTemplateFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListQuestionTemplatesQuery = {
  listQuestionTemplates?:  {
    __typename: "ModelQuestionTemplateConnection",
    items:  Array< {
      __typename: "QuestionTemplate",
      id: string,
      title: string,
      owner: string,
      version: number,
      choices?: string | null,
      instructions?: string | null,
      answerSettings?: string | null,
      domain?: string | null,
      cluster?: string | null,
      grade?: string | null,
      standard?: string | null,
      imageUrl?: string | null,
      gameTemplates?:  {
        __typename: "ModelGameQuestionsConnection",
        items:  Array< {
          __typename: "GameQuestions",
          id: string,
          gameTemplateID: string,
          questionTemplateID: string,
          gameTemplate:  {
            __typename: "GameTemplate",
            id: string,
            title: string,
            owner: string,
            version: number,
            description: string,
            domain?: string | null,
            cluster?: string | null,
            grade?: string | null,
            standard?: string | null,
            phaseOneTime?: number | null,
            phaseTwoTime?: number | null,
            imageUrl?: string | null,
            questionTemplates?:  {
              __typename: "ModelGameQuestionsConnection",
              items:  Array< {
                __typename: "GameQuestions",
                id: string,
                gameTemplateID: string,
                questionTemplateID: string,
                gameTemplate:  {
                  __typename: "GameTemplate",
                  id: string,
                  title: string,
                  owner: string,
                  version: number,
                  description: string,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  standard?: string | null,
                  phaseOneTime?: number | null,
                  phaseTwoTime?: number | null,
                  imageUrl?: string | null,
                  questionTemplatesCount: number,
                  questionTemplatesOrder?: string | null,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                questionTemplate:  {
                  __typename: "QuestionTemplate",
                  id: string,
                  title: string,
                  owner: string,
                  version: number,
                  choices?: string | null,
                  instructions?: string | null,
                  answerSettings?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  standard?: string | null,
                  imageUrl?: string | null,
                  gameTemplatesCount: number,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            questionTemplatesCount: number,
            questionTemplatesOrder?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            type?: string | null,
          },
          questionTemplate:  {
            __typename: "QuestionTemplate",
            id: string,
            title: string,
            owner: string,
            version: number,
            choices?: string | null,
            instructions?: string | null,
            answerSettings?: string | null,
            domain?: string | null,
            cluster?: string | null,
            grade?: string | null,
            standard?: string | null,
            imageUrl?: string | null,
            gameTemplates?:  {
              __typename: "ModelGameQuestionsConnection",
              items:  Array< {
                __typename: "GameQuestions",
                id: string,
                gameTemplateID: string,
                questionTemplateID: string,
                gameTemplate:  {
                  __typename: "GameTemplate",
                  id: string,
                  title: string,
                  owner: string,
                  version: number,
                  description: string,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  standard?: string | null,
                  phaseOneTime?: number | null,
                  phaseTwoTime?: number | null,
                  imageUrl?: string | null,
                  questionTemplatesCount: number,
                  questionTemplatesOrder?: string | null,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                questionTemplate:  {
                  __typename: "QuestionTemplate",
                  id: string,
                  title: string,
                  owner: string,
                  version: number,
                  choices?: string | null,
                  instructions?: string | null,
                  answerSettings?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  standard?: string | null,
                  imageUrl?: string | null,
                  gameTemplatesCount: number,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            gameTemplatesCount: number,
            createdAt?: string | null,
            updatedAt?: string | null,
            type?: string | null,
          },
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      gameTemplatesCount: number,
      createdAt?: string | null,
      updatedAt?: string | null,
      type?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetGameSessionQueryVariables = {
  id: string,
};

export type GetGameSessionQuery = {
  getGameSession?:  {
    __typename: "GameSession",
    id: string,
    gameId: string,
    startTime?: string | null,
    phaseOneTime: number,
    phaseTwoTime: number,
    teams?:  {
      __typename: "ModelTeamConnection",
      items:  Array< {
        __typename: "Team",
        id: string,
        name: string,
        question?:  {
          __typename: "Question",
          id: string,
          text: string,
          choices?: string | null,
          answerSettings?: string | null,
          responses?: string | null,
          hints?: string | null,
          imageUrl?: string | null,
          instructions?: string | null,
          standard?: string | null,
          cluster?: string | null,
          domain?: string | null,
          grade?: string | null,
          order: number,
          isConfidenceEnabled: boolean,
          isShortAnswerEnabled: boolean,
          isHintEnabled: boolean,
          gameSessionId: string,
        } | null,
        teamMembers?:  {
          __typename: "ModelTeamMemberConnection",
          items:  Array< {
            __typename: "TeamMember",
            id: string,
            isFacilitator?: boolean | null,
            answers?:  {
              __typename: "ModelTeamAnswerConnection",
              items:  Array< {
                __typename: "TeamAnswer",
                id: string,
                isCorrect?: boolean | null,
                isSubmitted: boolean,
                isShortAnswerEnabled: boolean,
                currentState: GameSessionState,
                currentQuestionIndex: number,
                questionId: string,
                teamMemberAnswersId: string,
                teamAnswersId?: string | null,
                teamName?: string | null,
                text: string,
                answer: string,
                confidenceLevel?: ConfidenceLevel | null,
                hint?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            deviceId: string,
            createdAt: string,
            updatedAt: string,
            teamTeamMembersId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        score: number,
        selectedAvatarIndex: number,
        createdAt: string,
        updatedAt: string,
        gameSessionTeamsId?: string | null,
        teamQuestionId?: string | null,
        teamQuestionOrder?: number | null,
        teamQuestionGameSessionId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    currentQuestionIndex?: number | null,
    currentState: GameSessionState,
    gameCode: number,
    isAdvancedMode: boolean,
    imageUrl?: string | null,
    description?: string | null,
    title?: string | null,
    currentTimer?: number | null,
    questions?:  {
      __typename: "ModelQuestionConnection",
      items:  Array< {
        __typename: "Question",
        id: string,
        text: string,
        choices?: string | null,
        answerSettings?: string | null,
        responses?: string | null,
        hints?: string | null,
        imageUrl?: string | null,
        instructions?: string | null,
        standard?: string | null,
        cluster?: string | null,
        domain?: string | null,
        grade?: string | null,
        order: number,
        isConfidenceEnabled: boolean,
        isShortAnswerEnabled: boolean,
        isHintEnabled: boolean,
        gameSessionId: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListGameSessionsQueryVariables = {
  filter?: ModelGameSessionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListGameSessionsQuery = {
  listGameSessions?:  {
    __typename: "ModelGameSessionConnection",
    items:  Array< {
      __typename: "GameSession",
      id: string,
      gameId: string,
      startTime?: string | null,
      phaseOneTime: number,
      phaseTwoTime: number,
      teams?:  {
        __typename: "ModelTeamConnection",
        items:  Array< {
          __typename: "Team",
          id: string,
          name: string,
          question?:  {
            __typename: "Question",
            id: string,
            text: string,
            choices?: string | null,
            answerSettings?: string | null,
            responses?: string | null,
            hints?: string | null,
            imageUrl?: string | null,
            instructions?: string | null,
            standard?: string | null,
            cluster?: string | null,
            domain?: string | null,
            grade?: string | null,
            order: number,
            isConfidenceEnabled: boolean,
            isShortAnswerEnabled: boolean,
            isHintEnabled: boolean,
            gameSessionId: string,
          } | null,
          teamMembers?:  {
            __typename: "ModelTeamMemberConnection",
            items:  Array< {
              __typename: "TeamMember",
              id: string,
              isFacilitator?: boolean | null,
              answers?:  {
                __typename: "ModelTeamAnswerConnection",
                items:  Array< {
                  __typename: "TeamAnswer",
                  id: string,
                  isCorrect?: boolean | null,
                  isSubmitted: boolean,
                  isShortAnswerEnabled: boolean,
                  currentState: GameSessionState,
                  currentQuestionIndex: number,
                  questionId: string,
                  teamMemberAnswersId: string,
                  teamAnswersId?: string | null,
                  teamName?: string | null,
                  text: string,
                  answer: string,
                  confidenceLevel?: ConfidenceLevel | null,
                  hint?: string | null,
                  createdAt: string,
                  updatedAt: string,
                } | null >,
                nextToken?: string | null,
              } | null,
              deviceId: string,
              createdAt: string,
              updatedAt: string,
              teamTeamMembersId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          score: number,
          selectedAvatarIndex: number,
          createdAt: string,
          updatedAt: string,
          gameSessionTeamsId?: string | null,
          teamQuestionId?: string | null,
          teamQuestionOrder?: number | null,
          teamQuestionGameSessionId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      currentQuestionIndex?: number | null,
      currentState: GameSessionState,
      gameCode: number,
      isAdvancedMode: boolean,
      imageUrl?: string | null,
      description?: string | null,
      title?: string | null,
      currentTimer?: number | null,
      questions?:  {
        __typename: "ModelQuestionConnection",
        items:  Array< {
          __typename: "Question",
          id: string,
          text: string,
          choices?: string | null,
          answerSettings?: string | null,
          responses?: string | null,
          hints?: string | null,
          imageUrl?: string | null,
          instructions?: string | null,
          standard?: string | null,
          cluster?: string | null,
          domain?: string | null,
          grade?: string | null,
          order: number,
          isConfidenceEnabled: boolean,
          isShortAnswerEnabled: boolean,
          isHintEnabled: boolean,
          gameSessionId: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetQuestionQueryVariables = {
  id: string,
  order: number,
  gameSessionId: string,
};

export type GetQuestionQuery = {
  getQuestion?:  {
    __typename: "Question",
    id: string,
    text: string,
    choices?: string | null,
    answerSettings?: string | null,
    responses?: string | null,
    hints?: string | null,
    imageUrl?: string | null,
    instructions?: string | null,
    standard?: string | null,
    cluster?: string | null,
    domain?: string | null,
    grade?: string | null,
    order: number,
    isConfidenceEnabled: boolean,
    isShortAnswerEnabled: boolean,
    isHintEnabled: boolean,
    gameSessionId: string,
  } | null,
};

export type ListQuestionsQueryVariables = {
  id?: string | null,
  orderGameSessionId?: ModelQuestionPrimaryCompositeKeyConditionInput | null,
  filter?: ModelQuestionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListQuestionsQuery = {
  listQuestions?:  {
    __typename: "ModelQuestionConnection",
    items:  Array< {
      __typename: "Question",
      id: string,
      text: string,
      choices?: string | null,
      answerSettings?: string | null,
      responses?: string | null,
      hints?: string | null,
      imageUrl?: string | null,
      instructions?: string | null,
      standard?: string | null,
      cluster?: string | null,
      domain?: string | null,
      grade?: string | null,
      order: number,
      isConfidenceEnabled: boolean,
      isShortAnswerEnabled: boolean,
      isHintEnabled: boolean,
      gameSessionId: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetTeamQueryVariables = {
  id: string,
};

export type GetTeamQuery = {
  getTeam?:  {
    __typename: "Team",
    id: string,
    name: string,
    question?:  {
      __typename: "Question",
      id: string,
      text: string,
      choices?: string | null,
      answerSettings?: string | null,
      responses?: string | null,
      hints?: string | null,
      imageUrl?: string | null,
      instructions?: string | null,
      standard?: string | null,
      cluster?: string | null,
      domain?: string | null,
      grade?: string | null,
      order: number,
      isConfidenceEnabled: boolean,
      isShortAnswerEnabled: boolean,
      isHintEnabled: boolean,
      gameSessionId: string,
    } | null,
    teamMembers?:  {
      __typename: "ModelTeamMemberConnection",
      items:  Array< {
        __typename: "TeamMember",
        id: string,
        isFacilitator?: boolean | null,
        answers?:  {
          __typename: "ModelTeamAnswerConnection",
          items:  Array< {
            __typename: "TeamAnswer",
            id: string,
            isCorrect?: boolean | null,
            isSubmitted: boolean,
            isShortAnswerEnabled: boolean,
            currentState: GameSessionState,
            currentQuestionIndex: number,
            questionId: string,
            teamMemberAnswersId: string,
            teamAnswersId?: string | null,
            teamName?: string | null,
            text: string,
            answer: string,
            confidenceLevel?: ConfidenceLevel | null,
            hint?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        deviceId: string,
        createdAt: string,
        updatedAt: string,
        teamTeamMembersId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    score: number,
    selectedAvatarIndex: number,
    createdAt: string,
    updatedAt: string,
    gameSessionTeamsId?: string | null,
    teamQuestionId?: string | null,
    teamQuestionOrder?: number | null,
    teamQuestionGameSessionId?: string | null,
  } | null,
};

export type ListTeamsQueryVariables = {
  filter?: ModelTeamFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTeamsQuery = {
  listTeams?:  {
    __typename: "ModelTeamConnection",
    items:  Array< {
      __typename: "Team",
      id: string,
      name: string,
      question?:  {
        __typename: "Question",
        id: string,
        text: string,
        choices?: string | null,
        answerSettings?: string | null,
        responses?: string | null,
        hints?: string | null,
        imageUrl?: string | null,
        instructions?: string | null,
        standard?: string | null,
        cluster?: string | null,
        domain?: string | null,
        grade?: string | null,
        order: number,
        isConfidenceEnabled: boolean,
        isShortAnswerEnabled: boolean,
        isHintEnabled: boolean,
        gameSessionId: string,
      } | null,
      teamMembers?:  {
        __typename: "ModelTeamMemberConnection",
        items:  Array< {
          __typename: "TeamMember",
          id: string,
          isFacilitator?: boolean | null,
          answers?:  {
            __typename: "ModelTeamAnswerConnection",
            items:  Array< {
              __typename: "TeamAnswer",
              id: string,
              isCorrect?: boolean | null,
              isSubmitted: boolean,
              isShortAnswerEnabled: boolean,
              currentState: GameSessionState,
              currentQuestionIndex: number,
              questionId: string,
              teamMemberAnswersId: string,
              teamAnswersId?: string | null,
              teamName?: string | null,
              text: string,
              answer: string,
              confidenceLevel?: ConfidenceLevel | null,
              hint?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          deviceId: string,
          createdAt: string,
          updatedAt: string,
          teamTeamMembersId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      score: number,
      selectedAvatarIndex: number,
      createdAt: string,
      updatedAt: string,
      gameSessionTeamsId?: string | null,
      teamQuestionId?: string | null,
      teamQuestionOrder?: number | null,
      teamQuestionGameSessionId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetTeamMemberQueryVariables = {
  id: string,
};

export type GetTeamMemberQuery = {
  getTeamMember?:  {
    __typename: "TeamMember",
    id: string,
    isFacilitator?: boolean | null,
    answers?:  {
      __typename: "ModelTeamAnswerConnection",
      items:  Array< {
        __typename: "TeamAnswer",
        id: string,
        isCorrect?: boolean | null,
        isSubmitted: boolean,
        isShortAnswerEnabled: boolean,
        currentState: GameSessionState,
        currentQuestionIndex: number,
        questionId: string,
        teamMemberAnswersId: string,
        teamAnswersId?: string | null,
        teamName?: string | null,
        text: string,
        answer: string,
        confidenceLevel?: ConfidenceLevel | null,
        hint?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    deviceId: string,
    createdAt: string,
    updatedAt: string,
    teamTeamMembersId?: string | null,
  } | null,
};

export type ListTeamMembersQueryVariables = {
  filter?: ModelTeamMemberFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTeamMembersQuery = {
  listTeamMembers?:  {
    __typename: "ModelTeamMemberConnection",
    items:  Array< {
      __typename: "TeamMember",
      id: string,
      isFacilitator?: boolean | null,
      answers?:  {
        __typename: "ModelTeamAnswerConnection",
        items:  Array< {
          __typename: "TeamAnswer",
          id: string,
          isCorrect?: boolean | null,
          isSubmitted: boolean,
          isShortAnswerEnabled: boolean,
          currentState: GameSessionState,
          currentQuestionIndex: number,
          questionId: string,
          teamMemberAnswersId: string,
          teamAnswersId?: string | null,
          teamName?: string | null,
          text: string,
          answer: string,
          confidenceLevel?: ConfidenceLevel | null,
          hint?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      deviceId: string,
      createdAt: string,
      updatedAt: string,
      teamTeamMembersId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetTeamAnswerQueryVariables = {
  id: string,
};

export type GetTeamAnswerQuery = {
  getTeamAnswer?:  {
    __typename: "TeamAnswer",
    id: string,
    isCorrect?: boolean | null,
    isSubmitted: boolean,
    isShortAnswerEnabled: boolean,
    currentState: GameSessionState,
    currentQuestionIndex: number,
    questionId: string,
    teamMemberAnswersId: string,
    teamAnswersId?: string | null,
    teamName?: string | null,
    text: string,
    answer: string,
    confidenceLevel?: ConfidenceLevel | null,
    hint?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListTeamAnswersQueryVariables = {
  filter?: ModelTeamAnswerFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTeamAnswersQuery = {
  listTeamAnswers?:  {
    __typename: "ModelTeamAnswerConnection",
    items:  Array< {
      __typename: "TeamAnswer",
      id: string,
      isCorrect?: boolean | null,
      isSubmitted: boolean,
      isShortAnswerEnabled: boolean,
      currentState: GameSessionState,
      currentQuestionIndex: number,
      questionId: string,
      teamMemberAnswersId: string,
      teamAnswersId?: string | null,
      teamName?: string | null,
      text: string,
      answer: string,
      confidenceLevel?: ConfidenceLevel | null,
      hint?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetGameQuestionsQueryVariables = {
  id: string,
};

export type GetGameQuestionsQuery = {
  getGameQuestions?:  {
    __typename: "GameQuestions",
    id: string,
    gameTemplateID: string,
    questionTemplateID: string,
    gameTemplate:  {
      __typename: "GameTemplate",
      id: string,
      title: string,
      owner: string,
      version: number,
      description: string,
      domain?: string | null,
      cluster?: string | null,
      grade?: string | null,
      standard?: string | null,
      phaseOneTime?: number | null,
      phaseTwoTime?: number | null,
      imageUrl?: string | null,
      questionTemplates?:  {
        __typename: "ModelGameQuestionsConnection",
        items:  Array< {
          __typename: "GameQuestions",
          id: string,
          gameTemplateID: string,
          questionTemplateID: string,
          gameTemplate:  {
            __typename: "GameTemplate",
            id: string,
            title: string,
            owner: string,
            version: number,
            description: string,
            domain?: string | null,
            cluster?: string | null,
            grade?: string | null,
            standard?: string | null,
            phaseOneTime?: number | null,
            phaseTwoTime?: number | null,
            imageUrl?: string | null,
            questionTemplates?:  {
              __typename: "ModelGameQuestionsConnection",
              items:  Array< {
                __typename: "GameQuestions",
                id: string,
                gameTemplateID: string,
                questionTemplateID: string,
                gameTemplate:  {
                  __typename: "GameTemplate",
                  id: string,
                  title: string,
                  owner: string,
                  version: number,
                  description: string,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  standard?: string | null,
                  phaseOneTime?: number | null,
                  phaseTwoTime?: number | null,
                  imageUrl?: string | null,
                  questionTemplatesCount: number,
                  questionTemplatesOrder?: string | null,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                questionTemplate:  {
                  __typename: "QuestionTemplate",
                  id: string,
                  title: string,
                  owner: string,
                  version: number,
                  choices?: string | null,
                  instructions?: string | null,
                  answerSettings?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  standard?: string | null,
                  imageUrl?: string | null,
                  gameTemplatesCount: number,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            questionTemplatesCount: number,
            questionTemplatesOrder?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            type?: string | null,
          },
          questionTemplate:  {
            __typename: "QuestionTemplate",
            id: string,
            title: string,
            owner: string,
            version: number,
            choices?: string | null,
            instructions?: string | null,
            answerSettings?: string | null,
            domain?: string | null,
            cluster?: string | null,
            grade?: string | null,
            standard?: string | null,
            imageUrl?: string | null,
            gameTemplates?:  {
              __typename: "ModelGameQuestionsConnection",
              items:  Array< {
                __typename: "GameQuestions",
                id: string,
                gameTemplateID: string,
                questionTemplateID: string,
                gameTemplate:  {
                  __typename: "GameTemplate",
                  id: string,
                  title: string,
                  owner: string,
                  version: number,
                  description: string,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  standard?: string | null,
                  phaseOneTime?: number | null,
                  phaseTwoTime?: number | null,
                  imageUrl?: string | null,
                  questionTemplatesCount: number,
                  questionTemplatesOrder?: string | null,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                questionTemplate:  {
                  __typename: "QuestionTemplate",
                  id: string,
                  title: string,
                  owner: string,
                  version: number,
                  choices?: string | null,
                  instructions?: string | null,
                  answerSettings?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  standard?: string | null,
                  imageUrl?: string | null,
                  gameTemplatesCount: number,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            gameTemplatesCount: number,
            createdAt?: string | null,
            updatedAt?: string | null,
            type?: string | null,
          },
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      questionTemplatesCount: number,
      questionTemplatesOrder?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      type?: string | null,
    },
    questionTemplate:  {
      __typename: "QuestionTemplate",
      id: string,
      title: string,
      owner: string,
      version: number,
      choices?: string | null,
      instructions?: string | null,
      answerSettings?: string | null,
      domain?: string | null,
      cluster?: string | null,
      grade?: string | null,
      standard?: string | null,
      imageUrl?: string | null,
      gameTemplates?:  {
        __typename: "ModelGameQuestionsConnection",
        items:  Array< {
          __typename: "GameQuestions",
          id: string,
          gameTemplateID: string,
          questionTemplateID: string,
          gameTemplate:  {
            __typename: "GameTemplate",
            id: string,
            title: string,
            owner: string,
            version: number,
            description: string,
            domain?: string | null,
            cluster?: string | null,
            grade?: string | null,
            standard?: string | null,
            phaseOneTime?: number | null,
            phaseTwoTime?: number | null,
            imageUrl?: string | null,
            questionTemplates?:  {
              __typename: "ModelGameQuestionsConnection",
              items:  Array< {
                __typename: "GameQuestions",
                id: string,
                gameTemplateID: string,
                questionTemplateID: string,
                gameTemplate:  {
                  __typename: "GameTemplate",
                  id: string,
                  title: string,
                  owner: string,
                  version: number,
                  description: string,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  standard?: string | null,
                  phaseOneTime?: number | null,
                  phaseTwoTime?: number | null,
                  imageUrl?: string | null,
                  questionTemplatesCount: number,
                  questionTemplatesOrder?: string | null,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                questionTemplate:  {
                  __typename: "QuestionTemplate",
                  id: string,
                  title: string,
                  owner: string,
                  version: number,
                  choices?: string | null,
                  instructions?: string | null,
                  answerSettings?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  standard?: string | null,
                  imageUrl?: string | null,
                  gameTemplatesCount: number,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            questionTemplatesCount: number,
            questionTemplatesOrder?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            type?: string | null,
          },
          questionTemplate:  {
            __typename: "QuestionTemplate",
            id: string,
            title: string,
            owner: string,
            version: number,
            choices?: string | null,
            instructions?: string | null,
            answerSettings?: string | null,
            domain?: string | null,
            cluster?: string | null,
            grade?: string | null,
            standard?: string | null,
            imageUrl?: string | null,
            gameTemplates?:  {
              __typename: "ModelGameQuestionsConnection",
              items:  Array< {
                __typename: "GameQuestions",
                id: string,
                gameTemplateID: string,
                questionTemplateID: string,
                gameTemplate:  {
                  __typename: "GameTemplate",
                  id: string,
                  title: string,
                  owner: string,
                  version: number,
                  description: string,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  standard?: string | null,
                  phaseOneTime?: number | null,
                  phaseTwoTime?: number | null,
                  imageUrl?: string | null,
                  questionTemplatesCount: number,
                  questionTemplatesOrder?: string | null,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                questionTemplate:  {
                  __typename: "QuestionTemplate",
                  id: string,
                  title: string,
                  owner: string,
                  version: number,
                  choices?: string | null,
                  instructions?: string | null,
                  answerSettings?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  standard?: string | null,
                  imageUrl?: string | null,
                  gameTemplatesCount: number,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            gameTemplatesCount: number,
            createdAt?: string | null,
            updatedAt?: string | null,
            type?: string | null,
          },
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      gameTemplatesCount: number,
      createdAt?: string | null,
      updatedAt?: string | null,
      type?: string | null,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListGameQuestionsQueryVariables = {
  filter?: ModelGameQuestionsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListGameQuestionsQuery = {
  listGameQuestions?:  {
    __typename: "ModelGameQuestionsConnection",
    items:  Array< {
      __typename: "GameQuestions",
      id: string,
      gameTemplateID: string,
      questionTemplateID: string,
      gameTemplate:  {
        __typename: "GameTemplate",
        id: string,
        title: string,
        owner: string,
        version: number,
        description: string,
        domain?: string | null,
        cluster?: string | null,
        grade?: string | null,
        standard?: string | null,
        phaseOneTime?: number | null,
        phaseTwoTime?: number | null,
        imageUrl?: string | null,
        questionTemplates?:  {
          __typename: "ModelGameQuestionsConnection",
          items:  Array< {
            __typename: "GameQuestions",
            id: string,
            gameTemplateID: string,
            questionTemplateID: string,
            gameTemplate:  {
              __typename: "GameTemplate",
              id: string,
              title: string,
              owner: string,
              version: number,
              description: string,
              domain?: string | null,
              cluster?: string | null,
              grade?: string | null,
              standard?: string | null,
              phaseOneTime?: number | null,
              phaseTwoTime?: number | null,
              imageUrl?: string | null,
              questionTemplates?:  {
                __typename: "ModelGameQuestionsConnection",
                items:  Array< {
                  __typename: "GameQuestions",
                  id: string,
                  gameTemplateID: string,
                  questionTemplateID: string,
                  createdAt: string,
                  updatedAt: string,
                } | null >,
                nextToken?: string | null,
              } | null,
              questionTemplatesCount: number,
              questionTemplatesOrder?: string | null,
              createdAt?: string | null,
              updatedAt?: string | null,
              type?: string | null,
            },
            questionTemplate:  {
              __typename: "QuestionTemplate",
              id: string,
              title: string,
              owner: string,
              version: number,
              choices?: string | null,
              instructions?: string | null,
              answerSettings?: string | null,
              domain?: string | null,
              cluster?: string | null,
              grade?: string | null,
              standard?: string | null,
              imageUrl?: string | null,
              gameTemplates?:  {
                __typename: "ModelGameQuestionsConnection",
                items:  Array< {
                  __typename: "GameQuestions",
                  id: string,
                  gameTemplateID: string,
                  questionTemplateID: string,
                  createdAt: string,
                  updatedAt: string,
                } | null >,
                nextToken?: string | null,
              } | null,
              gameTemplatesCount: number,
              createdAt?: string | null,
              updatedAt?: string | null,
              type?: string | null,
            },
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        questionTemplatesCount: number,
        questionTemplatesOrder?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        type?: string | null,
      },
      questionTemplate:  {
        __typename: "QuestionTemplate",
        id: string,
        title: string,
        owner: string,
        version: number,
        choices?: string | null,
        instructions?: string | null,
        answerSettings?: string | null,
        domain?: string | null,
        cluster?: string | null,
        grade?: string | null,
        standard?: string | null,
        imageUrl?: string | null,
        gameTemplates?:  {
          __typename: "ModelGameQuestionsConnection",
          items:  Array< {
            __typename: "GameQuestions",
            id: string,
            gameTemplateID: string,
            questionTemplateID: string,
            gameTemplate:  {
              __typename: "GameTemplate",
              id: string,
              title: string,
              owner: string,
              version: number,
              description: string,
              domain?: string | null,
              cluster?: string | null,
              grade?: string | null,
              standard?: string | null,
              phaseOneTime?: number | null,
              phaseTwoTime?: number | null,
              imageUrl?: string | null,
              questionTemplates?:  {
                __typename: "ModelGameQuestionsConnection",
                items:  Array< {
                  __typename: "GameQuestions",
                  id: string,
                  gameTemplateID: string,
                  questionTemplateID: string,
                  createdAt: string,
                  updatedAt: string,
                } | null >,
                nextToken?: string | null,
              } | null,
              questionTemplatesCount: number,
              questionTemplatesOrder?: string | null,
              createdAt?: string | null,
              updatedAt?: string | null,
              type?: string | null,
            },
            questionTemplate:  {
              __typename: "QuestionTemplate",
              id: string,
              title: string,
              owner: string,
              version: number,
              choices?: string | null,
              instructions?: string | null,
              answerSettings?: string | null,
              domain?: string | null,
              cluster?: string | null,
              grade?: string | null,
              standard?: string | null,
              imageUrl?: string | null,
              gameTemplates?:  {
                __typename: "ModelGameQuestionsConnection",
                items:  Array< {
                  __typename: "GameQuestions",
                  id: string,
                  gameTemplateID: string,
                  questionTemplateID: string,
                  createdAt: string,
                  updatedAt: string,
                } | null >,
                nextToken?: string | null,
              } | null,
              gameTemplatesCount: number,
              createdAt?: string | null,
              updatedAt?: string | null,
              type?: string | null,
            },
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        gameTemplatesCount: number,
        createdAt?: string | null,
        updatedAt?: string | null,
        type?: string | null,
      },
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GameTemplatesByOwnerQueryVariables = {
  owner: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelGameTemplateFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GameTemplatesByOwnerQuery = {
  gameTemplatesByOwner?:  {
    __typename: "ModelGameTemplateConnection",
    items:  Array< {
      __typename: "GameTemplate",
      id: string,
      title: string,
      owner: string,
      version: number,
      description: string,
      domain?: string | null,
      cluster?: string | null,
      grade?: string | null,
      standard?: string | null,
      phaseOneTime?: number | null,
      phaseTwoTime?: number | null,
      imageUrl?: string | null,
      questionTemplates?:  {
        __typename: "ModelGameQuestionsConnection",
        items:  Array< {
          __typename: "GameQuestions",
          id: string,
          gameTemplateID: string,
          questionTemplateID: string,
          gameTemplate:  {
            __typename: "GameTemplate",
            id: string,
            title: string,
            owner: string,
            version: number,
            description: string,
            domain?: string | null,
            cluster?: string | null,
            grade?: string | null,
            standard?: string | null,
            phaseOneTime?: number | null,
            phaseTwoTime?: number | null,
            imageUrl?: string | null,
            questionTemplates?:  {
              __typename: "ModelGameQuestionsConnection",
              items:  Array< {
                __typename: "GameQuestions",
                id: string,
                gameTemplateID: string,
                questionTemplateID: string,
                gameTemplate:  {
                  __typename: "GameTemplate",
                  id: string,
                  title: string,
                  owner: string,
                  version: number,
                  description: string,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  standard?: string | null,
                  phaseOneTime?: number | null,
                  phaseTwoTime?: number | null,
                  imageUrl?: string | null,
                  questionTemplatesCount: number,
                  questionTemplatesOrder?: string | null,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                questionTemplate:  {
                  __typename: "QuestionTemplate",
                  id: string,
                  title: string,
                  owner: string,
                  version: number,
                  choices?: string | null,
                  instructions?: string | null,
                  answerSettings?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  standard?: string | null,
                  imageUrl?: string | null,
                  gameTemplatesCount: number,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            questionTemplatesCount: number,
            questionTemplatesOrder?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            type?: string | null,
          },
          questionTemplate:  {
            __typename: "QuestionTemplate",
            id: string,
            title: string,
            owner: string,
            version: number,
            choices?: string | null,
            instructions?: string | null,
            answerSettings?: string | null,
            domain?: string | null,
            cluster?: string | null,
            grade?: string | null,
            standard?: string | null,
            imageUrl?: string | null,
            gameTemplates?:  {
              __typename: "ModelGameQuestionsConnection",
              items:  Array< {
                __typename: "GameQuestions",
                id: string,
                gameTemplateID: string,
                questionTemplateID: string,
                gameTemplate:  {
                  __typename: "GameTemplate",
                  id: string,
                  title: string,
                  owner: string,
                  version: number,
                  description: string,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  standard?: string | null,
                  phaseOneTime?: number | null,
                  phaseTwoTime?: number | null,
                  imageUrl?: string | null,
                  questionTemplatesCount: number,
                  questionTemplatesOrder?: string | null,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                questionTemplate:  {
                  __typename: "QuestionTemplate",
                  id: string,
                  title: string,
                  owner: string,
                  version: number,
                  choices?: string | null,
                  instructions?: string | null,
                  answerSettings?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  standard?: string | null,
                  imageUrl?: string | null,
                  gameTemplatesCount: number,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            gameTemplatesCount: number,
            createdAt?: string | null,
            updatedAt?: string | null,
            type?: string | null,
          },
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      questionTemplatesCount: number,
      questionTemplatesOrder?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      type?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GameTemplatesByDateQueryVariables = {
  type: string,
  updatedAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelGameTemplateFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GameTemplatesByDateQuery = {
  gameTemplatesByDate?:  {
    __typename: "ModelGameTemplateConnection",
    items:  Array< {
      __typename: "GameTemplate",
      id: string,
      title: string,
      owner: string,
      version: number,
      description: string,
      domain?: string | null,
      cluster?: string | null,
      grade?: string | null,
      standard?: string | null,
      phaseOneTime?: number | null,
      phaseTwoTime?: number | null,
      imageUrl?: string | null,
      questionTemplates?:  {
        __typename: "ModelGameQuestionsConnection",
        items:  Array< {
          __typename: "GameQuestions",
          id: string,
          gameTemplateID: string,
          questionTemplateID: string,
          gameTemplate:  {
            __typename: "GameTemplate",
            id: string,
            title: string,
            owner: string,
            version: number,
            description: string,
            domain?: string | null,
            cluster?: string | null,
            grade?: string | null,
            standard?: string | null,
            phaseOneTime?: number | null,
            phaseTwoTime?: number | null,
            imageUrl?: string | null,
            questionTemplates?:  {
              __typename: "ModelGameQuestionsConnection",
              items:  Array< {
                __typename: "GameQuestions",
                id: string,
                gameTemplateID: string,
                questionTemplateID: string,
                gameTemplate:  {
                  __typename: "GameTemplate",
                  id: string,
                  title: string,
                  owner: string,
                  version: number,
                  description: string,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  standard?: string | null,
                  phaseOneTime?: number | null,
                  phaseTwoTime?: number | null,
                  imageUrl?: string | null,
                  questionTemplatesCount: number,
                  questionTemplatesOrder?: string | null,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                questionTemplate:  {
                  __typename: "QuestionTemplate",
                  id: string,
                  title: string,
                  owner: string,
                  version: number,
                  choices?: string | null,
                  instructions?: string | null,
                  answerSettings?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  standard?: string | null,
                  imageUrl?: string | null,
                  gameTemplatesCount: number,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            questionTemplatesCount: number,
            questionTemplatesOrder?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            type?: string | null,
          },
          questionTemplate:  {
            __typename: "QuestionTemplate",
            id: string,
            title: string,
            owner: string,
            version: number,
            choices?: string | null,
            instructions?: string | null,
            answerSettings?: string | null,
            domain?: string | null,
            cluster?: string | null,
            grade?: string | null,
            standard?: string | null,
            imageUrl?: string | null,
            gameTemplates?:  {
              __typename: "ModelGameQuestionsConnection",
              items:  Array< {
                __typename: "GameQuestions",
                id: string,
                gameTemplateID: string,
                questionTemplateID: string,
                gameTemplate:  {
                  __typename: "GameTemplate",
                  id: string,
                  title: string,
                  owner: string,
                  version: number,
                  description: string,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  standard?: string | null,
                  phaseOneTime?: number | null,
                  phaseTwoTime?: number | null,
                  imageUrl?: string | null,
                  questionTemplatesCount: number,
                  questionTemplatesOrder?: string | null,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                questionTemplate:  {
                  __typename: "QuestionTemplate",
                  id: string,
                  title: string,
                  owner: string,
                  version: number,
                  choices?: string | null,
                  instructions?: string | null,
                  answerSettings?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  standard?: string | null,
                  imageUrl?: string | null,
                  gameTemplatesCount: number,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            gameTemplatesCount: number,
            createdAt?: string | null,
            updatedAt?: string | null,
            type?: string | null,
          },
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      questionTemplatesCount: number,
      questionTemplatesOrder?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      type?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GameTemplatesByGradeQueryVariables = {
  type: string,
  grade?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelGameTemplateFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GameTemplatesByGradeQuery = {
  gameTemplatesByGrade?:  {
    __typename: "ModelGameTemplateConnection",
    items:  Array< {
      __typename: "GameTemplate",
      id: string,
      title: string,
      owner: string,
      version: number,
      description: string,
      domain?: string | null,
      cluster?: string | null,
      grade?: string | null,
      standard?: string | null,
      phaseOneTime?: number | null,
      phaseTwoTime?: number | null,
      imageUrl?: string | null,
      questionTemplates?:  {
        __typename: "ModelGameQuestionsConnection",
        items:  Array< {
          __typename: "GameQuestions",
          id: string,
          gameTemplateID: string,
          questionTemplateID: string,
          gameTemplate:  {
            __typename: "GameTemplate",
            id: string,
            title: string,
            owner: string,
            version: number,
            description: string,
            domain?: string | null,
            cluster?: string | null,
            grade?: string | null,
            standard?: string | null,
            phaseOneTime?: number | null,
            phaseTwoTime?: number | null,
            imageUrl?: string | null,
            questionTemplates?:  {
              __typename: "ModelGameQuestionsConnection",
              items:  Array< {
                __typename: "GameQuestions",
                id: string,
                gameTemplateID: string,
                questionTemplateID: string,
                gameTemplate:  {
                  __typename: "GameTemplate",
                  id: string,
                  title: string,
                  owner: string,
                  version: number,
                  description: string,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  standard?: string | null,
                  phaseOneTime?: number | null,
                  phaseTwoTime?: number | null,
                  imageUrl?: string | null,
                  questionTemplatesCount: number,
                  questionTemplatesOrder?: string | null,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                questionTemplate:  {
                  __typename: "QuestionTemplate",
                  id: string,
                  title: string,
                  owner: string,
                  version: number,
                  choices?: string | null,
                  instructions?: string | null,
                  answerSettings?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  standard?: string | null,
                  imageUrl?: string | null,
                  gameTemplatesCount: number,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            questionTemplatesCount: number,
            questionTemplatesOrder?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            type?: string | null,
          },
          questionTemplate:  {
            __typename: "QuestionTemplate",
            id: string,
            title: string,
            owner: string,
            version: number,
            choices?: string | null,
            instructions?: string | null,
            answerSettings?: string | null,
            domain?: string | null,
            cluster?: string | null,
            grade?: string | null,
            standard?: string | null,
            imageUrl?: string | null,
            gameTemplates?:  {
              __typename: "ModelGameQuestionsConnection",
              items:  Array< {
                __typename: "GameQuestions",
                id: string,
                gameTemplateID: string,
                questionTemplateID: string,
                gameTemplate:  {
                  __typename: "GameTemplate",
                  id: string,
                  title: string,
                  owner: string,
                  version: number,
                  description: string,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  standard?: string | null,
                  phaseOneTime?: number | null,
                  phaseTwoTime?: number | null,
                  imageUrl?: string | null,
                  questionTemplatesCount: number,
                  questionTemplatesOrder?: string | null,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                questionTemplate:  {
                  __typename: "QuestionTemplate",
                  id: string,
                  title: string,
                  owner: string,
                  version: number,
                  choices?: string | null,
                  instructions?: string | null,
                  answerSettings?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  standard?: string | null,
                  imageUrl?: string | null,
                  gameTemplatesCount: number,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            gameTemplatesCount: number,
            createdAt?: string | null,
            updatedAt?: string | null,
            type?: string | null,
          },
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      questionTemplatesCount: number,
      questionTemplatesOrder?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      type?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GameTemplatesByQuestionTemplatesCountQueryVariables = {
  type: string,
  questionTemplatesCount?: ModelIntKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelGameTemplateFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GameTemplatesByQuestionTemplatesCountQuery = {
  gameTemplatesByQuestionTemplatesCount?:  {
    __typename: "ModelGameTemplateConnection",
    items:  Array< {
      __typename: "GameTemplate",
      id: string,
      title: string,
      owner: string,
      version: number,
      description: string,
      domain?: string | null,
      cluster?: string | null,
      grade?: string | null,
      standard?: string | null,
      phaseOneTime?: number | null,
      phaseTwoTime?: number | null,
      imageUrl?: string | null,
      questionTemplates?:  {
        __typename: "ModelGameQuestionsConnection",
        items:  Array< {
          __typename: "GameQuestions",
          id: string,
          gameTemplateID: string,
          questionTemplateID: string,
          gameTemplate:  {
            __typename: "GameTemplate",
            id: string,
            title: string,
            owner: string,
            version: number,
            description: string,
            domain?: string | null,
            cluster?: string | null,
            grade?: string | null,
            standard?: string | null,
            phaseOneTime?: number | null,
            phaseTwoTime?: number | null,
            imageUrl?: string | null,
            questionTemplates?:  {
              __typename: "ModelGameQuestionsConnection",
              items:  Array< {
                __typename: "GameQuestions",
                id: string,
                gameTemplateID: string,
                questionTemplateID: string,
                gameTemplate:  {
                  __typename: "GameTemplate",
                  id: string,
                  title: string,
                  owner: string,
                  version: number,
                  description: string,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  standard?: string | null,
                  phaseOneTime?: number | null,
                  phaseTwoTime?: number | null,
                  imageUrl?: string | null,
                  questionTemplatesCount: number,
                  questionTemplatesOrder?: string | null,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                questionTemplate:  {
                  __typename: "QuestionTemplate",
                  id: string,
                  title: string,
                  owner: string,
                  version: number,
                  choices?: string | null,
                  instructions?: string | null,
                  answerSettings?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  standard?: string | null,
                  imageUrl?: string | null,
                  gameTemplatesCount: number,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            questionTemplatesCount: number,
            questionTemplatesOrder?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            type?: string | null,
          },
          questionTemplate:  {
            __typename: "QuestionTemplate",
            id: string,
            title: string,
            owner: string,
            version: number,
            choices?: string | null,
            instructions?: string | null,
            answerSettings?: string | null,
            domain?: string | null,
            cluster?: string | null,
            grade?: string | null,
            standard?: string | null,
            imageUrl?: string | null,
            gameTemplates?:  {
              __typename: "ModelGameQuestionsConnection",
              items:  Array< {
                __typename: "GameQuestions",
                id: string,
                gameTemplateID: string,
                questionTemplateID: string,
                gameTemplate:  {
                  __typename: "GameTemplate",
                  id: string,
                  title: string,
                  owner: string,
                  version: number,
                  description: string,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  standard?: string | null,
                  phaseOneTime?: number | null,
                  phaseTwoTime?: number | null,
                  imageUrl?: string | null,
                  questionTemplatesCount: number,
                  questionTemplatesOrder?: string | null,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                questionTemplate:  {
                  __typename: "QuestionTemplate",
                  id: string,
                  title: string,
                  owner: string,
                  version: number,
                  choices?: string | null,
                  instructions?: string | null,
                  answerSettings?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  standard?: string | null,
                  imageUrl?: string | null,
                  gameTemplatesCount: number,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            gameTemplatesCount: number,
            createdAt?: string | null,
            updatedAt?: string | null,
            type?: string | null,
          },
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      questionTemplatesCount: number,
      questionTemplatesOrder?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      type?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type QuestionTemplatesByDateQueryVariables = {
  type: string,
  updatedAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelQuestionTemplateFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type QuestionTemplatesByDateQuery = {
  questionTemplatesByDate?:  {
    __typename: "ModelQuestionTemplateConnection",
    items:  Array< {
      __typename: "QuestionTemplate",
      id: string,
      title: string,
      owner: string,
      version: number,
      choices?: string | null,
      instructions?: string | null,
      answerSettings?: string | null,
      domain?: string | null,
      cluster?: string | null,
      grade?: string | null,
      standard?: string | null,
      imageUrl?: string | null,
      gameTemplates?:  {
        __typename: "ModelGameQuestionsConnection",
        items:  Array< {
          __typename: "GameQuestions",
          id: string,
          gameTemplateID: string,
          questionTemplateID: string,
          gameTemplate:  {
            __typename: "GameTemplate",
            id: string,
            title: string,
            owner: string,
            version: number,
            description: string,
            domain?: string | null,
            cluster?: string | null,
            grade?: string | null,
            standard?: string | null,
            phaseOneTime?: number | null,
            phaseTwoTime?: number | null,
            imageUrl?: string | null,
            questionTemplates?:  {
              __typename: "ModelGameQuestionsConnection",
              items:  Array< {
                __typename: "GameQuestions",
                id: string,
                gameTemplateID: string,
                questionTemplateID: string,
                gameTemplate:  {
                  __typename: "GameTemplate",
                  id: string,
                  title: string,
                  owner: string,
                  version: number,
                  description: string,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  standard?: string | null,
                  phaseOneTime?: number | null,
                  phaseTwoTime?: number | null,
                  imageUrl?: string | null,
                  questionTemplatesCount: number,
                  questionTemplatesOrder?: string | null,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                questionTemplate:  {
                  __typename: "QuestionTemplate",
                  id: string,
                  title: string,
                  owner: string,
                  version: number,
                  choices?: string | null,
                  instructions?: string | null,
                  answerSettings?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  standard?: string | null,
                  imageUrl?: string | null,
                  gameTemplatesCount: number,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            questionTemplatesCount: number,
            questionTemplatesOrder?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            type?: string | null,
          },
          questionTemplate:  {
            __typename: "QuestionTemplate",
            id: string,
            title: string,
            owner: string,
            version: number,
            choices?: string | null,
            instructions?: string | null,
            answerSettings?: string | null,
            domain?: string | null,
            cluster?: string | null,
            grade?: string | null,
            standard?: string | null,
            imageUrl?: string | null,
            gameTemplates?:  {
              __typename: "ModelGameQuestionsConnection",
              items:  Array< {
                __typename: "GameQuestions",
                id: string,
                gameTemplateID: string,
                questionTemplateID: string,
                gameTemplate:  {
                  __typename: "GameTemplate",
                  id: string,
                  title: string,
                  owner: string,
                  version: number,
                  description: string,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  standard?: string | null,
                  phaseOneTime?: number | null,
                  phaseTwoTime?: number | null,
                  imageUrl?: string | null,
                  questionTemplatesCount: number,
                  questionTemplatesOrder?: string | null,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                questionTemplate:  {
                  __typename: "QuestionTemplate",
                  id: string,
                  title: string,
                  owner: string,
                  version: number,
                  choices?: string | null,
                  instructions?: string | null,
                  answerSettings?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  standard?: string | null,
                  imageUrl?: string | null,
                  gameTemplatesCount: number,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            gameTemplatesCount: number,
            createdAt?: string | null,
            updatedAt?: string | null,
            type?: string | null,
          },
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      gameTemplatesCount: number,
      createdAt?: string | null,
      updatedAt?: string | null,
      type?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type QuestionTemplatesByGradeQueryVariables = {
  type: string,
  grade?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelQuestionTemplateFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type QuestionTemplatesByGradeQuery = {
  questionTemplatesByGrade?:  {
    __typename: "ModelQuestionTemplateConnection",
    items:  Array< {
      __typename: "QuestionTemplate",
      id: string,
      title: string,
      owner: string,
      version: number,
      choices?: string | null,
      instructions?: string | null,
      answerSettings?: string | null,
      domain?: string | null,
      cluster?: string | null,
      grade?: string | null,
      standard?: string | null,
      imageUrl?: string | null,
      gameTemplates?:  {
        __typename: "ModelGameQuestionsConnection",
        items:  Array< {
          __typename: "GameQuestions",
          id: string,
          gameTemplateID: string,
          questionTemplateID: string,
          gameTemplate:  {
            __typename: "GameTemplate",
            id: string,
            title: string,
            owner: string,
            version: number,
            description: string,
            domain?: string | null,
            cluster?: string | null,
            grade?: string | null,
            standard?: string | null,
            phaseOneTime?: number | null,
            phaseTwoTime?: number | null,
            imageUrl?: string | null,
            questionTemplates?:  {
              __typename: "ModelGameQuestionsConnection",
              items:  Array< {
                __typename: "GameQuestions",
                id: string,
                gameTemplateID: string,
                questionTemplateID: string,
                gameTemplate:  {
                  __typename: "GameTemplate",
                  id: string,
                  title: string,
                  owner: string,
                  version: number,
                  description: string,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  standard?: string | null,
                  phaseOneTime?: number | null,
                  phaseTwoTime?: number | null,
                  imageUrl?: string | null,
                  questionTemplatesCount: number,
                  questionTemplatesOrder?: string | null,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                questionTemplate:  {
                  __typename: "QuestionTemplate",
                  id: string,
                  title: string,
                  owner: string,
                  version: number,
                  choices?: string | null,
                  instructions?: string | null,
                  answerSettings?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  standard?: string | null,
                  imageUrl?: string | null,
                  gameTemplatesCount: number,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            questionTemplatesCount: number,
            questionTemplatesOrder?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            type?: string | null,
          },
          questionTemplate:  {
            __typename: "QuestionTemplate",
            id: string,
            title: string,
            owner: string,
            version: number,
            choices?: string | null,
            instructions?: string | null,
            answerSettings?: string | null,
            domain?: string | null,
            cluster?: string | null,
            grade?: string | null,
            standard?: string | null,
            imageUrl?: string | null,
            gameTemplates?:  {
              __typename: "ModelGameQuestionsConnection",
              items:  Array< {
                __typename: "GameQuestions",
                id: string,
                gameTemplateID: string,
                questionTemplateID: string,
                gameTemplate:  {
                  __typename: "GameTemplate",
                  id: string,
                  title: string,
                  owner: string,
                  version: number,
                  description: string,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  standard?: string | null,
                  phaseOneTime?: number | null,
                  phaseTwoTime?: number | null,
                  imageUrl?: string | null,
                  questionTemplatesCount: number,
                  questionTemplatesOrder?: string | null,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                questionTemplate:  {
                  __typename: "QuestionTemplate",
                  id: string,
                  title: string,
                  owner: string,
                  version: number,
                  choices?: string | null,
                  instructions?: string | null,
                  answerSettings?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  standard?: string | null,
                  imageUrl?: string | null,
                  gameTemplatesCount: number,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            gameTemplatesCount: number,
            createdAt?: string | null,
            updatedAt?: string | null,
            type?: string | null,
          },
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      gameTemplatesCount: number,
      createdAt?: string | null,
      updatedAt?: string | null,
      type?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type QuestionTemplatesByGameTemplatesCountQueryVariables = {
  type: string,
  gameTemplatesCount?: ModelIntKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelQuestionTemplateFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type QuestionTemplatesByGameTemplatesCountQuery = {
  questionTemplatesByGameTemplatesCount?:  {
    __typename: "ModelQuestionTemplateConnection",
    items:  Array< {
      __typename: "QuestionTemplate",
      id: string,
      title: string,
      owner: string,
      version: number,
      choices?: string | null,
      instructions?: string | null,
      answerSettings?: string | null,
      domain?: string | null,
      cluster?: string | null,
      grade?: string | null,
      standard?: string | null,
      imageUrl?: string | null,
      gameTemplates?:  {
        __typename: "ModelGameQuestionsConnection",
        items:  Array< {
          __typename: "GameQuestions",
          id: string,
          gameTemplateID: string,
          questionTemplateID: string,
          gameTemplate:  {
            __typename: "GameTemplate",
            id: string,
            title: string,
            owner: string,
            version: number,
            description: string,
            domain?: string | null,
            cluster?: string | null,
            grade?: string | null,
            standard?: string | null,
            phaseOneTime?: number | null,
            phaseTwoTime?: number | null,
            imageUrl?: string | null,
            questionTemplates?:  {
              __typename: "ModelGameQuestionsConnection",
              items:  Array< {
                __typename: "GameQuestions",
                id: string,
                gameTemplateID: string,
                questionTemplateID: string,
                gameTemplate:  {
                  __typename: "GameTemplate",
                  id: string,
                  title: string,
                  owner: string,
                  version: number,
                  description: string,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  standard?: string | null,
                  phaseOneTime?: number | null,
                  phaseTwoTime?: number | null,
                  imageUrl?: string | null,
                  questionTemplatesCount: number,
                  questionTemplatesOrder?: string | null,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                questionTemplate:  {
                  __typename: "QuestionTemplate",
                  id: string,
                  title: string,
                  owner: string,
                  version: number,
                  choices?: string | null,
                  instructions?: string | null,
                  answerSettings?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  standard?: string | null,
                  imageUrl?: string | null,
                  gameTemplatesCount: number,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            questionTemplatesCount: number,
            questionTemplatesOrder?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            type?: string | null,
          },
          questionTemplate:  {
            __typename: "QuestionTemplate",
            id: string,
            title: string,
            owner: string,
            version: number,
            choices?: string | null,
            instructions?: string | null,
            answerSettings?: string | null,
            domain?: string | null,
            cluster?: string | null,
            grade?: string | null,
            standard?: string | null,
            imageUrl?: string | null,
            gameTemplates?:  {
              __typename: "ModelGameQuestionsConnection",
              items:  Array< {
                __typename: "GameQuestions",
                id: string,
                gameTemplateID: string,
                questionTemplateID: string,
                gameTemplate:  {
                  __typename: "GameTemplate",
                  id: string,
                  title: string,
                  owner: string,
                  version: number,
                  description: string,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  standard?: string | null,
                  phaseOneTime?: number | null,
                  phaseTwoTime?: number | null,
                  imageUrl?: string | null,
                  questionTemplatesCount: number,
                  questionTemplatesOrder?: string | null,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                questionTemplate:  {
                  __typename: "QuestionTemplate",
                  id: string,
                  title: string,
                  owner: string,
                  version: number,
                  choices?: string | null,
                  instructions?: string | null,
                  answerSettings?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  standard?: string | null,
                  imageUrl?: string | null,
                  gameTemplatesCount: number,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            gameTemplatesCount: number,
            createdAt?: string | null,
            updatedAt?: string | null,
            type?: string | null,
          },
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      gameTemplatesCount: number,
      createdAt?: string | null,
      updatedAt?: string | null,
      type?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GameSessionByStateQueryVariables = {
  currentState: GameSessionState,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelGameSessionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GameSessionByStateQuery = {
  gameSessionByState?:  {
    __typename: "ModelGameSessionConnection",
    items:  Array< {
      __typename: "GameSession",
      id: string,
      gameId: string,
      startTime?: string | null,
      phaseOneTime: number,
      phaseTwoTime: number,
      teams?:  {
        __typename: "ModelTeamConnection",
        items:  Array< {
          __typename: "Team",
          id: string,
          name: string,
          question?:  {
            __typename: "Question",
            id: string,
            text: string,
            choices?: string | null,
            answerSettings?: string | null,
            responses?: string | null,
            hints?: string | null,
            imageUrl?: string | null,
            instructions?: string | null,
            standard?: string | null,
            cluster?: string | null,
            domain?: string | null,
            grade?: string | null,
            order: number,
            isConfidenceEnabled: boolean,
            isShortAnswerEnabled: boolean,
            isHintEnabled: boolean,
            gameSessionId: string,
          } | null,
          teamMembers?:  {
            __typename: "ModelTeamMemberConnection",
            items:  Array< {
              __typename: "TeamMember",
              id: string,
              isFacilitator?: boolean | null,
              answers?:  {
                __typename: "ModelTeamAnswerConnection",
                items:  Array< {
                  __typename: "TeamAnswer",
                  id: string,
                  isCorrect?: boolean | null,
                  isSubmitted: boolean,
                  isShortAnswerEnabled: boolean,
                  currentState: GameSessionState,
                  currentQuestionIndex: number,
                  questionId: string,
                  teamMemberAnswersId: string,
                  teamAnswersId?: string | null,
                  teamName?: string | null,
                  text: string,
                  answer: string,
                  confidenceLevel?: ConfidenceLevel | null,
                  hint?: string | null,
                  createdAt: string,
                  updatedAt: string,
                } | null >,
                nextToken?: string | null,
              } | null,
              deviceId: string,
              createdAt: string,
              updatedAt: string,
              teamTeamMembersId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          score: number,
          selectedAvatarIndex: number,
          createdAt: string,
          updatedAt: string,
          gameSessionTeamsId?: string | null,
          teamQuestionId?: string | null,
          teamQuestionOrder?: number | null,
          teamQuestionGameSessionId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      currentQuestionIndex?: number | null,
      currentState: GameSessionState,
      gameCode: number,
      isAdvancedMode: boolean,
      imageUrl?: string | null,
      description?: string | null,
      title?: string | null,
      currentTimer?: number | null,
      questions?:  {
        __typename: "ModelQuestionConnection",
        items:  Array< {
          __typename: "Question",
          id: string,
          text: string,
          choices?: string | null,
          answerSettings?: string | null,
          responses?: string | null,
          hints?: string | null,
          imageUrl?: string | null,
          instructions?: string | null,
          standard?: string | null,
          cluster?: string | null,
          domain?: string | null,
          grade?: string | null,
          order: number,
          isConfidenceEnabled: boolean,
          isShortAnswerEnabled: boolean,
          isHintEnabled: boolean,
          gameSessionId: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GameSessionByCodeQueryVariables = {
  gameCode: number,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelGameSessionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GameSessionByCodeQuery = {
  gameSessionByCode?:  {
    __typename: "ModelGameSessionConnection",
    items:  Array< {
      __typename: "GameSession",
      id: string,
      gameId: string,
      startTime?: string | null,
      phaseOneTime: number,
      phaseTwoTime: number,
      teams?:  {
        __typename: "ModelTeamConnection",
        items:  Array< {
          __typename: "Team",
          id: string,
          name: string,
          question?:  {
            __typename: "Question",
            id: string,
            text: string,
            choices?: string | null,
            answerSettings?: string | null,
            responses?: string | null,
            hints?: string | null,
            imageUrl?: string | null,
            instructions?: string | null,
            standard?: string | null,
            cluster?: string | null,
            domain?: string | null,
            grade?: string | null,
            order: number,
            isConfidenceEnabled: boolean,
            isShortAnswerEnabled: boolean,
            isHintEnabled: boolean,
            gameSessionId: string,
          } | null,
          teamMembers?:  {
            __typename: "ModelTeamMemberConnection",
            items:  Array< {
              __typename: "TeamMember",
              id: string,
              isFacilitator?: boolean | null,
              answers?:  {
                __typename: "ModelTeamAnswerConnection",
                items:  Array< {
                  __typename: "TeamAnswer",
                  id: string,
                  isCorrect?: boolean | null,
                  isSubmitted: boolean,
                  isShortAnswerEnabled: boolean,
                  currentState: GameSessionState,
                  currentQuestionIndex: number,
                  questionId: string,
                  teamMemberAnswersId: string,
                  teamAnswersId?: string | null,
                  teamName?: string | null,
                  text: string,
                  answer: string,
                  confidenceLevel?: ConfidenceLevel | null,
                  hint?: string | null,
                  createdAt: string,
                  updatedAt: string,
                } | null >,
                nextToken?: string | null,
              } | null,
              deviceId: string,
              createdAt: string,
              updatedAt: string,
              teamTeamMembersId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          score: number,
          selectedAvatarIndex: number,
          createdAt: string,
          updatedAt: string,
          gameSessionTeamsId?: string | null,
          teamQuestionId?: string | null,
          teamQuestionOrder?: number | null,
          teamQuestionGameSessionId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      currentQuestionIndex?: number | null,
      currentState: GameSessionState,
      gameCode: number,
      isAdvancedMode: boolean,
      imageUrl?: string | null,
      description?: string | null,
      title?: string | null,
      currentTimer?: number | null,
      questions?:  {
        __typename: "ModelQuestionConnection",
        items:  Array< {
          __typename: "Question",
          id: string,
          text: string,
          choices?: string | null,
          answerSettings?: string | null,
          responses?: string | null,
          hints?: string | null,
          imageUrl?: string | null,
          instructions?: string | null,
          standard?: string | null,
          cluster?: string | null,
          domain?: string | null,
          grade?: string | null,
          order: number,
          isConfidenceEnabled: boolean,
          isShortAnswerEnabled: boolean,
          isHintEnabled: boolean,
          gameSessionId: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type OnGameSessionUpdatedByIdSubscriptionVariables = {
  id: string,
};

export type OnGameSessionUpdatedByIdSubscription = {
  onGameSessionUpdatedById?:  {
    __typename: "GameSession",
    id: string,
    gameId: string,
    startTime?: string | null,
    phaseOneTime: number,
    phaseTwoTime: number,
    teams?:  {
      __typename: "ModelTeamConnection",
      items:  Array< {
        __typename: "Team",
        id: string,
        name: string,
        question?:  {
          __typename: "Question",
          id: string,
          text: string,
          choices?: string | null,
          answerSettings?: string | null,
          responses?: string | null,
          hints?: string | null,
          imageUrl?: string | null,
          instructions?: string | null,
          standard?: string | null,
          cluster?: string | null,
          domain?: string | null,
          grade?: string | null,
          order: number,
          isConfidenceEnabled: boolean,
          isShortAnswerEnabled: boolean,
          isHintEnabled: boolean,
          gameSessionId: string,
        } | null,
        teamMembers?:  {
          __typename: "ModelTeamMemberConnection",
          items:  Array< {
            __typename: "TeamMember",
            id: string,
            isFacilitator?: boolean | null,
            answers?:  {
              __typename: "ModelTeamAnswerConnection",
              items:  Array< {
                __typename: "TeamAnswer",
                id: string,
                isCorrect?: boolean | null,
                isSubmitted: boolean,
                isShortAnswerEnabled: boolean,
                currentState: GameSessionState,
                currentQuestionIndex: number,
                questionId: string,
                teamMemberAnswersId: string,
                teamAnswersId?: string | null,
                teamName?: string | null,
                text: string,
                answer: string,
                confidenceLevel?: ConfidenceLevel | null,
                hint?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            deviceId: string,
            createdAt: string,
            updatedAt: string,
            teamTeamMembersId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        score: number,
        selectedAvatarIndex: number,
        createdAt: string,
        updatedAt: string,
        gameSessionTeamsId?: string | null,
        teamQuestionId?: string | null,
        teamQuestionOrder?: number | null,
        teamQuestionGameSessionId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    currentQuestionIndex?: number | null,
    currentState: GameSessionState,
    gameCode: number,
    isAdvancedMode: boolean,
    imageUrl?: string | null,
    description?: string | null,
    title?: string | null,
    currentTimer?: number | null,
    questions?:  {
      __typename: "ModelQuestionConnection",
      items:  Array< {
        __typename: "Question",
        id: string,
        text: string,
        choices?: string | null,
        answerSettings?: string | null,
        responses?: string | null,
        hints?: string | null,
        imageUrl?: string | null,
        instructions?: string | null,
        standard?: string | null,
        cluster?: string | null,
        domain?: string | null,
        grade?: string | null,
        order: number,
        isConfidenceEnabled: boolean,
        isShortAnswerEnabled: boolean,
        isHintEnabled: boolean,
        gameSessionId: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnTeamMemberUpdateByTeamIdSubscriptionVariables = {
  teamTeamMembersId: string,
};

export type OnTeamMemberUpdateByTeamIdSubscription = {
  onTeamMemberUpdateByTeamId?:  {
    __typename: "TeamMember",
    id: string,
    isFacilitator?: boolean | null,
    answers?:  {
      __typename: "ModelTeamAnswerConnection",
      items:  Array< {
        __typename: "TeamAnswer",
        id: string,
        isCorrect?: boolean | null,
        isSubmitted: boolean,
        isShortAnswerEnabled: boolean,
        currentState: GameSessionState,
        currentQuestionIndex: number,
        questionId: string,
        teamMemberAnswersId: string,
        teamAnswersId?: string | null,
        teamName?: string | null,
        text: string,
        answer: string,
        confidenceLevel?: ConfidenceLevel | null,
        hint?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    deviceId: string,
    createdAt: string,
    updatedAt: string,
    teamTeamMembersId?: string | null,
  } | null,
};

export type OnTeamCreateByGameSessionIdSubscriptionVariables = {
  gameSessionTeamsId: string,
};

export type OnTeamCreateByGameSessionIdSubscription = {
  onTeamCreateByGameSessionId?:  {
    __typename: "Team",
    id: string,
    name: string,
    question?:  {
      __typename: "Question",
      id: string,
      text: string,
      choices?: string | null,
      answerSettings?: string | null,
      responses?: string | null,
      hints?: string | null,
      imageUrl?: string | null,
      instructions?: string | null,
      standard?: string | null,
      cluster?: string | null,
      domain?: string | null,
      grade?: string | null,
      order: number,
      isConfidenceEnabled: boolean,
      isShortAnswerEnabled: boolean,
      isHintEnabled: boolean,
      gameSessionId: string,
    } | null,
    teamMembers?:  {
      __typename: "ModelTeamMemberConnection",
      items:  Array< {
        __typename: "TeamMember",
        id: string,
        isFacilitator?: boolean | null,
        answers?:  {
          __typename: "ModelTeamAnswerConnection",
          items:  Array< {
            __typename: "TeamAnswer",
            id: string,
            isCorrect?: boolean | null,
            isSubmitted: boolean,
            isShortAnswerEnabled: boolean,
            currentState: GameSessionState,
            currentQuestionIndex: number,
            questionId: string,
            teamMemberAnswersId: string,
            teamAnswersId?: string | null,
            teamName?: string | null,
            text: string,
            answer: string,
            confidenceLevel?: ConfidenceLevel | null,
            hint?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        deviceId: string,
        createdAt: string,
        updatedAt: string,
        teamTeamMembersId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    score: number,
    selectedAvatarIndex: number,
    createdAt: string,
    updatedAt: string,
    gameSessionTeamsId?: string | null,
    teamQuestionId?: string | null,
    teamQuestionOrder?: number | null,
    teamQuestionGameSessionId?: string | null,
  } | null,
};

export type OnTeamDeleteByGameSessionIdSubscriptionVariables = {
  gameSessionTeamsId: string,
};

export type OnTeamDeleteByGameSessionIdSubscription = {
  onTeamDeleteByGameSessionId?:  {
    __typename: "Team",
    id: string,
    name: string,
    question?:  {
      __typename: "Question",
      id: string,
      text: string,
      choices?: string | null,
      answerSettings?: string | null,
      responses?: string | null,
      hints?: string | null,
      imageUrl?: string | null,
      instructions?: string | null,
      standard?: string | null,
      cluster?: string | null,
      domain?: string | null,
      grade?: string | null,
      order: number,
      isConfidenceEnabled: boolean,
      isShortAnswerEnabled: boolean,
      isHintEnabled: boolean,
      gameSessionId: string,
    } | null,
    teamMembers?:  {
      __typename: "ModelTeamMemberConnection",
      items:  Array< {
        __typename: "TeamMember",
        id: string,
        isFacilitator?: boolean | null,
        answers?:  {
          __typename: "ModelTeamAnswerConnection",
          items:  Array< {
            __typename: "TeamAnswer",
            id: string,
            isCorrect?: boolean | null,
            isSubmitted: boolean,
            isShortAnswerEnabled: boolean,
            currentState: GameSessionState,
            currentQuestionIndex: number,
            questionId: string,
            teamMemberAnswersId: string,
            teamAnswersId?: string | null,
            teamName?: string | null,
            text: string,
            answer: string,
            confidenceLevel?: ConfidenceLevel | null,
            hint?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        deviceId: string,
        createdAt: string,
        updatedAt: string,
        teamTeamMembersId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    score: number,
    selectedAvatarIndex: number,
    createdAt: string,
    updatedAt: string,
    gameSessionTeamsId?: string | null,
    teamQuestionId?: string | null,
    teamQuestionOrder?: number | null,
    teamQuestionGameSessionId?: string | null,
  } | null,
};

export type OnCreateGameTemplateSubscriptionVariables = {
  filter?: ModelSubscriptionGameTemplateFilterInput | null,
};

export type OnCreateGameTemplateSubscription = {
  onCreateGameTemplate?:  {
    __typename: "GameTemplate",
    id: string,
    title: string,
    owner: string,
    version: number,
    description: string,
    domain?: string | null,
    cluster?: string | null,
    grade?: string | null,
    standard?: string | null,
    phaseOneTime?: number | null,
    phaseTwoTime?: number | null,
    imageUrl?: string | null,
    questionTemplates?:  {
      __typename: "ModelGameQuestionsConnection",
      items:  Array< {
        __typename: "GameQuestions",
        id: string,
        gameTemplateID: string,
        questionTemplateID: string,
        gameTemplate:  {
          __typename: "GameTemplate",
          id: string,
          title: string,
          owner: string,
          version: number,
          description: string,
          domain?: string | null,
          cluster?: string | null,
          grade?: string | null,
          standard?: string | null,
          phaseOneTime?: number | null,
          phaseTwoTime?: number | null,
          imageUrl?: string | null,
          questionTemplates?:  {
            __typename: "ModelGameQuestionsConnection",
            items:  Array< {
              __typename: "GameQuestions",
              id: string,
              gameTemplateID: string,
              questionTemplateID: string,
              gameTemplate:  {
                __typename: "GameTemplate",
                id: string,
                title: string,
                owner: string,
                version: number,
                description: string,
                domain?: string | null,
                cluster?: string | null,
                grade?: string | null,
                standard?: string | null,
                phaseOneTime?: number | null,
                phaseTwoTime?: number | null,
                imageUrl?: string | null,
                questionTemplates?:  {
                  __typename: "ModelGameQuestionsConnection",
                  nextToken?: string | null,
                } | null,
                questionTemplatesCount: number,
                questionTemplatesOrder?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
                type?: string | null,
              },
              questionTemplate:  {
                __typename: "QuestionTemplate",
                id: string,
                title: string,
                owner: string,
                version: number,
                choices?: string | null,
                instructions?: string | null,
                answerSettings?: string | null,
                domain?: string | null,
                cluster?: string | null,
                grade?: string | null,
                standard?: string | null,
                imageUrl?: string | null,
                gameTemplates?:  {
                  __typename: "ModelGameQuestionsConnection",
                  nextToken?: string | null,
                } | null,
                gameTemplatesCount: number,
                createdAt?: string | null,
                updatedAt?: string | null,
                type?: string | null,
              },
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          questionTemplatesCount: number,
          questionTemplatesOrder?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          type?: string | null,
        },
        questionTemplate:  {
          __typename: "QuestionTemplate",
          id: string,
          title: string,
          owner: string,
          version: number,
          choices?: string | null,
          instructions?: string | null,
          answerSettings?: string | null,
          domain?: string | null,
          cluster?: string | null,
          grade?: string | null,
          standard?: string | null,
          imageUrl?: string | null,
          gameTemplates?:  {
            __typename: "ModelGameQuestionsConnection",
            items:  Array< {
              __typename: "GameQuestions",
              id: string,
              gameTemplateID: string,
              questionTemplateID: string,
              gameTemplate:  {
                __typename: "GameTemplate",
                id: string,
                title: string,
                owner: string,
                version: number,
                description: string,
                domain?: string | null,
                cluster?: string | null,
                grade?: string | null,
                standard?: string | null,
                phaseOneTime?: number | null,
                phaseTwoTime?: number | null,
                imageUrl?: string | null,
                questionTemplates?:  {
                  __typename: "ModelGameQuestionsConnection",
                  nextToken?: string | null,
                } | null,
                questionTemplatesCount: number,
                questionTemplatesOrder?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
                type?: string | null,
              },
              questionTemplate:  {
                __typename: "QuestionTemplate",
                id: string,
                title: string,
                owner: string,
                version: number,
                choices?: string | null,
                instructions?: string | null,
                answerSettings?: string | null,
                domain?: string | null,
                cluster?: string | null,
                grade?: string | null,
                standard?: string | null,
                imageUrl?: string | null,
                gameTemplates?:  {
                  __typename: "ModelGameQuestionsConnection",
                  nextToken?: string | null,
                } | null,
                gameTemplatesCount: number,
                createdAt?: string | null,
                updatedAt?: string | null,
                type?: string | null,
              },
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          gameTemplatesCount: number,
          createdAt?: string | null,
          updatedAt?: string | null,
          type?: string | null,
        },
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    questionTemplatesCount: number,
    questionTemplatesOrder?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    type?: string | null,
  } | null,
};

export type OnUpdateGameTemplateSubscriptionVariables = {
  filter?: ModelSubscriptionGameTemplateFilterInput | null,
};

export type OnUpdateGameTemplateSubscription = {
  onUpdateGameTemplate?:  {
    __typename: "GameTemplate",
    id: string,
    title: string,
    owner: string,
    version: number,
    description: string,
    domain?: string | null,
    cluster?: string | null,
    grade?: string | null,
    standard?: string | null,
    phaseOneTime?: number | null,
    phaseTwoTime?: number | null,
    imageUrl?: string | null,
    questionTemplates?:  {
      __typename: "ModelGameQuestionsConnection",
      items:  Array< {
        __typename: "GameQuestions",
        id: string,
        gameTemplateID: string,
        questionTemplateID: string,
        gameTemplate:  {
          __typename: "GameTemplate",
          id: string,
          title: string,
          owner: string,
          version: number,
          description: string,
          domain?: string | null,
          cluster?: string | null,
          grade?: string | null,
          standard?: string | null,
          phaseOneTime?: number | null,
          phaseTwoTime?: number | null,
          imageUrl?: string | null,
          questionTemplates?:  {
            __typename: "ModelGameQuestionsConnection",
            items:  Array< {
              __typename: "GameQuestions",
              id: string,
              gameTemplateID: string,
              questionTemplateID: string,
              gameTemplate:  {
                __typename: "GameTemplate",
                id: string,
                title: string,
                owner: string,
                version: number,
                description: string,
                domain?: string | null,
                cluster?: string | null,
                grade?: string | null,
                standard?: string | null,
                phaseOneTime?: number | null,
                phaseTwoTime?: number | null,
                imageUrl?: string | null,
                questionTemplates?:  {
                  __typename: "ModelGameQuestionsConnection",
                  nextToken?: string | null,
                } | null,
                questionTemplatesCount: number,
                questionTemplatesOrder?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
                type?: string | null,
              },
              questionTemplate:  {
                __typename: "QuestionTemplate",
                id: string,
                title: string,
                owner: string,
                version: number,
                choices?: string | null,
                instructions?: string | null,
                answerSettings?: string | null,
                domain?: string | null,
                cluster?: string | null,
                grade?: string | null,
                standard?: string | null,
                imageUrl?: string | null,
                gameTemplates?:  {
                  __typename: "ModelGameQuestionsConnection",
                  nextToken?: string | null,
                } | null,
                gameTemplatesCount: number,
                createdAt?: string | null,
                updatedAt?: string | null,
                type?: string | null,
              },
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          questionTemplatesCount: number,
          questionTemplatesOrder?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          type?: string | null,
        },
        questionTemplate:  {
          __typename: "QuestionTemplate",
          id: string,
          title: string,
          owner: string,
          version: number,
          choices?: string | null,
          instructions?: string | null,
          answerSettings?: string | null,
          domain?: string | null,
          cluster?: string | null,
          grade?: string | null,
          standard?: string | null,
          imageUrl?: string | null,
          gameTemplates?:  {
            __typename: "ModelGameQuestionsConnection",
            items:  Array< {
              __typename: "GameQuestions",
              id: string,
              gameTemplateID: string,
              questionTemplateID: string,
              gameTemplate:  {
                __typename: "GameTemplate",
                id: string,
                title: string,
                owner: string,
                version: number,
                description: string,
                domain?: string | null,
                cluster?: string | null,
                grade?: string | null,
                standard?: string | null,
                phaseOneTime?: number | null,
                phaseTwoTime?: number | null,
                imageUrl?: string | null,
                questionTemplates?:  {
                  __typename: "ModelGameQuestionsConnection",
                  nextToken?: string | null,
                } | null,
                questionTemplatesCount: number,
                questionTemplatesOrder?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
                type?: string | null,
              },
              questionTemplate:  {
                __typename: "QuestionTemplate",
                id: string,
                title: string,
                owner: string,
                version: number,
                choices?: string | null,
                instructions?: string | null,
                answerSettings?: string | null,
                domain?: string | null,
                cluster?: string | null,
                grade?: string | null,
                standard?: string | null,
                imageUrl?: string | null,
                gameTemplates?:  {
                  __typename: "ModelGameQuestionsConnection",
                  nextToken?: string | null,
                } | null,
                gameTemplatesCount: number,
                createdAt?: string | null,
                updatedAt?: string | null,
                type?: string | null,
              },
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          gameTemplatesCount: number,
          createdAt?: string | null,
          updatedAt?: string | null,
          type?: string | null,
        },
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    questionTemplatesCount: number,
    questionTemplatesOrder?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    type?: string | null,
  } | null,
};

export type OnDeleteGameTemplateSubscriptionVariables = {
  filter?: ModelSubscriptionGameTemplateFilterInput | null,
};

export type OnDeleteGameTemplateSubscription = {
  onDeleteGameTemplate?:  {
    __typename: "GameTemplate",
    id: string,
    title: string,
    owner: string,
    version: number,
    description: string,
    domain?: string | null,
    cluster?: string | null,
    grade?: string | null,
    standard?: string | null,
    phaseOneTime?: number | null,
    phaseTwoTime?: number | null,
    imageUrl?: string | null,
    questionTemplates?:  {
      __typename: "ModelGameQuestionsConnection",
      items:  Array< {
        __typename: "GameQuestions",
        id: string,
        gameTemplateID: string,
        questionTemplateID: string,
        gameTemplate:  {
          __typename: "GameTemplate",
          id: string,
          title: string,
          owner: string,
          version: number,
          description: string,
          domain?: string | null,
          cluster?: string | null,
          grade?: string | null,
          standard?: string | null,
          phaseOneTime?: number | null,
          phaseTwoTime?: number | null,
          imageUrl?: string | null,
          questionTemplates?:  {
            __typename: "ModelGameQuestionsConnection",
            items:  Array< {
              __typename: "GameQuestions",
              id: string,
              gameTemplateID: string,
              questionTemplateID: string,
              gameTemplate:  {
                __typename: "GameTemplate",
                id: string,
                title: string,
                owner: string,
                version: number,
                description: string,
                domain?: string | null,
                cluster?: string | null,
                grade?: string | null,
                standard?: string | null,
                phaseOneTime?: number | null,
                phaseTwoTime?: number | null,
                imageUrl?: string | null,
                questionTemplates?:  {
                  __typename: "ModelGameQuestionsConnection",
                  nextToken?: string | null,
                } | null,
                questionTemplatesCount: number,
                questionTemplatesOrder?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
                type?: string | null,
              },
              questionTemplate:  {
                __typename: "QuestionTemplate",
                id: string,
                title: string,
                owner: string,
                version: number,
                choices?: string | null,
                instructions?: string | null,
                answerSettings?: string | null,
                domain?: string | null,
                cluster?: string | null,
                grade?: string | null,
                standard?: string | null,
                imageUrl?: string | null,
                gameTemplates?:  {
                  __typename: "ModelGameQuestionsConnection",
                  nextToken?: string | null,
                } | null,
                gameTemplatesCount: number,
                createdAt?: string | null,
                updatedAt?: string | null,
                type?: string | null,
              },
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          questionTemplatesCount: number,
          questionTemplatesOrder?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          type?: string | null,
        },
        questionTemplate:  {
          __typename: "QuestionTemplate",
          id: string,
          title: string,
          owner: string,
          version: number,
          choices?: string | null,
          instructions?: string | null,
          answerSettings?: string | null,
          domain?: string | null,
          cluster?: string | null,
          grade?: string | null,
          standard?: string | null,
          imageUrl?: string | null,
          gameTemplates?:  {
            __typename: "ModelGameQuestionsConnection",
            items:  Array< {
              __typename: "GameQuestions",
              id: string,
              gameTemplateID: string,
              questionTemplateID: string,
              gameTemplate:  {
                __typename: "GameTemplate",
                id: string,
                title: string,
                owner: string,
                version: number,
                description: string,
                domain?: string | null,
                cluster?: string | null,
                grade?: string | null,
                standard?: string | null,
                phaseOneTime?: number | null,
                phaseTwoTime?: number | null,
                imageUrl?: string | null,
                questionTemplates?:  {
                  __typename: "ModelGameQuestionsConnection",
                  nextToken?: string | null,
                } | null,
                questionTemplatesCount: number,
                questionTemplatesOrder?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
                type?: string | null,
              },
              questionTemplate:  {
                __typename: "QuestionTemplate",
                id: string,
                title: string,
                owner: string,
                version: number,
                choices?: string | null,
                instructions?: string | null,
                answerSettings?: string | null,
                domain?: string | null,
                cluster?: string | null,
                grade?: string | null,
                standard?: string | null,
                imageUrl?: string | null,
                gameTemplates?:  {
                  __typename: "ModelGameQuestionsConnection",
                  nextToken?: string | null,
                } | null,
                gameTemplatesCount: number,
                createdAt?: string | null,
                updatedAt?: string | null,
                type?: string | null,
              },
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          gameTemplatesCount: number,
          createdAt?: string | null,
          updatedAt?: string | null,
          type?: string | null,
        },
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    questionTemplatesCount: number,
    questionTemplatesOrder?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    type?: string | null,
  } | null,
};

export type OnCreateQuestionTemplateSubscriptionVariables = {
  filter?: ModelSubscriptionQuestionTemplateFilterInput | null,
};

export type OnCreateQuestionTemplateSubscription = {
  onCreateQuestionTemplate?:  {
    __typename: "QuestionTemplate",
    id: string,
    title: string,
    owner: string,
    version: number,
    choices?: string | null,
    instructions?: string | null,
    answerSettings?: string | null,
    domain?: string | null,
    cluster?: string | null,
    grade?: string | null,
    standard?: string | null,
    imageUrl?: string | null,
    gameTemplates?:  {
      __typename: "ModelGameQuestionsConnection",
      items:  Array< {
        __typename: "GameQuestions",
        id: string,
        gameTemplateID: string,
        questionTemplateID: string,
        gameTemplate:  {
          __typename: "GameTemplate",
          id: string,
          title: string,
          owner: string,
          version: number,
          description: string,
          domain?: string | null,
          cluster?: string | null,
          grade?: string | null,
          standard?: string | null,
          phaseOneTime?: number | null,
          phaseTwoTime?: number | null,
          imageUrl?: string | null,
          questionTemplates?:  {
            __typename: "ModelGameQuestionsConnection",
            items:  Array< {
              __typename: "GameQuestions",
              id: string,
              gameTemplateID: string,
              questionTemplateID: string,
              gameTemplate:  {
                __typename: "GameTemplate",
                id: string,
                title: string,
                owner: string,
                version: number,
                description: string,
                domain?: string | null,
                cluster?: string | null,
                grade?: string | null,
                standard?: string | null,
                phaseOneTime?: number | null,
                phaseTwoTime?: number | null,
                imageUrl?: string | null,
                questionTemplates?:  {
                  __typename: "ModelGameQuestionsConnection",
                  nextToken?: string | null,
                } | null,
                questionTemplatesCount: number,
                questionTemplatesOrder?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
                type?: string | null,
              },
              questionTemplate:  {
                __typename: "QuestionTemplate",
                id: string,
                title: string,
                owner: string,
                version: number,
                choices?: string | null,
                instructions?: string | null,
                answerSettings?: string | null,
                domain?: string | null,
                cluster?: string | null,
                grade?: string | null,
                standard?: string | null,
                imageUrl?: string | null,
                gameTemplates?:  {
                  __typename: "ModelGameQuestionsConnection",
                  nextToken?: string | null,
                } | null,
                gameTemplatesCount: number,
                createdAt?: string | null,
                updatedAt?: string | null,
                type?: string | null,
              },
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          questionTemplatesCount: number,
          questionTemplatesOrder?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          type?: string | null,
        },
        questionTemplate:  {
          __typename: "QuestionTemplate",
          id: string,
          title: string,
          owner: string,
          version: number,
          choices?: string | null,
          instructions?: string | null,
          answerSettings?: string | null,
          domain?: string | null,
          cluster?: string | null,
          grade?: string | null,
          standard?: string | null,
          imageUrl?: string | null,
          gameTemplates?:  {
            __typename: "ModelGameQuestionsConnection",
            items:  Array< {
              __typename: "GameQuestions",
              id: string,
              gameTemplateID: string,
              questionTemplateID: string,
              gameTemplate:  {
                __typename: "GameTemplate",
                id: string,
                title: string,
                owner: string,
                version: number,
                description: string,
                domain?: string | null,
                cluster?: string | null,
                grade?: string | null,
                standard?: string | null,
                phaseOneTime?: number | null,
                phaseTwoTime?: number | null,
                imageUrl?: string | null,
                questionTemplates?:  {
                  __typename: "ModelGameQuestionsConnection",
                  nextToken?: string | null,
                } | null,
                questionTemplatesCount: number,
                questionTemplatesOrder?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
                type?: string | null,
              },
              questionTemplate:  {
                __typename: "QuestionTemplate",
                id: string,
                title: string,
                owner: string,
                version: number,
                choices?: string | null,
                instructions?: string | null,
                answerSettings?: string | null,
                domain?: string | null,
                cluster?: string | null,
                grade?: string | null,
                standard?: string | null,
                imageUrl?: string | null,
                gameTemplates?:  {
                  __typename: "ModelGameQuestionsConnection",
                  nextToken?: string | null,
                } | null,
                gameTemplatesCount: number,
                createdAt?: string | null,
                updatedAt?: string | null,
                type?: string | null,
              },
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          gameTemplatesCount: number,
          createdAt?: string | null,
          updatedAt?: string | null,
          type?: string | null,
        },
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    gameTemplatesCount: number,
    createdAt?: string | null,
    updatedAt?: string | null,
    type?: string | null,
  } | null,
};

export type OnUpdateQuestionTemplateSubscriptionVariables = {
  filter?: ModelSubscriptionQuestionTemplateFilterInput | null,
};

export type OnUpdateQuestionTemplateSubscription = {
  onUpdateQuestionTemplate?:  {
    __typename: "QuestionTemplate",
    id: string,
    title: string,
    owner: string,
    version: number,
    choices?: string | null,
    instructions?: string | null,
    answerSettings?: string | null,
    domain?: string | null,
    cluster?: string | null,
    grade?: string | null,
    standard?: string | null,
    imageUrl?: string | null,
    gameTemplates?:  {
      __typename: "ModelGameQuestionsConnection",
      items:  Array< {
        __typename: "GameQuestions",
        id: string,
        gameTemplateID: string,
        questionTemplateID: string,
        gameTemplate:  {
          __typename: "GameTemplate",
          id: string,
          title: string,
          owner: string,
          version: number,
          description: string,
          domain?: string | null,
          cluster?: string | null,
          grade?: string | null,
          standard?: string | null,
          phaseOneTime?: number | null,
          phaseTwoTime?: number | null,
          imageUrl?: string | null,
          questionTemplates?:  {
            __typename: "ModelGameQuestionsConnection",
            items:  Array< {
              __typename: "GameQuestions",
              id: string,
              gameTemplateID: string,
              questionTemplateID: string,
              gameTemplate:  {
                __typename: "GameTemplate",
                id: string,
                title: string,
                owner: string,
                version: number,
                description: string,
                domain?: string | null,
                cluster?: string | null,
                grade?: string | null,
                standard?: string | null,
                phaseOneTime?: number | null,
                phaseTwoTime?: number | null,
                imageUrl?: string | null,
                questionTemplates?:  {
                  __typename: "ModelGameQuestionsConnection",
                  nextToken?: string | null,
                } | null,
                questionTemplatesCount: number,
                questionTemplatesOrder?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
                type?: string | null,
              },
              questionTemplate:  {
                __typename: "QuestionTemplate",
                id: string,
                title: string,
                owner: string,
                version: number,
                choices?: string | null,
                instructions?: string | null,
                answerSettings?: string | null,
                domain?: string | null,
                cluster?: string | null,
                grade?: string | null,
                standard?: string | null,
                imageUrl?: string | null,
                gameTemplates?:  {
                  __typename: "ModelGameQuestionsConnection",
                  nextToken?: string | null,
                } | null,
                gameTemplatesCount: number,
                createdAt?: string | null,
                updatedAt?: string | null,
                type?: string | null,
              },
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          questionTemplatesCount: number,
          questionTemplatesOrder?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          type?: string | null,
        },
        questionTemplate:  {
          __typename: "QuestionTemplate",
          id: string,
          title: string,
          owner: string,
          version: number,
          choices?: string | null,
          instructions?: string | null,
          answerSettings?: string | null,
          domain?: string | null,
          cluster?: string | null,
          grade?: string | null,
          standard?: string | null,
          imageUrl?: string | null,
          gameTemplates?:  {
            __typename: "ModelGameQuestionsConnection",
            items:  Array< {
              __typename: "GameQuestions",
              id: string,
              gameTemplateID: string,
              questionTemplateID: string,
              gameTemplate:  {
                __typename: "GameTemplate",
                id: string,
                title: string,
                owner: string,
                version: number,
                description: string,
                domain?: string | null,
                cluster?: string | null,
                grade?: string | null,
                standard?: string | null,
                phaseOneTime?: number | null,
                phaseTwoTime?: number | null,
                imageUrl?: string | null,
                questionTemplates?:  {
                  __typename: "ModelGameQuestionsConnection",
                  nextToken?: string | null,
                } | null,
                questionTemplatesCount: number,
                questionTemplatesOrder?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
                type?: string | null,
              },
              questionTemplate:  {
                __typename: "QuestionTemplate",
                id: string,
                title: string,
                owner: string,
                version: number,
                choices?: string | null,
                instructions?: string | null,
                answerSettings?: string | null,
                domain?: string | null,
                cluster?: string | null,
                grade?: string | null,
                standard?: string | null,
                imageUrl?: string | null,
                gameTemplates?:  {
                  __typename: "ModelGameQuestionsConnection",
                  nextToken?: string | null,
                } | null,
                gameTemplatesCount: number,
                createdAt?: string | null,
                updatedAt?: string | null,
                type?: string | null,
              },
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          gameTemplatesCount: number,
          createdAt?: string | null,
          updatedAt?: string | null,
          type?: string | null,
        },
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    gameTemplatesCount: number,
    createdAt?: string | null,
    updatedAt?: string | null,
    type?: string | null,
  } | null,
};

export type OnDeleteQuestionTemplateSubscriptionVariables = {
  filter?: ModelSubscriptionQuestionTemplateFilterInput | null,
};

export type OnDeleteQuestionTemplateSubscription = {
  onDeleteQuestionTemplate?:  {
    __typename: "QuestionTemplate",
    id: string,
    title: string,
    owner: string,
    version: number,
    choices?: string | null,
    instructions?: string | null,
    answerSettings?: string | null,
    domain?: string | null,
    cluster?: string | null,
    grade?: string | null,
    standard?: string | null,
    imageUrl?: string | null,
    gameTemplates?:  {
      __typename: "ModelGameQuestionsConnection",
      items:  Array< {
        __typename: "GameQuestions",
        id: string,
        gameTemplateID: string,
        questionTemplateID: string,
        gameTemplate:  {
          __typename: "GameTemplate",
          id: string,
          title: string,
          owner: string,
          version: number,
          description: string,
          domain?: string | null,
          cluster?: string | null,
          grade?: string | null,
          standard?: string | null,
          phaseOneTime?: number | null,
          phaseTwoTime?: number | null,
          imageUrl?: string | null,
          questionTemplates?:  {
            __typename: "ModelGameQuestionsConnection",
            items:  Array< {
              __typename: "GameQuestions",
              id: string,
              gameTemplateID: string,
              questionTemplateID: string,
              gameTemplate:  {
                __typename: "GameTemplate",
                id: string,
                title: string,
                owner: string,
                version: number,
                description: string,
                domain?: string | null,
                cluster?: string | null,
                grade?: string | null,
                standard?: string | null,
                phaseOneTime?: number | null,
                phaseTwoTime?: number | null,
                imageUrl?: string | null,
                questionTemplates?:  {
                  __typename: "ModelGameQuestionsConnection",
                  nextToken?: string | null,
                } | null,
                questionTemplatesCount: number,
                questionTemplatesOrder?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
                type?: string | null,
              },
              questionTemplate:  {
                __typename: "QuestionTemplate",
                id: string,
                title: string,
                owner: string,
                version: number,
                choices?: string | null,
                instructions?: string | null,
                answerSettings?: string | null,
                domain?: string | null,
                cluster?: string | null,
                grade?: string | null,
                standard?: string | null,
                imageUrl?: string | null,
                gameTemplates?:  {
                  __typename: "ModelGameQuestionsConnection",
                  nextToken?: string | null,
                } | null,
                gameTemplatesCount: number,
                createdAt?: string | null,
                updatedAt?: string | null,
                type?: string | null,
              },
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          questionTemplatesCount: number,
          questionTemplatesOrder?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          type?: string | null,
        },
        questionTemplate:  {
          __typename: "QuestionTemplate",
          id: string,
          title: string,
          owner: string,
          version: number,
          choices?: string | null,
          instructions?: string | null,
          answerSettings?: string | null,
          domain?: string | null,
          cluster?: string | null,
          grade?: string | null,
          standard?: string | null,
          imageUrl?: string | null,
          gameTemplates?:  {
            __typename: "ModelGameQuestionsConnection",
            items:  Array< {
              __typename: "GameQuestions",
              id: string,
              gameTemplateID: string,
              questionTemplateID: string,
              gameTemplate:  {
                __typename: "GameTemplate",
                id: string,
                title: string,
                owner: string,
                version: number,
                description: string,
                domain?: string | null,
                cluster?: string | null,
                grade?: string | null,
                standard?: string | null,
                phaseOneTime?: number | null,
                phaseTwoTime?: number | null,
                imageUrl?: string | null,
                questionTemplates?:  {
                  __typename: "ModelGameQuestionsConnection",
                  nextToken?: string | null,
                } | null,
                questionTemplatesCount: number,
                questionTemplatesOrder?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
                type?: string | null,
              },
              questionTemplate:  {
                __typename: "QuestionTemplate",
                id: string,
                title: string,
                owner: string,
                version: number,
                choices?: string | null,
                instructions?: string | null,
                answerSettings?: string | null,
                domain?: string | null,
                cluster?: string | null,
                grade?: string | null,
                standard?: string | null,
                imageUrl?: string | null,
                gameTemplates?:  {
                  __typename: "ModelGameQuestionsConnection",
                  nextToken?: string | null,
                } | null,
                gameTemplatesCount: number,
                createdAt?: string | null,
                updatedAt?: string | null,
                type?: string | null,
              },
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          gameTemplatesCount: number,
          createdAt?: string | null,
          updatedAt?: string | null,
          type?: string | null,
        },
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    gameTemplatesCount: number,
    createdAt?: string | null,
    updatedAt?: string | null,
    type?: string | null,
  } | null,
};

export type OnCreateGameSessionSubscriptionVariables = {
  filter?: ModelSubscriptionGameSessionFilterInput | null,
};

export type OnCreateGameSessionSubscription = {
  onCreateGameSession?:  {
    __typename: "GameSession",
    id: string,
    gameId: string,
    startTime?: string | null,
    phaseOneTime: number,
    phaseTwoTime: number,
    teams?:  {
      __typename: "ModelTeamConnection",
      items:  Array< {
        __typename: "Team",
        id: string,
        name: string,
        question?:  {
          __typename: "Question",
          id: string,
          text: string,
          choices?: string | null,
          answerSettings?: string | null,
          responses?: string | null,
          hints?: string | null,
          imageUrl?: string | null,
          instructions?: string | null,
          standard?: string | null,
          cluster?: string | null,
          domain?: string | null,
          grade?: string | null,
          order: number,
          isConfidenceEnabled: boolean,
          isShortAnswerEnabled: boolean,
          isHintEnabled: boolean,
          gameSessionId: string,
        } | null,
        teamMembers?:  {
          __typename: "ModelTeamMemberConnection",
          items:  Array< {
            __typename: "TeamMember",
            id: string,
            isFacilitator?: boolean | null,
            answers?:  {
              __typename: "ModelTeamAnswerConnection",
              items:  Array< {
                __typename: "TeamAnswer",
                id: string,
                isCorrect?: boolean | null,
                isSubmitted: boolean,
                isShortAnswerEnabled: boolean,
                currentState: GameSessionState,
                currentQuestionIndex: number,
                questionId: string,
                teamMemberAnswersId: string,
                teamAnswersId?: string | null,
                teamName?: string | null,
                text: string,
                answer: string,
                confidenceLevel?: ConfidenceLevel | null,
                hint?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            deviceId: string,
            createdAt: string,
            updatedAt: string,
            teamTeamMembersId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        score: number,
        selectedAvatarIndex: number,
        createdAt: string,
        updatedAt: string,
        gameSessionTeamsId?: string | null,
        teamQuestionId?: string | null,
        teamQuestionOrder?: number | null,
        teamQuestionGameSessionId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    currentQuestionIndex?: number | null,
    currentState: GameSessionState,
    gameCode: number,
    isAdvancedMode: boolean,
    imageUrl?: string | null,
    description?: string | null,
    title?: string | null,
    currentTimer?: number | null,
    questions?:  {
      __typename: "ModelQuestionConnection",
      items:  Array< {
        __typename: "Question",
        id: string,
        text: string,
        choices?: string | null,
        answerSettings?: string | null,
        responses?: string | null,
        hints?: string | null,
        imageUrl?: string | null,
        instructions?: string | null,
        standard?: string | null,
        cluster?: string | null,
        domain?: string | null,
        grade?: string | null,
        order: number,
        isConfidenceEnabled: boolean,
        isShortAnswerEnabled: boolean,
        isHintEnabled: boolean,
        gameSessionId: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateGameSessionSubscriptionVariables = {
  filter?: ModelSubscriptionGameSessionFilterInput | null,
};

export type OnUpdateGameSessionSubscription = {
  onUpdateGameSession?:  {
    __typename: "GameSession",
    id: string,
    gameId: string,
    startTime?: string | null,
    phaseOneTime: number,
    phaseTwoTime: number,
    teams?:  {
      __typename: "ModelTeamConnection",
      items:  Array< {
        __typename: "Team",
        id: string,
        name: string,
        question?:  {
          __typename: "Question",
          id: string,
          text: string,
          choices?: string | null,
          answerSettings?: string | null,
          responses?: string | null,
          hints?: string | null,
          imageUrl?: string | null,
          instructions?: string | null,
          standard?: string | null,
          cluster?: string | null,
          domain?: string | null,
          grade?: string | null,
          order: number,
          isConfidenceEnabled: boolean,
          isShortAnswerEnabled: boolean,
          isHintEnabled: boolean,
          gameSessionId: string,
        } | null,
        teamMembers?:  {
          __typename: "ModelTeamMemberConnection",
          items:  Array< {
            __typename: "TeamMember",
            id: string,
            isFacilitator?: boolean | null,
            answers?:  {
              __typename: "ModelTeamAnswerConnection",
              items:  Array< {
                __typename: "TeamAnswer",
                id: string,
                isCorrect?: boolean | null,
                isSubmitted: boolean,
                isShortAnswerEnabled: boolean,
                currentState: GameSessionState,
                currentQuestionIndex: number,
                questionId: string,
                teamMemberAnswersId: string,
                teamAnswersId?: string | null,
                teamName?: string | null,
                text: string,
                answer: string,
                confidenceLevel?: ConfidenceLevel | null,
                hint?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            deviceId: string,
            createdAt: string,
            updatedAt: string,
            teamTeamMembersId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        score: number,
        selectedAvatarIndex: number,
        createdAt: string,
        updatedAt: string,
        gameSessionTeamsId?: string | null,
        teamQuestionId?: string | null,
        teamQuestionOrder?: number | null,
        teamQuestionGameSessionId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    currentQuestionIndex?: number | null,
    currentState: GameSessionState,
    gameCode: number,
    isAdvancedMode: boolean,
    imageUrl?: string | null,
    description?: string | null,
    title?: string | null,
    currentTimer?: number | null,
    questions?:  {
      __typename: "ModelQuestionConnection",
      items:  Array< {
        __typename: "Question",
        id: string,
        text: string,
        choices?: string | null,
        answerSettings?: string | null,
        responses?: string | null,
        hints?: string | null,
        imageUrl?: string | null,
        instructions?: string | null,
        standard?: string | null,
        cluster?: string | null,
        domain?: string | null,
        grade?: string | null,
        order: number,
        isConfidenceEnabled: boolean,
        isShortAnswerEnabled: boolean,
        isHintEnabled: boolean,
        gameSessionId: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteGameSessionSubscriptionVariables = {
  filter?: ModelSubscriptionGameSessionFilterInput | null,
};

export type OnDeleteGameSessionSubscription = {
  onDeleteGameSession?:  {
    __typename: "GameSession",
    id: string,
    gameId: string,
    startTime?: string | null,
    phaseOneTime: number,
    phaseTwoTime: number,
    teams?:  {
      __typename: "ModelTeamConnection",
      items:  Array< {
        __typename: "Team",
        id: string,
        name: string,
        question?:  {
          __typename: "Question",
          id: string,
          text: string,
          choices?: string | null,
          answerSettings?: string | null,
          responses?: string | null,
          hints?: string | null,
          imageUrl?: string | null,
          instructions?: string | null,
          standard?: string | null,
          cluster?: string | null,
          domain?: string | null,
          grade?: string | null,
          order: number,
          isConfidenceEnabled: boolean,
          isShortAnswerEnabled: boolean,
          isHintEnabled: boolean,
          gameSessionId: string,
        } | null,
        teamMembers?:  {
          __typename: "ModelTeamMemberConnection",
          items:  Array< {
            __typename: "TeamMember",
            id: string,
            isFacilitator?: boolean | null,
            answers?:  {
              __typename: "ModelTeamAnswerConnection",
              items:  Array< {
                __typename: "TeamAnswer",
                id: string,
                isCorrect?: boolean | null,
                isSubmitted: boolean,
                isShortAnswerEnabled: boolean,
                currentState: GameSessionState,
                currentQuestionIndex: number,
                questionId: string,
                teamMemberAnswersId: string,
                teamAnswersId?: string | null,
                teamName?: string | null,
                text: string,
                answer: string,
                confidenceLevel?: ConfidenceLevel | null,
                hint?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            deviceId: string,
            createdAt: string,
            updatedAt: string,
            teamTeamMembersId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        score: number,
        selectedAvatarIndex: number,
        createdAt: string,
        updatedAt: string,
        gameSessionTeamsId?: string | null,
        teamQuestionId?: string | null,
        teamQuestionOrder?: number | null,
        teamQuestionGameSessionId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    currentQuestionIndex?: number | null,
    currentState: GameSessionState,
    gameCode: number,
    isAdvancedMode: boolean,
    imageUrl?: string | null,
    description?: string | null,
    title?: string | null,
    currentTimer?: number | null,
    questions?:  {
      __typename: "ModelQuestionConnection",
      items:  Array< {
        __typename: "Question",
        id: string,
        text: string,
        choices?: string | null,
        answerSettings?: string | null,
        responses?: string | null,
        hints?: string | null,
        imageUrl?: string | null,
        instructions?: string | null,
        standard?: string | null,
        cluster?: string | null,
        domain?: string | null,
        grade?: string | null,
        order: number,
        isConfidenceEnabled: boolean,
        isShortAnswerEnabled: boolean,
        isHintEnabled: boolean,
        gameSessionId: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateTeamSubscriptionVariables = {
  filter?: ModelSubscriptionTeamFilterInput | null,
};

export type OnCreateTeamSubscription = {
  onCreateTeam?:  {
    __typename: "Team",
    id: string,
    name: string,
    question?:  {
      __typename: "Question",
      id: string,
      text: string,
      choices?: string | null,
      answerSettings?: string | null,
      responses?: string | null,
      hints?: string | null,
      imageUrl?: string | null,
      instructions?: string | null,
      standard?: string | null,
      cluster?: string | null,
      domain?: string | null,
      grade?: string | null,
      order: number,
      isConfidenceEnabled: boolean,
      isShortAnswerEnabled: boolean,
      isHintEnabled: boolean,
      gameSessionId: string,
    } | null,
    teamMembers?:  {
      __typename: "ModelTeamMemberConnection",
      items:  Array< {
        __typename: "TeamMember",
        id: string,
        isFacilitator?: boolean | null,
        answers?:  {
          __typename: "ModelTeamAnswerConnection",
          items:  Array< {
            __typename: "TeamAnswer",
            id: string,
            isCorrect?: boolean | null,
            isSubmitted: boolean,
            isShortAnswerEnabled: boolean,
            currentState: GameSessionState,
            currentQuestionIndex: number,
            questionId: string,
            teamMemberAnswersId: string,
            teamAnswersId?: string | null,
            teamName?: string | null,
            text: string,
            answer: string,
            confidenceLevel?: ConfidenceLevel | null,
            hint?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        deviceId: string,
        createdAt: string,
        updatedAt: string,
        teamTeamMembersId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    score: number,
    selectedAvatarIndex: number,
    createdAt: string,
    updatedAt: string,
    gameSessionTeamsId?: string | null,
    teamQuestionId?: string | null,
    teamQuestionOrder?: number | null,
    teamQuestionGameSessionId?: string | null,
  } | null,
};

export type OnUpdateTeamSubscriptionVariables = {
  filter?: ModelSubscriptionTeamFilterInput | null,
};

export type OnUpdateTeamSubscription = {
  onUpdateTeam?:  {
    __typename: "Team",
    id: string,
    name: string,
    question?:  {
      __typename: "Question",
      id: string,
      text: string,
      choices?: string | null,
      answerSettings?: string | null,
      responses?: string | null,
      hints?: string | null,
      imageUrl?: string | null,
      instructions?: string | null,
      standard?: string | null,
      cluster?: string | null,
      domain?: string | null,
      grade?: string | null,
      order: number,
      isConfidenceEnabled: boolean,
      isShortAnswerEnabled: boolean,
      isHintEnabled: boolean,
      gameSessionId: string,
    } | null,
    teamMembers?:  {
      __typename: "ModelTeamMemberConnection",
      items:  Array< {
        __typename: "TeamMember",
        id: string,
        isFacilitator?: boolean | null,
        answers?:  {
          __typename: "ModelTeamAnswerConnection",
          items:  Array< {
            __typename: "TeamAnswer",
            id: string,
            isCorrect?: boolean | null,
            isSubmitted: boolean,
            isShortAnswerEnabled: boolean,
            currentState: GameSessionState,
            currentQuestionIndex: number,
            questionId: string,
            teamMemberAnswersId: string,
            teamAnswersId?: string | null,
            teamName?: string | null,
            text: string,
            answer: string,
            confidenceLevel?: ConfidenceLevel | null,
            hint?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        deviceId: string,
        createdAt: string,
        updatedAt: string,
        teamTeamMembersId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    score: number,
    selectedAvatarIndex: number,
    createdAt: string,
    updatedAt: string,
    gameSessionTeamsId?: string | null,
    teamQuestionId?: string | null,
    teamQuestionOrder?: number | null,
    teamQuestionGameSessionId?: string | null,
  } | null,
};

export type OnDeleteTeamSubscriptionVariables = {
  filter?: ModelSubscriptionTeamFilterInput | null,
};

export type OnDeleteTeamSubscription = {
  onDeleteTeam?:  {
    __typename: "Team",
    id: string,
    name: string,
    question?:  {
      __typename: "Question",
      id: string,
      text: string,
      choices?: string | null,
      answerSettings?: string | null,
      responses?: string | null,
      hints?: string | null,
      imageUrl?: string | null,
      instructions?: string | null,
      standard?: string | null,
      cluster?: string | null,
      domain?: string | null,
      grade?: string | null,
      order: number,
      isConfidenceEnabled: boolean,
      isShortAnswerEnabled: boolean,
      isHintEnabled: boolean,
      gameSessionId: string,
    } | null,
    teamMembers?:  {
      __typename: "ModelTeamMemberConnection",
      items:  Array< {
        __typename: "TeamMember",
        id: string,
        isFacilitator?: boolean | null,
        answers?:  {
          __typename: "ModelTeamAnswerConnection",
          items:  Array< {
            __typename: "TeamAnswer",
            id: string,
            isCorrect?: boolean | null,
            isSubmitted: boolean,
            isShortAnswerEnabled: boolean,
            currentState: GameSessionState,
            currentQuestionIndex: number,
            questionId: string,
            teamMemberAnswersId: string,
            teamAnswersId?: string | null,
            teamName?: string | null,
            text: string,
            answer: string,
            confidenceLevel?: ConfidenceLevel | null,
            hint?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        deviceId: string,
        createdAt: string,
        updatedAt: string,
        teamTeamMembersId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    score: number,
    selectedAvatarIndex: number,
    createdAt: string,
    updatedAt: string,
    gameSessionTeamsId?: string | null,
    teamQuestionId?: string | null,
    teamQuestionOrder?: number | null,
    teamQuestionGameSessionId?: string | null,
  } | null,
};

export type OnCreateTeamMemberSubscriptionVariables = {
  filter?: ModelSubscriptionTeamMemberFilterInput | null,
};

export type OnCreateTeamMemberSubscription = {
  onCreateTeamMember?:  {
    __typename: "TeamMember",
    id: string,
    isFacilitator?: boolean | null,
    answers?:  {
      __typename: "ModelTeamAnswerConnection",
      items:  Array< {
        __typename: "TeamAnswer",
        id: string,
        isCorrect?: boolean | null,
        isSubmitted: boolean,
        isShortAnswerEnabled: boolean,
        currentState: GameSessionState,
        currentQuestionIndex: number,
        questionId: string,
        teamMemberAnswersId: string,
        teamAnswersId?: string | null,
        teamName?: string | null,
        text: string,
        answer: string,
        confidenceLevel?: ConfidenceLevel | null,
        hint?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    deviceId: string,
    createdAt: string,
    updatedAt: string,
    teamTeamMembersId?: string | null,
  } | null,
};

export type OnUpdateTeamMemberSubscriptionVariables = {
  filter?: ModelSubscriptionTeamMemberFilterInput | null,
};

export type OnUpdateTeamMemberSubscription = {
  onUpdateTeamMember?:  {
    __typename: "TeamMember",
    id: string,
    isFacilitator?: boolean | null,
    answers?:  {
      __typename: "ModelTeamAnswerConnection",
      items:  Array< {
        __typename: "TeamAnswer",
        id: string,
        isCorrect?: boolean | null,
        isSubmitted: boolean,
        isShortAnswerEnabled: boolean,
        currentState: GameSessionState,
        currentQuestionIndex: number,
        questionId: string,
        teamMemberAnswersId: string,
        teamAnswersId?: string | null,
        teamName?: string | null,
        text: string,
        answer: string,
        confidenceLevel?: ConfidenceLevel | null,
        hint?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    deviceId: string,
    createdAt: string,
    updatedAt: string,
    teamTeamMembersId?: string | null,
  } | null,
};

export type OnDeleteTeamMemberSubscriptionVariables = {
  filter?: ModelSubscriptionTeamMemberFilterInput | null,
};

export type OnDeleteTeamMemberSubscription = {
  onDeleteTeamMember?:  {
    __typename: "TeamMember",
    id: string,
    isFacilitator?: boolean | null,
    answers?:  {
      __typename: "ModelTeamAnswerConnection",
      items:  Array< {
        __typename: "TeamAnswer",
        id: string,
        isCorrect?: boolean | null,
        isSubmitted: boolean,
        isShortAnswerEnabled: boolean,
        currentState: GameSessionState,
        currentQuestionIndex: number,
        questionId: string,
        teamMemberAnswersId: string,
        teamAnswersId?: string | null,
        teamName?: string | null,
        text: string,
        answer: string,
        confidenceLevel?: ConfidenceLevel | null,
        hint?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    deviceId: string,
    createdAt: string,
    updatedAt: string,
    teamTeamMembersId?: string | null,
  } | null,
};

export type OnCreateTeamAnswerSubscriptionVariables = {
  filter?: ModelSubscriptionTeamAnswerFilterInput | null,
};

export type OnCreateTeamAnswerSubscription = {
  onCreateTeamAnswer?:  {
    __typename: "TeamAnswer",
    id: string,
    isCorrect?: boolean | null,
    isSubmitted: boolean,
    isShortAnswerEnabled: boolean,
    currentState: GameSessionState,
    currentQuestionIndex: number,
    questionId: string,
    teamMemberAnswersId: string,
    teamAnswersId?: string | null,
    teamName?: string | null,
    text: string,
    answer: string,
    confidenceLevel?: ConfidenceLevel | null,
    hint?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateTeamAnswerSubscriptionVariables = {
  filter?: ModelSubscriptionTeamAnswerFilterInput | null,
};

export type OnUpdateTeamAnswerSubscription = {
  onUpdateTeamAnswer?:  {
    __typename: "TeamAnswer",
    id: string,
    isCorrect?: boolean | null,
    isSubmitted: boolean,
    isShortAnswerEnabled: boolean,
    currentState: GameSessionState,
    currentQuestionIndex: number,
    questionId: string,
    teamMemberAnswersId: string,
    teamAnswersId?: string | null,
    teamName?: string | null,
    text: string,
    answer: string,
    confidenceLevel?: ConfidenceLevel | null,
    hint?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteTeamAnswerSubscriptionVariables = {
  filter?: ModelSubscriptionTeamAnswerFilterInput | null,
};

export type OnDeleteTeamAnswerSubscription = {
  onDeleteTeamAnswer?:  {
    __typename: "TeamAnswer",
    id: string,
    isCorrect?: boolean | null,
    isSubmitted: boolean,
    isShortAnswerEnabled: boolean,
    currentState: GameSessionState,
    currentQuestionIndex: number,
    questionId: string,
    teamMemberAnswersId: string,
    teamAnswersId?: string | null,
    teamName?: string | null,
    text: string,
    answer: string,
    confidenceLevel?: ConfidenceLevel | null,
    hint?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateGameQuestionsSubscriptionVariables = {
  filter?: ModelSubscriptionGameQuestionsFilterInput | null,
};

export type OnCreateGameQuestionsSubscription = {
  onCreateGameQuestions?:  {
    __typename: "GameQuestions",
    id: string,
    gameTemplateID: string,
    questionTemplateID: string,
    gameTemplate:  {
      __typename: "GameTemplate",
      id: string,
      title: string,
      owner: string,
      version: number,
      description: string,
      domain?: string | null,
      cluster?: string | null,
      grade?: string | null,
      standard?: string | null,
      phaseOneTime?: number | null,
      phaseTwoTime?: number | null,
      imageUrl?: string | null,
      questionTemplates?:  {
        __typename: "ModelGameQuestionsConnection",
        items:  Array< {
          __typename: "GameQuestions",
          id: string,
          gameTemplateID: string,
          questionTemplateID: string,
          gameTemplate:  {
            __typename: "GameTemplate",
            id: string,
            title: string,
            owner: string,
            version: number,
            description: string,
            domain?: string | null,
            cluster?: string | null,
            grade?: string | null,
            standard?: string | null,
            phaseOneTime?: number | null,
            phaseTwoTime?: number | null,
            imageUrl?: string | null,
            questionTemplates?:  {
              __typename: "ModelGameQuestionsConnection",
              items:  Array< {
                __typename: "GameQuestions",
                id: string,
                gameTemplateID: string,
                questionTemplateID: string,
                gameTemplate:  {
                  __typename: "GameTemplate",
                  id: string,
                  title: string,
                  owner: string,
                  version: number,
                  description: string,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  standard?: string | null,
                  phaseOneTime?: number | null,
                  phaseTwoTime?: number | null,
                  imageUrl?: string | null,
                  questionTemplatesCount: number,
                  questionTemplatesOrder?: string | null,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                questionTemplate:  {
                  __typename: "QuestionTemplate",
                  id: string,
                  title: string,
                  owner: string,
                  version: number,
                  choices?: string | null,
                  instructions?: string | null,
                  answerSettings?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  standard?: string | null,
                  imageUrl?: string | null,
                  gameTemplatesCount: number,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            questionTemplatesCount: number,
            questionTemplatesOrder?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            type?: string | null,
          },
          questionTemplate:  {
            __typename: "QuestionTemplate",
            id: string,
            title: string,
            owner: string,
            version: number,
            choices?: string | null,
            instructions?: string | null,
            answerSettings?: string | null,
            domain?: string | null,
            cluster?: string | null,
            grade?: string | null,
            standard?: string | null,
            imageUrl?: string | null,
            gameTemplates?:  {
              __typename: "ModelGameQuestionsConnection",
              items:  Array< {
                __typename: "GameQuestions",
                id: string,
                gameTemplateID: string,
                questionTemplateID: string,
                gameTemplate:  {
                  __typename: "GameTemplate",
                  id: string,
                  title: string,
                  owner: string,
                  version: number,
                  description: string,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  standard?: string | null,
                  phaseOneTime?: number | null,
                  phaseTwoTime?: number | null,
                  imageUrl?: string | null,
                  questionTemplatesCount: number,
                  questionTemplatesOrder?: string | null,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                questionTemplate:  {
                  __typename: "QuestionTemplate",
                  id: string,
                  title: string,
                  owner: string,
                  version: number,
                  choices?: string | null,
                  instructions?: string | null,
                  answerSettings?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  standard?: string | null,
                  imageUrl?: string | null,
                  gameTemplatesCount: number,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            gameTemplatesCount: number,
            createdAt?: string | null,
            updatedAt?: string | null,
            type?: string | null,
          },
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      questionTemplatesCount: number,
      questionTemplatesOrder?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      type?: string | null,
    },
    questionTemplate:  {
      __typename: "QuestionTemplate",
      id: string,
      title: string,
      owner: string,
      version: number,
      choices?: string | null,
      instructions?: string | null,
      answerSettings?: string | null,
      domain?: string | null,
      cluster?: string | null,
      grade?: string | null,
      standard?: string | null,
      imageUrl?: string | null,
      gameTemplates?:  {
        __typename: "ModelGameQuestionsConnection",
        items:  Array< {
          __typename: "GameQuestions",
          id: string,
          gameTemplateID: string,
          questionTemplateID: string,
          gameTemplate:  {
            __typename: "GameTemplate",
            id: string,
            title: string,
            owner: string,
            version: number,
            description: string,
            domain?: string | null,
            cluster?: string | null,
            grade?: string | null,
            standard?: string | null,
            phaseOneTime?: number | null,
            phaseTwoTime?: number | null,
            imageUrl?: string | null,
            questionTemplates?:  {
              __typename: "ModelGameQuestionsConnection",
              items:  Array< {
                __typename: "GameQuestions",
                id: string,
                gameTemplateID: string,
                questionTemplateID: string,
                gameTemplate:  {
                  __typename: "GameTemplate",
                  id: string,
                  title: string,
                  owner: string,
                  version: number,
                  description: string,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  standard?: string | null,
                  phaseOneTime?: number | null,
                  phaseTwoTime?: number | null,
                  imageUrl?: string | null,
                  questionTemplatesCount: number,
                  questionTemplatesOrder?: string | null,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                questionTemplate:  {
                  __typename: "QuestionTemplate",
                  id: string,
                  title: string,
                  owner: string,
                  version: number,
                  choices?: string | null,
                  instructions?: string | null,
                  answerSettings?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  standard?: string | null,
                  imageUrl?: string | null,
                  gameTemplatesCount: number,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            questionTemplatesCount: number,
            questionTemplatesOrder?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            type?: string | null,
          },
          questionTemplate:  {
            __typename: "QuestionTemplate",
            id: string,
            title: string,
            owner: string,
            version: number,
            choices?: string | null,
            instructions?: string | null,
            answerSettings?: string | null,
            domain?: string | null,
            cluster?: string | null,
            grade?: string | null,
            standard?: string | null,
            imageUrl?: string | null,
            gameTemplates?:  {
              __typename: "ModelGameQuestionsConnection",
              items:  Array< {
                __typename: "GameQuestions",
                id: string,
                gameTemplateID: string,
                questionTemplateID: string,
                gameTemplate:  {
                  __typename: "GameTemplate",
                  id: string,
                  title: string,
                  owner: string,
                  version: number,
                  description: string,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  standard?: string | null,
                  phaseOneTime?: number | null,
                  phaseTwoTime?: number | null,
                  imageUrl?: string | null,
                  questionTemplatesCount: number,
                  questionTemplatesOrder?: string | null,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                questionTemplate:  {
                  __typename: "QuestionTemplate",
                  id: string,
                  title: string,
                  owner: string,
                  version: number,
                  choices?: string | null,
                  instructions?: string | null,
                  answerSettings?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  standard?: string | null,
                  imageUrl?: string | null,
                  gameTemplatesCount: number,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            gameTemplatesCount: number,
            createdAt?: string | null,
            updatedAt?: string | null,
            type?: string | null,
          },
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      gameTemplatesCount: number,
      createdAt?: string | null,
      updatedAt?: string | null,
      type?: string | null,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateGameQuestionsSubscriptionVariables = {
  filter?: ModelSubscriptionGameQuestionsFilterInput | null,
};

export type OnUpdateGameQuestionsSubscription = {
  onUpdateGameQuestions?:  {
    __typename: "GameQuestions",
    id: string,
    gameTemplateID: string,
    questionTemplateID: string,
    gameTemplate:  {
      __typename: "GameTemplate",
      id: string,
      title: string,
      owner: string,
      version: number,
      description: string,
      domain?: string | null,
      cluster?: string | null,
      grade?: string | null,
      standard?: string | null,
      phaseOneTime?: number | null,
      phaseTwoTime?: number | null,
      imageUrl?: string | null,
      questionTemplates?:  {
        __typename: "ModelGameQuestionsConnection",
        items:  Array< {
          __typename: "GameQuestions",
          id: string,
          gameTemplateID: string,
          questionTemplateID: string,
          gameTemplate:  {
            __typename: "GameTemplate",
            id: string,
            title: string,
            owner: string,
            version: number,
            description: string,
            domain?: string | null,
            cluster?: string | null,
            grade?: string | null,
            standard?: string | null,
            phaseOneTime?: number | null,
            phaseTwoTime?: number | null,
            imageUrl?: string | null,
            questionTemplates?:  {
              __typename: "ModelGameQuestionsConnection",
              items:  Array< {
                __typename: "GameQuestions",
                id: string,
                gameTemplateID: string,
                questionTemplateID: string,
                gameTemplate:  {
                  __typename: "GameTemplate",
                  id: string,
                  title: string,
                  owner: string,
                  version: number,
                  description: string,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  standard?: string | null,
                  phaseOneTime?: number | null,
                  phaseTwoTime?: number | null,
                  imageUrl?: string | null,
                  questionTemplatesCount: number,
                  questionTemplatesOrder?: string | null,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                questionTemplate:  {
                  __typename: "QuestionTemplate",
                  id: string,
                  title: string,
                  owner: string,
                  version: number,
                  choices?: string | null,
                  instructions?: string | null,
                  answerSettings?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  standard?: string | null,
                  imageUrl?: string | null,
                  gameTemplatesCount: number,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            questionTemplatesCount: number,
            questionTemplatesOrder?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            type?: string | null,
          },
          questionTemplate:  {
            __typename: "QuestionTemplate",
            id: string,
            title: string,
            owner: string,
            version: number,
            choices?: string | null,
            instructions?: string | null,
            answerSettings?: string | null,
            domain?: string | null,
            cluster?: string | null,
            grade?: string | null,
            standard?: string | null,
            imageUrl?: string | null,
            gameTemplates?:  {
              __typename: "ModelGameQuestionsConnection",
              items:  Array< {
                __typename: "GameQuestions",
                id: string,
                gameTemplateID: string,
                questionTemplateID: string,
                gameTemplate:  {
                  __typename: "GameTemplate",
                  id: string,
                  title: string,
                  owner: string,
                  version: number,
                  description: string,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  standard?: string | null,
                  phaseOneTime?: number | null,
                  phaseTwoTime?: number | null,
                  imageUrl?: string | null,
                  questionTemplatesCount: number,
                  questionTemplatesOrder?: string | null,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                questionTemplate:  {
                  __typename: "QuestionTemplate",
                  id: string,
                  title: string,
                  owner: string,
                  version: number,
                  choices?: string | null,
                  instructions?: string | null,
                  answerSettings?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  standard?: string | null,
                  imageUrl?: string | null,
                  gameTemplatesCount: number,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            gameTemplatesCount: number,
            createdAt?: string | null,
            updatedAt?: string | null,
            type?: string | null,
          },
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      questionTemplatesCount: number,
      questionTemplatesOrder?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      type?: string | null,
    },
    questionTemplate:  {
      __typename: "QuestionTemplate",
      id: string,
      title: string,
      owner: string,
      version: number,
      choices?: string | null,
      instructions?: string | null,
      answerSettings?: string | null,
      domain?: string | null,
      cluster?: string | null,
      grade?: string | null,
      standard?: string | null,
      imageUrl?: string | null,
      gameTemplates?:  {
        __typename: "ModelGameQuestionsConnection",
        items:  Array< {
          __typename: "GameQuestions",
          id: string,
          gameTemplateID: string,
          questionTemplateID: string,
          gameTemplate:  {
            __typename: "GameTemplate",
            id: string,
            title: string,
            owner: string,
            version: number,
            description: string,
            domain?: string | null,
            cluster?: string | null,
            grade?: string | null,
            standard?: string | null,
            phaseOneTime?: number | null,
            phaseTwoTime?: number | null,
            imageUrl?: string | null,
            questionTemplates?:  {
              __typename: "ModelGameQuestionsConnection",
              items:  Array< {
                __typename: "GameQuestions",
                id: string,
                gameTemplateID: string,
                questionTemplateID: string,
                gameTemplate:  {
                  __typename: "GameTemplate",
                  id: string,
                  title: string,
                  owner: string,
                  version: number,
                  description: string,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  standard?: string | null,
                  phaseOneTime?: number | null,
                  phaseTwoTime?: number | null,
                  imageUrl?: string | null,
                  questionTemplatesCount: number,
                  questionTemplatesOrder?: string | null,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                questionTemplate:  {
                  __typename: "QuestionTemplate",
                  id: string,
                  title: string,
                  owner: string,
                  version: number,
                  choices?: string | null,
                  instructions?: string | null,
                  answerSettings?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  standard?: string | null,
                  imageUrl?: string | null,
                  gameTemplatesCount: number,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            questionTemplatesCount: number,
            questionTemplatesOrder?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            type?: string | null,
          },
          questionTemplate:  {
            __typename: "QuestionTemplate",
            id: string,
            title: string,
            owner: string,
            version: number,
            choices?: string | null,
            instructions?: string | null,
            answerSettings?: string | null,
            domain?: string | null,
            cluster?: string | null,
            grade?: string | null,
            standard?: string | null,
            imageUrl?: string | null,
            gameTemplates?:  {
              __typename: "ModelGameQuestionsConnection",
              items:  Array< {
                __typename: "GameQuestions",
                id: string,
                gameTemplateID: string,
                questionTemplateID: string,
                gameTemplate:  {
                  __typename: "GameTemplate",
                  id: string,
                  title: string,
                  owner: string,
                  version: number,
                  description: string,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  standard?: string | null,
                  phaseOneTime?: number | null,
                  phaseTwoTime?: number | null,
                  imageUrl?: string | null,
                  questionTemplatesCount: number,
                  questionTemplatesOrder?: string | null,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                questionTemplate:  {
                  __typename: "QuestionTemplate",
                  id: string,
                  title: string,
                  owner: string,
                  version: number,
                  choices?: string | null,
                  instructions?: string | null,
                  answerSettings?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  standard?: string | null,
                  imageUrl?: string | null,
                  gameTemplatesCount: number,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            gameTemplatesCount: number,
            createdAt?: string | null,
            updatedAt?: string | null,
            type?: string | null,
          },
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      gameTemplatesCount: number,
      createdAt?: string | null,
      updatedAt?: string | null,
      type?: string | null,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteGameQuestionsSubscriptionVariables = {
  filter?: ModelSubscriptionGameQuestionsFilterInput | null,
};

export type OnDeleteGameQuestionsSubscription = {
  onDeleteGameQuestions?:  {
    __typename: "GameQuestions",
    id: string,
    gameTemplateID: string,
    questionTemplateID: string,
    gameTemplate:  {
      __typename: "GameTemplate",
      id: string,
      title: string,
      owner: string,
      version: number,
      description: string,
      domain?: string | null,
      cluster?: string | null,
      grade?: string | null,
      standard?: string | null,
      phaseOneTime?: number | null,
      phaseTwoTime?: number | null,
      imageUrl?: string | null,
      questionTemplates?:  {
        __typename: "ModelGameQuestionsConnection",
        items:  Array< {
          __typename: "GameQuestions",
          id: string,
          gameTemplateID: string,
          questionTemplateID: string,
          gameTemplate:  {
            __typename: "GameTemplate",
            id: string,
            title: string,
            owner: string,
            version: number,
            description: string,
            domain?: string | null,
            cluster?: string | null,
            grade?: string | null,
            standard?: string | null,
            phaseOneTime?: number | null,
            phaseTwoTime?: number | null,
            imageUrl?: string | null,
            questionTemplates?:  {
              __typename: "ModelGameQuestionsConnection",
              items:  Array< {
                __typename: "GameQuestions",
                id: string,
                gameTemplateID: string,
                questionTemplateID: string,
                gameTemplate:  {
                  __typename: "GameTemplate",
                  id: string,
                  title: string,
                  owner: string,
                  version: number,
                  description: string,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  standard?: string | null,
                  phaseOneTime?: number | null,
                  phaseTwoTime?: number | null,
                  imageUrl?: string | null,
                  questionTemplatesCount: number,
                  questionTemplatesOrder?: string | null,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                questionTemplate:  {
                  __typename: "QuestionTemplate",
                  id: string,
                  title: string,
                  owner: string,
                  version: number,
                  choices?: string | null,
                  instructions?: string | null,
                  answerSettings?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  standard?: string | null,
                  imageUrl?: string | null,
                  gameTemplatesCount: number,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            questionTemplatesCount: number,
            questionTemplatesOrder?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            type?: string | null,
          },
          questionTemplate:  {
            __typename: "QuestionTemplate",
            id: string,
            title: string,
            owner: string,
            version: number,
            choices?: string | null,
            instructions?: string | null,
            answerSettings?: string | null,
            domain?: string | null,
            cluster?: string | null,
            grade?: string | null,
            standard?: string | null,
            imageUrl?: string | null,
            gameTemplates?:  {
              __typename: "ModelGameQuestionsConnection",
              items:  Array< {
                __typename: "GameQuestions",
                id: string,
                gameTemplateID: string,
                questionTemplateID: string,
                gameTemplate:  {
                  __typename: "GameTemplate",
                  id: string,
                  title: string,
                  owner: string,
                  version: number,
                  description: string,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  standard?: string | null,
                  phaseOneTime?: number | null,
                  phaseTwoTime?: number | null,
                  imageUrl?: string | null,
                  questionTemplatesCount: number,
                  questionTemplatesOrder?: string | null,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                questionTemplate:  {
                  __typename: "QuestionTemplate",
                  id: string,
                  title: string,
                  owner: string,
                  version: number,
                  choices?: string | null,
                  instructions?: string | null,
                  answerSettings?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  standard?: string | null,
                  imageUrl?: string | null,
                  gameTemplatesCount: number,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            gameTemplatesCount: number,
            createdAt?: string | null,
            updatedAt?: string | null,
            type?: string | null,
          },
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      questionTemplatesCount: number,
      questionTemplatesOrder?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      type?: string | null,
    },
    questionTemplate:  {
      __typename: "QuestionTemplate",
      id: string,
      title: string,
      owner: string,
      version: number,
      choices?: string | null,
      instructions?: string | null,
      answerSettings?: string | null,
      domain?: string | null,
      cluster?: string | null,
      grade?: string | null,
      standard?: string | null,
      imageUrl?: string | null,
      gameTemplates?:  {
        __typename: "ModelGameQuestionsConnection",
        items:  Array< {
          __typename: "GameQuestions",
          id: string,
          gameTemplateID: string,
          questionTemplateID: string,
          gameTemplate:  {
            __typename: "GameTemplate",
            id: string,
            title: string,
            owner: string,
            version: number,
            description: string,
            domain?: string | null,
            cluster?: string | null,
            grade?: string | null,
            standard?: string | null,
            phaseOneTime?: number | null,
            phaseTwoTime?: number | null,
            imageUrl?: string | null,
            questionTemplates?:  {
              __typename: "ModelGameQuestionsConnection",
              items:  Array< {
                __typename: "GameQuestions",
                id: string,
                gameTemplateID: string,
                questionTemplateID: string,
                gameTemplate:  {
                  __typename: "GameTemplate",
                  id: string,
                  title: string,
                  owner: string,
                  version: number,
                  description: string,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  standard?: string | null,
                  phaseOneTime?: number | null,
                  phaseTwoTime?: number | null,
                  imageUrl?: string | null,
                  questionTemplatesCount: number,
                  questionTemplatesOrder?: string | null,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                questionTemplate:  {
                  __typename: "QuestionTemplate",
                  id: string,
                  title: string,
                  owner: string,
                  version: number,
                  choices?: string | null,
                  instructions?: string | null,
                  answerSettings?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  standard?: string | null,
                  imageUrl?: string | null,
                  gameTemplatesCount: number,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            questionTemplatesCount: number,
            questionTemplatesOrder?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            type?: string | null,
          },
          questionTemplate:  {
            __typename: "QuestionTemplate",
            id: string,
            title: string,
            owner: string,
            version: number,
            choices?: string | null,
            instructions?: string | null,
            answerSettings?: string | null,
            domain?: string | null,
            cluster?: string | null,
            grade?: string | null,
            standard?: string | null,
            imageUrl?: string | null,
            gameTemplates?:  {
              __typename: "ModelGameQuestionsConnection",
              items:  Array< {
                __typename: "GameQuestions",
                id: string,
                gameTemplateID: string,
                questionTemplateID: string,
                gameTemplate:  {
                  __typename: "GameTemplate",
                  id: string,
                  title: string,
                  owner: string,
                  version: number,
                  description: string,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  standard?: string | null,
                  phaseOneTime?: number | null,
                  phaseTwoTime?: number | null,
                  imageUrl?: string | null,
                  questionTemplatesCount: number,
                  questionTemplatesOrder?: string | null,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                questionTemplate:  {
                  __typename: "QuestionTemplate",
                  id: string,
                  title: string,
                  owner: string,
                  version: number,
                  choices?: string | null,
                  instructions?: string | null,
                  answerSettings?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  standard?: string | null,
                  imageUrl?: string | null,
                  gameTemplatesCount: number,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            gameTemplatesCount: number,
            createdAt?: string | null,
            updatedAt?: string | null,
            type?: string | null,
          },
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      gameTemplatesCount: number,
      createdAt?: string | null,
      updatedAt?: string | null,
      type?: string | null,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};
